import React, { useContext, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Warning from "@material-ui/icons/Warning";
import Box from '@material-ui/core/Box';

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import CloudImage from "components/CloudImage/CloudImage";
import SmartVideo from "components/SmartVideo/SmartVideo";

import { AppContext } from "../../AppContext";
import AuthService from "../../AuthService";

const styles = {
  postDetails: {
    width: "100%",
    fontSize: "22px"
  },
  amount: {
    width: "100%",
    fontSize: "22px",
    color: "#9c35b3"
  },
  chatImgContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "left"
  },
  chatImg: {
    width: "250px",
    height: "250px",
    objectFit: "cover"
  },
  textButton: {
    color: "#9c35b3",
    cursor: "pointer",
    fontSize: "20px"
  }
};

const useStyles = makeStyles(styles);

export default function Chat(props) {
  const classes = useStyles();

  const { index, message, deleteEnable, senderName } = props;

  const [isProfane, setIsProfane] = useState(message.isProfane);
  const [isDeleted, setIsDeleted] = useState(message.deleted);

  const context = useContext(AppContext);
  const authService = new AuthService();

  function removeAlerte() {
    setIsProfane(false);
    let url = context.apiUrl + context.messagesPath + "profanityStatus";
    console.log("url", url);
    authService.setUrl(url);
    authService.request(
      "put",
      {
        messageId: message._id,
        isProfane: false,
        isCritical: false
      },
      response => {}
    );
  }

  function removeMessage(messageId) {
    const { onDelete } = props;
    setIsDeleted(true);
    onDelete(messageId);
  }

  return (
    <GridContainer key={message._id}>
      <GridItem xs={12} sm={12} md={3}>
        <div className={classes.postDetails}>
          <p>{new Date(message.date).toLocaleString()}</p>
        </div>
      </GridItem>

      <GridItem xs={12} sm={12} md={1}>
        <div className={classes.postDetails}>
          <p>{senderName}</p>
        </div>
      </GridItem>

      <GridItem xs={12} sm={12} md={3}>
        {(message.message || message.response) && (
          <div className={classes.postDetails}>
            <p>{message.message || message.response}</p>
          </div>
        )}

        {(message.photoId || message.videoId) && (
          <div className={classes.chatImgContainer}>
            {!message.video && message.src && (
              <CloudImage
                className={classes.chatImg}
                src={message.src.normal ? message.src.normal : message.src.blur}
                defaultHeight="auto"
                defaultWidth="100%"
                alt="thumb"
              />
            )}
            {message.video && (
              <SmartVideo
                className={classes.chatImg}
                video={message.video}
                autoPlay={false}
                controls
                defaultHeight="300px"
                defaultWidth="600px"
              />
            )}
          </div>
        )}
      </GridItem>

      <GridItem xs={12} sm={12} md={1} className={classes.postDetails}>
        {message.amount && <p className={classes.amount}>{message.amount} €</p>}
      </GridItem>

      <GridItem xs={12} sm={12} md={1}>
        <div className={classes.postDetails}>
          <p>{message.status}</p>
        </div>
      </GridItem>

      <GridItem xs={12} sm={12} md={1}>
        <p className={classes.postDetails}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            {
              isProfane &&
              <Warning fontSize="large" style={{ color: "#d52a50"}} />
            }
            {
              isProfane &&
              <label
                style={{ color: "#d52a50", cursor: "pointer" }}
                onClick={removeAlerte}>
                Remove
              </label>
            }
          </Box>
        </p>
      </GridItem>

      <GridItem xs={12} sm={12} md={1}>
        <div className={classes.postDetails}>
          <p style={{ fontWeight: "normal", fontSize: "20px", color: "#d52a50" }}>
            {isDeleted ? "Deleted" : ""}
          </p>
        </div>
      </GridItem>

      <GridItem xs={12} sm={12} md={1}>
        {deleteEnable && (
          <div className={classes.postDetails}>
            <p
              className={classes.textButton}
              onClick={() => removeMessage(message._id)}
            >
              Delete
            </p>
          </div>
        )}
      </GridItem>
    </GridContainer>
  );
}
