import React from "react";

import "./CloudImage.css";

class CloudImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDownloaded: false,
      src: null,
      height: props.defaultHeight ? props.defaultHeight : "500px",
      width: props.defaultWidth ? props.defaultWidth : "500px"
    };
    this.imgRef = React.createRef();
  }

  componentWillReceiveProps = nextProps => {
    if (nextProps.src && !this.props.src) {
      this.setState({ src: nextProps.src });
    }
  };

  componentDidMount = () => {
    const { src } = this.props;
    if (src) {
      this.setState({ src });
    }
  };

  handleImageLoaded = () => {
    const { onImageLoaded } = this.props;

    let image = this.imgRef.current;

    if (onImageLoaded) {
      var c = document.createElement("canvas");
      c.width = image.width;
      c.height = image.height;
      var ctx = c.getContext("2d");
      ctx.drawImage(image, 0, 0, image.width, image.height);
      c.toBlob(blob => {
        onImageLoaded(blob);
      });
    }
  };

  render() {
    const { className, alt, onClick, crossOrigin } = this.props;
    const { src, height, width } = this.state;

    return (
      <div
        className={className + " skeleton-container"}
        onClick={onClick}
        style={{ width, height }}
      >
        <img
          ref={this.imgRef}
          alt={alt}
          className="smart-image"
          onLoad={this.handleImageLoaded}
          onDragStart={evt => evt.preventDefault()}
          crossOrigin={crossOrigin}
          src={src}
        />
      </div>
    );
  }
}

export default CloudImage;
