import React, { useEffect, useContext } from "react";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import Movepoint from "components/Movepoint/Movepoint.js";
import IconButton from "@material-ui/core/IconButton";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Modal from "@material-ui/core/Modal";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

// @material-ui/icons
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import DeleteIcon from "@material-ui/icons/Delete";

import { AppContext } from "../../AppContext";
import AuthService from "../../AuthService";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  formControl: {
    margin: "5px",
    width: "120px",
  },
  formControlDialog: {
    margin: "5px",
    width: "265px",
  },
  errorMessageSubscription: {
    marginTop: "5px",
    color: "#f44336",
  },
  selectEmpty: {
    marginTop: "2",
  },
  searchInput: {
    width: "300px",
  },
  textfield: {
    margin: "10px 10px 10px 0px",
  },
  link: {
    cursor: "pointer",
    color: "#d62546",
  },
  actionText: {
    cursor: "pointer",
    fontWeight: "bold",
    color: "#d62546",
    fontSize: "16px",
  },
  downloadIcon: {
    cursor: "pointer",
    margin: "15px 0px 0px 5px",
  },
  spinnerContainer: {
    marginTop: "10px",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  deleteButton: {
    padding: "8px",
  },
};

const useStyles = makeStyles(styles);

const monthNames = [
  "all",
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const years = ["all"];
const subscriptionsTypes = ["yearly", "monthly", "all"];
const statusList = ["active", "inactive", "all"];

let staringDate = 2020;
let date = new Date();
const offset = new Date().getTimezoneOffset();
for (let year = staringDate; year <= date.getFullYear(); ++year) {
  years.push(year);
}

const getDateWithTime = () => {
  return new Date(new Date().getTime() - offset * 60 * 1000)
    ?.toISOString()
    ?.slice(0, 16);
};

export default function Subscriptions() {
  const context = useContext(AppContext);
  const classes = useStyles();

  const [toggle, setToggle] = React.useState(false); // Just needed to force refresh
  const [subscriptions, setSubscriptions] = React.useState([]);
  const [columnFilter, setColumnFilter] = React.useState(13);
  const [searchLoading, setSearchLoading] = React.useState(true);
  const [tr, setTr] = React.useState({});
  const [subscriptionType, setSubscriptionType] = React.useState("all");
  const [modelUserName, setModelUserName] = React.useState("");
  const [fanUserName, setFanUserName] = React.useState("");
  const [status, setStatus] = React.useState("all");
  const [tableData, setTableData] = React.useState([]);
  const [month, setMonth] = React.useState(0);
  const [year, setYear] = React.useState("all");
  const [loading, setLoading] = React.useState(false);
  const [currencies, setCurrencies] = React.useState([]);
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(10);
  const [openDialogDeleting, setOpenDialogDeleting] = React.useState(false);
  const [subscriptionToDelete, setSubscriptionToDelete] = React.useState(null);
  const [
    errorMessageSubscription,
    setErrorMessageSubscription,
  ] = React.useState("");
  const [modelNameForDeletion, setModelNameForDeletion] = React.useState("");
  const [fanNameForDeletion, setFanNameForDeletion] = React.useState("");
  const [deletionCode, setDeletionCode] = React.useState("");

  const [deletionDate, setDeletionDate] = React.useState(getDateWithTime());

  const authServiceSingleton = React.useRef(new AuthService());

  let initColumns = [
    {
      columnName: "Fan",
      getData: (data) => <p>{data.fanName}</p>,
      getCompareData: () => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
    {
      columnName: "Model",
      getData: (data) => <p>{data.modelName}</p>,
      getCompareData: () => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
    {
      columnName: "Type",
      getData: (data) => <p>{data.type}</p>,
      getCompareData: () => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
    {
      columnName: "Start",
      getData: (data) => <p>{new Date(data.date).toLocaleString()}</p>,
      getCompareData: () => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
    {
      columnName: "End",
      getData: (data) => (
        <p>
          {data.endSubscriptionDate
            ? new Date(data.endSubscriptionDate).toLocaleString()
            : ""}
        </p>
      ),
      getCompareData: () => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
    {
      columnName: "Charge ID",
      getData: (data) => <p>{data.chargeId}</p>,
      getCompareData: () => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
    {
      columnName: "Amount",
      getData: (data) => (
        <p>
          {data.amount} {data.currency}
        </p>
      ),
      getCompareData: () => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
    {
      columnName: "Status",
      getData: (data) => (
        <>
          {data.endSubscriptionDate ? (
            <Chip
              label="Inactive"
              style={{ background: "#d62546", color: "#FFF" }}
            />
          ) : (
            <Chip
              label="Active"
              style={{ background: "#5DBB63", color: "#FFF" }}
            />
          )}
        </>
      ),
      getCompareData: () => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
    {
      columnName: "End reason",
      getData: (data, tr) => (
        <p>
          {data.reasonCustom
            ? data.reasonCustom
            : data.reasonKey
            ? tr[data.reasonKey]
            : ""}
        </p>
      ),
      getCompareData: () => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
    {
      columnName: "Key",
      getData: (data) => <p>{data._key}</p>,
      getCompareData: () => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
    {
      columnName: "ID",
      getData: (data) => <p>{data.subscriptionId}</p>,
      getCompareData: () => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
    {
      columnName: "Delete",
      getData: (data) => (
        <IconButton
          aria-label="delete"
          className={classes.deleteButton}
          onClick={() => handleOpenDialogDeleting(data)}
        >
          <DeleteIcon color="secondary" />
        </IconButton>
      ),
      getCompareData: (data) => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
  ];

  const [columns, setColumns] = React.useState(initColumns);

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  const handleColumnStateChange = (index) => (event) => {
    let activeColumns = columns.filter((e) => e.isActive);
    let currentFilterColumnName = activeColumns[columnFilter]
      ? activeColumns[columnFilter].columnName
      : "";

    let newColumns = columns;
    newColumns[index].isActive = event.target.checked;
    setColumns(newColumns);

    let newFilterColumn = newColumns
      .filter((e) => e.isActive)
      .map((e) => e.columnName)
      .indexOf(currentFilterColumnName);
    if (newFilterColumn === -1) {
      newFilterColumn = initColumns.map((e) => e.isFilterable).indexOf(true);
    }
    setColumnFilter(newFilterColumn);
    setToggle(!toggle);
  };

  const makeDataFromSubscriptions = (subscriptions) => {
    var data = [];

    subscriptions.forEach((subscription) => {
      let row = [];
      columns.forEach((column) => {
        if (column.isActive) {
          // console.log("currencies", currencies);
          let currenciesFiltered = currencies.filter(
            (e) => e.code == subscription.currency
          );
          let symbol =
            currenciesFiltered.length > 0 ? currenciesFiltered[0].symbol : "";
          if (symbol) {
            subscription.currency = symbol;
          }
          row.push({
            value: column.getData(subscription, tr),
            compare: column.getCompareData(subscription),
          });
        }
      });
      data.push(row);
    });

    return data;
  };

  useEffect(() => {
    // Get all currencies
    authServiceSingleton.current.setUrl(
      context.apiUrl + context.categoriesPath + "currencies/all"
    );
    authServiceSingleton.current.request("get", {}, (response) => {
      const currencies = response.data;
      console.log("GET currencies", currencies);
      setCurrencies(currencies);
    });

    // Get translations
    authServiceSingleton.current.setUrl(context.apiUrl + context.translationsPath);
    authServiceSingleton.current.request("get", { country: "EN", type: "site" }, (response) => {
      const { data } = response;
      if (data.length > 0) {
        data[0].translations.userOption.all = "all";
        setTr(data[0].translations);
      } else {
        console.log("No translations found !");
      }
    });
  }, []);

  useEffect(() => {
    if (currencies.length > 0) {
      searchMore(true /* reset */);
    }
  }, [
    subscriptionType,
    modelUserName,
    fanUserName,
    status,
    month,
    year,
    currencies,
  ]);

  const searchMoreWithMovepoint = (reset) => {
    // Avoid to be triggered immediatly
    if (tableData.length > 0) {
      searchMore(reset);
    }
  };

  const searchMore = (reset) => {
    setSearchLoading(true);

    // Cancel previous pending search
    authServiceSingleton.current.cancel();

    // Get all transactions
    authServiceSingleton.current.setUrl(context.apiUrl + context.subscribePath + "all");
    authServiceSingleton.current.request(
      "get",
      {
        month: month || "all",
        year,
        subscriptionType,
        modelUserName,
        fanUserName,
        status,
        start: reset ? 0 : start,
        end: reset ? 10 : end,
      },
      (response) => {
        const { data } = response;
        setSearchLoading(false);
        let newSubscriptions = reset ? data : subscriptions.concat(data);
        setSubscriptions(newSubscriptions);
        let newTabledata = makeDataFromSubscriptions(newSubscriptions);
        setTableData(newTabledata);
        let newStart = newSubscriptions.length;
        let newEnd = newStart + 10;
        setStart(newStart);
        setEnd(newEnd);
      }
    );
  };

  let total = [];

  const checkIntegrity = () => {
    setLoading(true);

    authServiceSingleton.current.setUrl(
      context.apiUrl + context.securionpayPath + "subscriptions/mismatches"
    );
    authServiceSingleton.current.request("get", {}, (response) => {
      setLoading(false);
      const { data } = response;
      alert(JSON.stringify(data, null, 4));
    });
  };

  const handleFanNameChange = (evt) => {
    setFanUserName(evt.target.value);
  };

  const handleModelNameChange = (evt) => {
    setModelUserName(evt.target.value);
  };

  const handleSubscriptionTypeChange = (evt) => {
    setSubscriptionType(evt.target.value);
  };

  const handleStatusChange = (evt) => {
    setStatus(evt.target.value);
  };

  const handleOpenDialogDeleting = (subscription) => {
    setOpenDialogDeleting(true);
    setSubscriptionToDelete(subscription);
    setDeletionDate(getDateWithTime());

    authServiceSingleton.current.setUrl(context.apiUrl + context.subscribePath + "initDeletion");
    authServiceSingleton.current.request(
      "post",
      {
        subscriptionId: subscription._id,
      },
      (response) => {
        const { data } = response;
        if (data?.error) {
          console.log("error", data.error);
          handleCloseDialogDeleting();
        }
      }
    );
  };

  const handleCloseDialogDeleting = () => {
    setOpenDialogDeleting(false);
    setSubscriptionToDelete(null);
    setErrorMessageSubscription("");
    setFanNameForDeletion("");
    setModelNameForDeletion("");
    setDeletionCode("");
    setDeletionDate(getDateWithTime());
  };

  const deleteSubscription = () => {
    authServiceSingleton.current.setUrl(context.apiUrl + context.subscribePath);
    authServiceSingleton.current.request(
      "delete",
      {
        subscriptionId: subscriptionToDelete._id,
        deletionCode,
        deletionDate: deletionDate ? new Date(deletionDate) : new Date(),
        fanName: fanNameForDeletion,
        modelName: modelNameForDeletion,
      },
      (response) => {
        const { data } = response;
        if (data?.error) {
          console.log("Error : ", data?.error);
          setErrorMessageSubscription(data?.error);
          return;
        } else if (data?.redirectURL) {
          window.open(data?.redirectURL, "_blank");
        }

        let newSubscriptions = subscriptions.map((subscription) => {
          if (subscription._id === data._id) {
            return {
              ...subscription,
              endSubscriptionDate: data.endSubscriptionDate,
              reasonCustom: data.reasonCustom || "Deleted by admin",
            };
          }
          return subscription;
        });
        setSubscriptions(newSubscriptions);
        let newTabledata = makeDataFromSubscriptions(newSubscriptions);
        setTableData(newTabledata);
        handleCloseDialogDeleting();
      }
    );
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "white",
    border: "2px solid #2D2D2D",
    boxShadow: 24,
    width: "80px",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
  };

  return (
    <>
      <Modal
        open={loading}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CircularProgress />
          <label>Loading</label>
        </Box>
      </Modal>

      <FormControl className={classes.formControl}>
        <Button fullWidth color="primary" onClick={() => checkIntegrity()}>
          Check integrity
        </Button>
      </FormControl>

      <FormControl className={classes.formControl}>
        <TextField
          label="Fan"
          type="search"
          variant="outlined"
          onChange={handleFanNameChange}
        />
      </FormControl>

      <FormControl className={classes.formControl}>
        <TextField
          label="Model"
          type="search"
          variant="outlined"
          onChange={handleModelNameChange}
        />
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel>Year</InputLabel>
        <Select onChange={handleYearChange} value={year}>
          {years.map((y) => (
            <MenuItem value={y}>{y}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel>Month</InputLabel>
        <Select onChange={handleMonthChange} value={month}>
          {monthNames.map((m, index) => (
            <MenuItem value={index}>{m}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel>Subscription Type</InputLabel>
        <Select
          onChange={handleSubscriptionTypeChange}
          value={subscriptionType}
        >
          {subscriptionsTypes.map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel>Status</InputLabel>
        <Select onChange={handleStatusChange} value={status}>
          {statusList.map((s) => (
            <MenuItem key={s} value={s}>
              {s}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <br />

      {columns.map((column, index) => (
        <FormControlLabel
          control={
            <Checkbox
              disabled={column.isMandatory}
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={<CheckCircleIcon />}
              checked={column.isActive}
              onChange={handleColumnStateChange(index)}
              color="secondary"
            />
          }
          label={column.columnName}
        />
      ))}

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Subscriptions</h4>
              <p className={classes.cardCategoryWhite}>
                Here are all the subscriptions
              </p>
            </CardHeader>

            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={columns
                  .filter((column) => column.isActive)
                  .map((column) => column.columnName)}
                tableData={total.concat(
                  tableData.map((e) => e.map((e) => e.value))
                )}
              />
              {searchLoading && (
                <div className={classes.spinnerContainer}>
                  <CircularProgress color="secondary" />
                </div>
              )}
              <Movepoint
                onEnter={() => searchMoreWithMovepoint(false /* reset */)}
                onLeave={() => {}}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      <Dialog open={openDialogDeleting} onClose={handleCloseDialogDeleting}>
        {subscriptionToDelete && (
          <>
            <DialogTitle>
              Delete the subscription between a fan and his model
            </DialogTitle>
            <DialogContent>
              <DialogContentText>{`Subscription selected : ${subscriptionToDelete?.fanName} / ${subscriptionToDelete?.modelName}`}</DialogContentText>
              <FormControl className={classes.formControlDialog}>
                <TextField
                  label="Fan name"
                  type="search"
                  variant="outlined"
                  autoFocus={true}
                  value={fanNameForDeletion}
                  onChange={(e) => setFanNameForDeletion(e.target.value)}
                />
              </FormControl>
              <FormControl className={classes.formControlDialog}>
                <TextField
                  label="Model name"
                  type="search"
                  variant="outlined"
                  value={modelNameForDeletion}
                  onChange={(e) => setModelNameForDeletion(e.target.value)}
                />
              </FormControl>
              <FormControl className={classes.formControlDialog}>
                <TextField
                  label="Deletion code"
                  type="search"
                  variant="outlined"
                  value={deletionCode}
                  onChange={(e) => setDeletionCode(e.target.value)}
                />
              </FormControl>
              <FormControl className={classes.formControlDialog}>
                <TextField
                  type="datetime-local"
                  variant="outlined"
                  value={deletionDate}
                  onChange={(e) => {
                    setDeletionDate(e.target.value);
                  }}
                />
              </FormControl>
              {errorMessageSubscription && (
                <DialogContentText className={classes.errorMessageSubscription}>
                  Error : {errorMessageSubscription}
                </DialogContentText>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialogDeleting}>Cancel</Button>
              <Button onClick={deleteSubscription} color="danger">
                Delete
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
}
