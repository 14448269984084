import React, { useContext, useEffect, useState } from "react";
import Autosuggest from "react-autosuggest";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Movepoint from "components/Movepoint/Movepoint.js";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";

import Post from "./Post.js";

import { AppContext } from "../../AppContext";
import AuthService from "../../AuthService";

const styles = {
  spinnerContainer: {
    marginTop: "10px",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  formControl: {
    margin: "5px",
    width: "180px",
  },
  infoButton: {
    background: "skyblue",
    color: "#fff",
    marginRight: "10px",
    height: "30px",
    marginTop: "15px",
  },
  formControlLabel: {
    margin: "5px",
    width: "auto",
  },
};

const useStyles = makeStyles(styles);

export default function List(props) {
  const classes = useStyles();
  const context = useContext(AppContext);

  const authService = new AuthService();
  const nbPostsToFetch = 10;

  const [toggle, setToggle] = useState(false); // needed to force refresh
  const [posts, setPosts] = useState([]);
  const [searchLoading, setSearchLoading] = useState(true);
  const [showPhotosBlurMissing, setShowPhotosBlurMissing] = useState(false);
  const [showFuturePosts, setShowFuturePosts] = useState(true);
  const [showPublicPostsOnly, setShowPublicPostsOnly] = useState(false);
  const [modelSuggestions, setModelSuggestions] = useState([]);
  const [username, setUserName] = useState("");
  const [contentCategories, setContentCategories] = useState([]);
  const [contentCategoriesSelected, setContentCategoriesSelected] = useState(
    []
  );

  const { approved } = props;

  useEffect(() => {
    authService.setUrl(context.apiUrl + context.categoriesPath + "content");
    authService.request("get", {}, (response) => {
      if (response.data) {
        setContentCategories(response.data);
        let contentCategoriesSelected = response.data.map((e) => e._id);
        setContentCategoriesSelected(contentCategoriesSelected);
        loadPosts(
          0,
          nbPostsToFetch,
          username,
          contentCategoriesSelected,
          showPhotosBlurMissing,
          showFuturePosts,
          showPublicPostsOnly,
          false
        );
      }
    });
  }, []);

  const handleUserNameChange = (event) => {
    var username = event.target.value;
    if (username != undefined) {
      const authService = new AuthService();

      setUserName(username);

      authService.setUrl(context.apiUrl + context.usersPath + "suggestion");
      authService.request(
        "get",
        {
          name: username,
          show: "All",
          age: "[18,60]",
          distance: 100,
          country: "All",
          sex: "All",
          userType: "All",
          radarVisibilityOnly: false,
        },
        (response) => {
          const { data } = response;
          setModelSuggestions(data);
        }
      );
    }
  };

  const loadPosts = (
    start,
    end,
    username,
    categories,
    blurBrokenOnly,
    showFuturePosts,
    showPublicPostsOnly,
    reset
  ) => {
    // Get all posts
    setSearchLoading(true);
    authService.setUrl(context.apiUrl + context.postsPath + "all");
    authService.request(
      "get",
      {
        approved,
        blurBrokenOnly,
        categories: JSON.stringify(categories),
        end,
        future: showFuturePosts,
        mediaType: "all",
        start,
        username: username ? username : "all",
        visibility: showPublicPostsOnly ? "public" : "all",
      },
      (response) => {
        setPosts(reset ? response.data : posts.concat(response.data));
        setSearchLoading(false);
      }
    );
  };

  const showMore = () => {
    let start = posts.length;
    let end = start + nbPostsToFetch;
    loadPosts(
      start,
      end,
      username,
      contentCategoriesSelected,
      showPhotosBlurMissing,
      showFuturePosts,
      showPublicPostsOnly,
      false
    );
  };

  const handlePostRemoved = (index) => {
    posts.splice(index, 1);
    setPosts(posts);
    setToggle(!toggle);
  };

  const handlePostChanged = (post, index) => {
    posts[index] = post;
    setPosts(posts);
    setToggle(!toggle);
  };

  const onSuggestionSelected = (event, data) => {
    setUserName(data.suggestionValue);
    loadPosts(
      0,
      nbPostsToFetch,
      data.suggestionValue,
      contentCategoriesSelected,
      showPhotosBlurMissing,
      showFuturePosts,
      showPublicPostsOnly,
      true
    );
  };

  const inputProps = {
    placeholder: "User name",
    onChange: handleUserNameChange,
    value: username,
  };

  const handleContentCategoriesChange = (evt) => {
    let { value } = evt.target;
    let contentCategoriesSelected =
      typeof value === "string" ? value.split(",") : value;
    setContentCategoriesSelected(contentCategoriesSelected);
    console.log("contentCategoriesSelected", contentCategoriesSelected);
    loadPosts(
      0,
      nbPostsToFetch,
      username,
      contentCategoriesSelected,
      showPhotosBlurMissing,
      showFuturePosts,
      showPublicPostsOnly,
      true
    );
  };

  const handleBlurToggleChange = () => {
    setShowPhotosBlurMissing(!showPhotosBlurMissing);
    loadPosts(
      0,
      nbPostsToFetch,
      username,
      contentCategoriesSelected,
      !showPhotosBlurMissing,
      showFuturePosts,
      showPublicPostsOnly,
      true
    );
  };

  const handleFuturChange = () => {
    setShowFuturePosts(!showFuturePosts);
    loadPosts(
      0,
      nbPostsToFetch,
      username,
      contentCategoriesSelected,
      showPhotosBlurMissing,
      !showFuturePosts,
      showPublicPostsOnly,
      true
    );
  };

  const handlePublicOnlyChange = () => {
    setShowPublicPostsOnly(!showPublicPostsOnly);
    loadPosts(
      0,
      nbPostsToFetch,
      username,
      contentCategoriesSelected,
      showPhotosBlurMissing,
      showFuturePosts,
      !showPublicPostsOnly,
      true
    );
  };

  return (
    <>
      <Button
        className={classes.infoButton}
        onClick={() => {
          setContentCategoriesSelected([]);
          setUserName("");
        }}
        variant="contained"
      >
        Clear
      </Button>

      <FormControl className={classes.formControl}>
        <InputLabel>Content Categories</InputLabel>
        <Select
          multiple
          onChange={handleContentCategoriesChange}
          renderValue={(selected) =>
            selected
              .map((cat) => {
                let index = contentCategories.map((e) => e._id).indexOf(cat);
                return contentCategories[index]
                  ? contentCategories[index].keyname
                  : "";
              })
              .join(", ")
          }
          value={contentCategoriesSelected}
        >
          {contentCategories.map((category) => (
            <MenuItem key={category._id} value={category._id}>
              <Checkbox
                checked={contentCategoriesSelected.indexOf(category._id) > -1}
              />
              <ListItemText primary={category.keyname} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControlLabel
        className={classes.formControlLabel}
        control={
          <Checkbox
            onChange={handleBlurToggleChange}
            checked={showPhotosBlurMissing}
          />
        }
        label="Posts blur missing"
      />

      <FormControlLabel
        className={classes.formControlLabel}
        control={
          <Checkbox onChange={handleFuturChange} checked={showFuturePosts} />
        }
        label="Show future"
      />

      <FormControlLabel
        className={classes.formControlLabel}
        control={
          <Checkbox
            onChange={handlePublicOnlyChange}
            checked={showPublicPostsOnly}
          />
        }
        label="Public only"
      />

      <FormControl className={classes.formControl}>
        <Autosuggest
          suggestions={modelSuggestions.filter(
            (e) => e?.toLowerCase().indexOf(username?.toLowerCase()) !== -1
          )}
          onSuggestionsFetchRequested={() => {}}
          onSuggestionsClearRequested={() => {}}
          onSuggestionSelected={onSuggestionSelected}
          getSuggestionValue={(suggestion) => suggestion}
          shouldRenderSuggestions={() => true}
          renderSuggestion={(elmt) => <span>{elmt}</span>}
          inputProps={inputProps}
        />
      </FormControl>

      {posts.map((post, index) => (
        <Post
          key={index + post._id}
          post={post}
          index={index}
          onPostChanged={handlePostChanged}
          onPostRemoved={handlePostRemoved}
        />
      ))}

      {searchLoading && (
        <div className={classes.spinnerContainer}>
          <CircularProgress color="secondary" />
        </div>
      )}
      <Movepoint onEnter={showMore} />
    </>
  );
}
