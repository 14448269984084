import React, { useEffect, useContext } from "react";
import Movepoint from "components/Movepoint/Movepoint.js";


// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import FullscreenDialog from "components/Dialog/FullscreenDialog.js";
import CloudImage from "components/CloudImage/CloudImage";
import Button from "components/CustomButtons/Button.js";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from '@material-ui/core/Chip';
import Modal from "@material-ui/core/Modal";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Thumb from "components/Thumb/Thumb.js";
import Box from "@material-ui/core/Box";
import Badge from '@material-ui/core/Badge';
import { pink, green } from '@material-ui/core/colors';

// @material-ui/icons
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";

// import { makePdf } from "../../utils/PdfUtils";

// import GetAppIcon from "@material-ui/icons/GetApp";

import { AppContext } from "../../AppContext";
import AuthService from "../../AuthService";
import { getMonth } from "date-fns";

function formatNumber(number) {
  let res = number * 100;
  res = Math.round(res);
  return res / 100;
}

function getNumberOfDays(month,year) {
  return new Date(year, month, 0).getDate();
};

function getDayValues(length = 31) {
  if (!length) {
    length = 31;
  }
  return ["All"].concat(Array.from({length}, (_, i) => i + 1));
}

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  formControl: {
    margin: "5px",
    width: "190px"
  },
  selectEmpty: {
    marginTop: "2"
  },
  searchInput: {
    width: "300px"
  },
  textfield: {
    margin: "10px 10px 10px 0px"
  },
  link: {
    cursor: "pointer",
    fontWeight: "bold",
    color: "#333"
  },
  actionText: {
    cursor: "pointer",
    fontWeight: "bold",
    color: "#d62546",
    fontSize: "16px"
  },
  downloadIcon: {
    cursor: "pointer",
    margin: "15px 0px 0px 5px"
  },
  spinnerContainer: {
    marginTop: "10px",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  successBadge: {
    backgroundColor: green[500],
    marginRight: '5px'
  },
  dangerBadge: {
    backgroundColor: pink[500],
    marginRight: '5px'
  }
};

const useStyles = makeStyles(styles);

let typeMap = {
  All: "All",
  buyPrivateMedia: "Achat de média dans le chat",
  subscription: "Abonnement",
  tip: "Don",
  recharge: "Recharge",
  meeting: "Live"
};

const currencies = ["eur", "gbp", "usd"];
const transactionTypes = ["All", "subscription", "buyPrivateMedia", "tip", "recharge", "meeting"];
// const orders = ["Asc", "Desc"];
const monthNames = [
  "All",
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

const years = ["All"];

let staringDate = 2020;
let date = new Date();
for (let year = staringDate; year <= date.getFullYear(); ++year) {
  years.push(year);
}

let searchTimeout = null;
export default function Users() {
  const context = useContext(AppContext);
  const classes = useStyles();

  const nbTransactionsToFetch = 15;

  const [toggle, setToggle] = React.useState(false); // Just needed to force refresh
  const [transactions, setTransactions] = React.useState([]);
  const [transactionsCount, setTransactionsCount] = React.useState(0);
  const [currency, setCurrency] = React.useState(currencies[0]);
  const [order, setOrder] = React.useState("Desc");
  const [day, setDay] = React.useState(0);
  const [month, setMonth] = React.useState(0);
  const [year, setYear] = React.useState("All");
  const [columnFilter, setColumnFilter] = React.useState(13);
  const [modelSearch, setModelSearch] = React.useState("");
  const [fanSearch, setFanSearch] = React.useState("");
  const [godfatherSearch, setGodfatherSearch] = React.useState("");
  const [promoterSearch, setPromoterSearch] = React.useState("");
  const [agencySearch, setAgencySearch] = React.useState("");
  const [superGodFatherSearch, setSuperGodFatherSearch] = React.useState("");
  const [transactionType, setTransactionType] = React.useState("All");
  const [country, setCountry] = React.useState("All");
  const [countries, setCountries] = React.useState([]);
  const [group, setGroup] = React.useState("No");
  const [photoUrl, setPhotoUrl] = React.useState(null);
  const [videoUrl, setVideoUrl] = React.useState(null);
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(nbTransactionsToFetch);
  const [reset, setReset] = React.useState(false);
  const [searchLoading, setSearchLoading] = React.useState(true);
  const [tr, setTr] = React.useState({});
  const [dayValues, setDayValues] = React.useState(getDayValues());
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [showIntegrity, setShowIntegrity] = React.useState(false);

  const authServiceSingleton = React.useRef(new AuthService());
  authServiceSingleton.current.setUrl(context.apiUrl + context.transactionsPath + "all");

  const handleCancel = (id, transactions, index) => {

    transactions.splice(index, 1);
    setTransactions(transactions);
    setTransactionsCount(transactions.length);

    const authService = new AuthService();
    let data = {
      transactionId: id
    }

    authService.setUrl(context.apiUrl + context.transactionsPath + "cancel");
    authService.request("put", data, () => {});
  }

  let initColumns = [
    {
      columnName: "Model",
      getData: (data, transactions, index, showIntegrity, searchStates) => (
        <p
          className={classes.link}
          onClick={() => {
            setStart(0);
            setEnd(nbTransactionsToFetch);
            // setReset(true);
            setModelSearch(data.modelUserName);
            // setGroup("No");
            getMoreTransactions(
              true,
              0,
              nbTransactionsToFetch,
              currency,
              day,
              month,
              year,
              transactionType,
              country,
              data.modelUserName,
              searchStates.fanSearch,
              searchStates.godfatherSearch,
              searchStates.promoterSearch,
              searchStates.superGodFatherSearch,
              searchStates.agencySearch,
              showIntegrity
            );
          }}
        >
          {
            showIntegrity && data.modelUserName &&
            <Badge
              variant="dot"
              classes={{ badge: data.modelIntegrity ? classes.successBadge : classes.dangerBadge }}
            />
          }
          {data.modelUserName}
        </p>
      ),
      getCompareData: () => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: true
    },
    {
      columnName: "Fan",
      getData: (data, transactions, index, showIntegrity, searchStates) => (
        <p
          className={classes.link}
          onClick={() => {
            // setReset(true);
            setFanSearch(data.fanUserName);
            // setGroup("No");
            setStart(0);
            setEnd(nbTransactionsToFetch);
            getMoreTransactions(
              true,
              0,
              nbTransactionsToFetch,
              currency,
              day,
              month,
              year,
              transactionType,
              country,
              searchStates.modelSearch,
              data.fanUserName,
              searchStates.godfatherSearch,
              searchStates.promoterSearch,
              searchStates.superGodFatherSearch,
              searchStates.agencySearch,
              showIntegrity
            );
          }}
        >
          {
            showIntegrity &&
            <Badge
              variant="dot"
              classes={{ badge: data.fanIntegrity ? classes.successBadge : classes.dangerBadge }}
            />
          }
          {data.fanUserName}
        </p>
      ),
      getCompareData: () => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: true
    },
    {
      columnName: "Godfather",
      getData: (data, transactions, index, showIntegrity, searchStates) => (
        <p
          className={classes.link}
          onClick={() => {
            // setReset(true);
            setGodfatherSearch(data.godfatherUserName);
            // setGroup("No");
            setStart(0);
            setEnd(nbTransactionsToFetch);
            getMoreTransactions(
              true,
              0,
              nbTransactionsToFetch,
              currency,
              day,
              month,
              year,
              transactionType,
              country,
              searchStates.modelSearch,
              searchStates.fanSearch,
              data.godfatherUserName,
              searchStates.promoterSearch,
              searchStates.superGodFatherSearch,
              searchStates.agencySearch,
              showIntegrity
            );
          }}
        >
          {
            showIntegrity && data.godfatherUserName &&
            <Badge
              variant="dot"
              classes={{ badge: data.godfatherIntegrity ? classes.successBadge : classes.dangerBadge }}
            />
          }
          {data.godfatherUserName}
        </p>
      ),
      getCompareData: () => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: true
    },
    {
      columnName: "Promoter",
      getData: (data, transactions, index, showIntegrity, searchStates) => (
        <p
          className={classes.link}
          onClick={() => {
            // setReset(true);
            setPromoterSearch(data.promoterUserName);
            // setGroup("No");
            setStart(0);
            setEnd(nbTransactionsToFetch);
            getMoreTransactions(
              true,
              0,
              nbTransactionsToFetch,
              currency,
              day,
              month,
              year,
              transactionType,
              country,
              searchStates.modelSearch,
              searchStates.fanSearch,
              searchStates.godfatherSearch,
              data.promoterUserName,
              searchStates.superGodFatherSearch,
              searchStates.agencySearch,
              showIntegrity
            );
          }}
        >
          {
            showIntegrity && data.promoterUserName &&
            <Badge
              variant="dot"
              classes={{ badge: data.promoterIntegrity ? classes.successBadge : classes.dangerBadge }}
            />
          }
          {data.promoterUserName}
        </p>
      ),
      getCompareData: () => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: true
    },
    {
      columnName: "Super Godfather",
      getData: (data, transactions, index, showIntegrity, searchStates) => (
        <p
          className={classes.link}
          onClick={() => {
            // setReset(true);
            setSuperGodFatherSearch(data.superGodFatherUserName);
            // setGroup("No");
            setStart(0);
            setEnd(nbTransactionsToFetch);
            getMoreTransactions(
              true,
              0,
              nbTransactionsToFetch,
              currency,
              day,
              month,
              year,
              transactionType,
              country,
              searchStates.modelSearch,
              searchStates.fanSearch,
              searchStates.godfatherSearch,
              searchStates.promoterSearch,
              data.superGodFatherUserName,
              searchStates.agencySearch,
              showIntegrity
            );
          }}
        >
          {
            showIntegrity && data.superGodFatherUserName &&
            <Badge
              variant="dot"
              classes={{ badge: data.superGodFatherIntegrity ? classes.successBadge : classes.dangerBadge }}
            />
          }
          {data.superGodFatherUserName}
        </p>
      ),
      getCompareData: () => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: true
    },
    {
      columnName: "Agency",
      getData: (data, transactions, index, showIntegrity, searchStates) => (
        <p
          className={classes.link}
          onClick={() => {
            // setReset(true);
            setAgencySearch(data.agencyUserName);
            // setGroup("No");
            setStart(0);
            setEnd(nbTransactionsToFetch);
            getMoreTransactions(
              true,
              0,
              nbTransactionsToFetch,
              currency,
              day,
              month,
              year,
              transactionType,
              country,
              searchStates.modelSearch,
              searchStates.fanSearch,
              searchStates.godfatherSearch,
              searchStates.promoterSearch,
              searchStates.superGodFatherSearch,
              data.agencyUserName,
              showIntegrity
            );
          }}
        >
          {
            showIntegrity && data.agencyUserName &&
            <Badge
              variant="dot"
              classes={{ badge: data.agencyIntegrity ? classes.successBadge : classes.dangerBadge }}
            />
          }
          {data.agencyUserName}
        </p>
      ),
      getCompareData: () => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: true
    },
    {
      columnName: "Total",
      getData: data => data.amount,
      getCompareData: data => data.amount,
      isFilterable: true,
      isActive: true,
      isMandatory: false
    },
    {
      columnName: "Model",
      getData: data => data.type === "recharge" ? 0 : formatNumber(
        Number(data.amount) -
        Number(data.transactionFeesRounded) -
        Number(data.platformTax) -
        Number(data.godFatherTax ?? 0) -
        Number(data.promoterTax ?? 0) -
        Number(data.superGodFatherTax ?? 0) -
        Number(data.agencyTax ?? 0) -
        Number(data.vat)
      ),
      getCompareData: data => data.type === "recharge" ? 0 : formatNumber(data.amount - data.transactionFeesRounded - data.platformTax - data.godFatherTax - data.vat),
      isFilterable: true,
      isActive: true,
      isMandatory: false
    },
    {
      columnName: "Godfather",
      getData: data => formatNumber(data.godFatherTax),
      getCompareData: data => formatNumber(data.godFatherTax),
      isFilterable: true,
      isActive: true,
      isMandatory: false
    },
    {
      columnName: "Promoter",
      getData: data => formatNumber(data?.promoterTax ?? 0),
      getCompareData: data => formatNumber(data?.promoterTax ?? 0),
      isFilterable: true,
      isActive: true,
      isMandatory: false
    },
    {
      columnName: "Super Godfather",
      getData: data => formatNumber(data?.superGodFatherTax ?? 0),
      getCompareData: data => formatNumber(data?.superGodFatherTax ?? 0),
      isFilterable: true,
      isActive: true,
      isMandatory: false
    },
    {
      columnName: "Agency",
      getData: data => formatNumber(data?.agencyTax ?? 0),
      getCompareData: data => formatNumber(data?.agencyTax ?? 0),
      isFilterable: true,
      isActive: true,
      isMandatory: false
    },
    {
      columnName: "Platform",
      getData: data => formatNumber(data.platformTax),
      getCompareData: data => formatNumber(data.platformTax),
      isFilterable: true,
      isActive: true,
      isMandatory: false
    },
    {
      columnName: "Bonus",
      getData: data => formatNumber(data.transactionFeesRounded - data.transactionFees),
      getCompareData: data => formatNumber(data.transactionFeesRounded - data.transactionFees),
      isFilterable: true,
      isActive: true,
      isMandatory: false
    },
    {
      columnName: "Bank",
      getData: data => formatNumber(data.transactionFees),
      getCompareData: data => formatNumber(data.transactionFees),
      isFilterable: true,
      isActive: true,
      isMandatory: false
    },
    {
      columnName: "PSP",
      getData: data => data.psp,
      getCompareData: () => {},
      isFilterable: false,
      isActive: false,
      isMandatory: false
    },
    {
      columnName: "Charge ID",
      getData: data => data.chargeId,
      getCompareData: () => {},
      isFilterable: false,
      isActive: false,
      isMandatory: false
    },
    {
      columnName: "Type",
      getData: data => typeMap[data.type],
      getCompareData: () => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: false
    },
    {
      columnName: "City",
      getData: data => data.city,
      getCompareData: () => 0,
      isFilterable: false,
      isActive: false,
      isMandatory: false
    },
    {
      columnName: "Country",
      getData: data => data.country,
      getCompareData: () => 0,
      isFilterable: false,
      isActive: false,
      isMandatory: false
    },
    {
      columnName: "Date",
      getData: data => new Date(data.date).toLocaleString(),
      getCompareData: data => new Date(data.date),
      isFilterable: false,
      isActive: true,
      isMandatory: false
    },
    {
      columnName: "Dispute",
      getData: data => data.dispute && <Chip
        size="small"
        label={data.dispute.disputed ? "Disputed" : "Dispute"}
        color={data.dispute.disputed ? "secondary" : ""}
        onClick={() => window.open(data.dispute.url)}
      />,
      getCompareData: () => 0,
      isFilterable: false,
      isActive: false,
      isMandatory: false
    },
    {
      columnName: "Thumb",
      getData: data => (
        <>
          {data.src && data.src.small && (
            <Thumb height="50px" width="50px" src={data.src.small} />
          )}
        </>
      ),
      getCompareData: () => 0,
      isFilterable: false,
      isActive: false,
      isMandatory: false
    },
    {
      columnName: "Key",
      getData: data => data._key,
      getCompareData: () => 0,
      isFilterable: false,
      isActive: false,
      isMandatory: false
    },
    {
      columnName: "Preview",
      getData: () => (
        <>
          {(data.src || data.url) && (
            <p
              className={classes.actionText}
              onClick={() => {
                if (data.url) {
                  setVideoUrl(data.url);
                } else if (data.src) {
                  setPhotoUrl(data.src.normal);
                } else {
                  console.log("No source found !");
                }
              }}
            >
              Show
            </p>
          )}
        </>
      ),
      getCompareData: data => 0,
      isFilterable: false,
      isActive: false,
      isMandatory: false
    },
    {
      columnName: "Cancel",
      getData: (data, transactions, index) => (
        <p
          className={classes.actionText}
          onClick={() => handleCancel(data._id, transactions, index)}
        >
          Cancel
        </p>
      ),
      getCompareData: () => 0,
      isFilterable: false,
      isActive: false,
      isMandatory: false
    }
  ];

  const [columns, setColumns] = React.useState(initColumns);
  const [columnsCount, setColumnsCount] = React.useState(0);

  // const handleColumnFilterChange = event => {
  //   setColumnFilter(event.target.value);
  // };

  // const handleOrderChange = event => {
  //   setOrder(event.target.value);
  // };

  const handleIntegrityStateChange = () => {
    setShowIntegrity(!showIntegrity);

    setStart(0);
    setEnd(nbTransactionsToFetch);

    getMoreTransactions(
      true,
      0,
      nbTransactionsToFetch,
      currency,
      day,
      month,
      year,
      transactionType,
      country,
      modelSearch,
      fanSearch,
      godfatherSearch,
      promoterSearch,
      superGodFatherSearch,
      agencySearch,
      !showIntegrity
    );
  }

  const handleCurrenyChange = event => {
    setCurrency(event.target.value);
    // setReset(true);
    setStart(0);
    setEnd(nbTransactionsToFetch);

    getMoreTransactions(
      true,
      0,
      nbTransactionsToFetch,
      event.target.value,
      day,
      month,
      year,
      transactionType,
      country,
      modelSearch,
      fanSearch,
      godfatherSearch,
      promoterSearch,
      superGodFatherSearch,
      agencySearch,
      showIntegrity
    );
  };

  const handleMonthChange = event => {
    setMonth(event.target.value);
    setDayValues(getDayValues(getNumberOfDays(event.target.value ,year)));
    // setReset(true);
    setStart(0);
    setEnd(nbTransactionsToFetch);

    getMoreTransactions(
      true,
      0,
      nbTransactionsToFetch,
      currency,
      day,
      event.target.value,
      year,
      transactionType,
      country,
      modelSearch,
      fanSearch,
      godfatherSearch,
      promoterSearch,
      superGodFatherSearch,
      agencySearch,
      showIntegrity
    );
  };

  const handleDayChange = event => {
    setDay(event.target.value);
    // setReset(true);
    setStart(0);
    setEnd(nbTransactionsToFetch);

    getMoreTransactions(
      true,
      0,
      nbTransactionsToFetch,
      currency,
      event.target.value,
      month,
      year,
      transactionType,
      country,
      modelSearch,
      fanSearch,
      godfatherSearch,
      promoterSearch,
      superGodFatherSearch,
      agencySearch,
      showIntegrity
    );
  };

  const handleYearChange = event => {
    setYear(event.target.value);
    // setReset(true);
    setStart(0);
    setEnd(nbTransactionsToFetch);

    getMoreTransactions(
      true,
      0,
      nbTransactionsToFetch,
      currency,
      day,
      month,
      event.target.value,
      transactionType,
      country,
      modelSearch,
      fanSearch,
      godfatherSearch,
      promoterSearch,
      superGodFatherSearch,
      agencySearch,
      showIntegrity
    );
  };

  const handleModelSearchChange = event => {
    setModelSearch(event.target.value);
    // setReset(true);
    setStart(0);
    setEnd(nbTransactionsToFetch);

    let newValue = event.target.value;

    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      getMoreTransactions(
        true,
        0,
        nbTransactionsToFetch,
        currency,
        day,
        month,
        year,
        transactionType,
        country,
        newValue,
        fanSearch,
        godfatherSearch,
        promoterSearch,
        superGodFatherSearch,
        agencySearch,
        showIntegrity
      );
    }, 300);
  };

  const handleFanSearchChange = event => {
    setFanSearch(event.target.value);
    // setReset(true);
    setStart(0);
    setEnd(nbTransactionsToFetch);

    let newValue = event.target.value;

    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      getMoreTransactions(
        true,
        0,
        nbTransactionsToFetch,
        currency,
        day,
        month,
        year,
        transactionType,
        country,
        modelSearch,
        newValue,
        godfatherSearch,
        promoterSearch,
        superGodFatherSearch,
        agencySearch,
        showIntegrity
      );
    }, 300);
  };

  const handleGodfatherSearchChange = event => {
    setGodfatherSearch(event.target.value);
    // setReset(true);
    setStart(0);
    setEnd(nbTransactionsToFetch);

    let newValue = event.target.value;

    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      getMoreTransactions(
        true,
        0,
        nbTransactionsToFetch,
        currency,
        day,
        month,
        year,
        transactionType,
        country,
        modelSearch,
        fanSearch,
        newValue,
        promoterSearch,
        superGodFatherSearch,
        agencySearch,
        showIntegrity
      );
    }, 300);
  };

  const handlePromoterSearchChange = event => {
    setPromoterSearch(event.target.value);
    // setReset(true);
    setStart(0);
    setEnd(nbTransactionsToFetch);

    let newValue = event.target.value;

    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      getMoreTransactions(
        true,
        0,
        nbTransactionsToFetch,
        currency,
        day,
        month,
        year,
        transactionType,
        country,
        modelSearch,
        fanSearch,
        modelSearch,
        newValue,
        superGodFatherSearch,
        showIntegrity
      );
    }, 300);
  };

  const handleAgencySearchChange = event => {
    setAgencySearch(event.target.value);
    // setReset(true);
    setStart(0);
    setEnd(nbTransactionsToFetch);

    let newValue = event.target.value;

    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      getMoreTransactions(
        true,
        0,
        nbTransactionsToFetch,
        currency,
        day,
        month,
        year,
        transactionType,
        country,
        modelSearch,
        fanSearch,
        modelSearch,
        promoterSearch,
        superGodFatherSearch,
        newValue,
        showIntegrity
      );
    }, 300);
  };

  const handleSuperGodFatherSearchChange = event => {
    setSuperGodFatherSearch(event.target.value);
    // setReset(true);
    setStart(0);
    setEnd(nbTransactionsToFetch);

    let newValue = event.target.value;

    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      getMoreTransactions(
        true,
        0,
        nbTransactionsToFetch,
        currency,
        day,
        month,
        year,
        transactionType,
        country,
        modelSearch,
        fanSearch,
        modelSearch,
        promoterSearch,
        newValue,
        agencySearch,
        showIntegrity
      );
    }, 300);
  };

  const handleTransactionTypeChange = event => {
    let newValue = event.target.value;

    setTransactionType(newValue);
    // setReset(true);
    setStart(0);
    setEnd(nbTransactionsToFetch);

    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      getMoreTransactions(
        true,
        0,
        nbTransactionsToFetch,
        currency,
        day,
        month,
        year,
        newValue,
        country,
        modelSearch,
        fanSearch,
        modelSearch,
        promoterSearch,
        superGodFatherSearch,
        agencySearch,
        showIntegrity
      );
    }, 300);
  };

  // const checkDate = transaction => {
  //   let date = new Date(transaction.date);
  //   return (
  //     (month === "All" || month === monthNames[date.getMonth() + 1]) &&
  //     (year === "All" || year === date.getFullYear())
  //   );
  // };

  const handleColumnStateChange = index => event => {
    let activeColumns = columns.filter(e => e.isActive);
    let currentFilterColumnName = activeColumns[columnFilter]
      ? activeColumns[columnFilter].columnName
      : "";

    let newColumns = columns;
    newColumns[index].isActive = event.target.checked;
    setColumns(newColumns);
    setColumnsCount(activeColumns.length);

    let newFilterColumn = newColumns
      .filter(e => e.isActive)
      .map(e => e.columnName)
      .indexOf(currentFilterColumnName);
    if (newFilterColumn === -1) {
      newFilterColumn = initColumns.map(e => e.isFilterable).indexOf(true);
    }
    setColumnFilter(newFilterColumn);
    setToggle(!toggle);
  };

  // const getTransactionsFiltered = () => {
  //   let transactionsFiltered = transactions;

  //   if (group !== "No") {
  //     let res = {};
  //     transactions.forEach(transaction => {
  //       let groupkey = groupeKeyMap[group];
  //       let key = transaction[groupkey];
  //       if (key) {
  //         if (res[key]) {
  //           res[key].amount += transaction.amount;
  //           res[key].platformTax += transaction.platformTax;
  //           res[key].godFatherTax += transaction.godFatherTax;
  //         } else {
  //           transaction.type = "";
  //           transaction.src = "";
  //           transaction.url = "";
  //           transaction.fanUserName =
  //             group !== "Fan" ? "" : transaction.fanUserName;
  //           transaction.modelUserName =
  //             group !== "Model" ? "" : transaction.modelUserName;
  //           transaction.godfatherUserName =
  //             group !== "Godfather" ? "" : transaction.godfatherUserName;
  //           res[key] = JSON.parse(JSON.stringify(transaction));
  //         }
  //       }
  //     });
  //     transactionsFiltered = Object.values(res);
  //   }

  //   transactionsFiltered = transactionsFiltered
  //     .filter(e => transactionType === "All" || transactionType === e.type)
  //     .filter(
  //       e =>
  //         modelSearch === "" ||
  //         e.modelUserName.toLowerCase() === modelSearch.toLowerCase()
  //     )
  //     .filter(
  //       e =>
  //         fanSearch === "" ||
  //         e.fanUserName.toLowerCase() === fanSearch.toLowerCase()
  //     )
  //     .filter(
  //       e =>
  //         godfatherSearch === "" ||
  //         e.godfatherUserName.toLowerCase() === godfatherSearch.toLowerCase()
  //     )
  //     .filter(e => checkDate(e))
  //     .filter(e => e.currency === currency);

  //   transactionsFiltered = transactionsFiltered.filter(
  //     e => country === "All" || country === e.country
  //   );
  //   return transactionsFiltered;
  // };

  const makeDataFromTransations = () => {
    var data = [];

    transactions.forEach((transaction, index) => {
      let row = [];
      columns.forEach(column => {
        if (column.isActive) {
          let searchStates = {
            fanSearch,
            modelSearch,
            godfatherSearch,
            promoterSearch,
            superGodFatherSearch,
            agencySearch
          }
          row.push({
            value: column.getData(transaction, transactions, index, showIntegrity, searchStates),
            compare: column.getCompareData(transaction)
          });
        }
      });
      data.push(row);
    });

    return data;
  };

  // const computeTotal = data => {
  //   let res = [];
  //   let total = [];
  //   if (data.length > 0) {
  //     for (let i = 0; i < data[0].length; ++i) {
  //       total.push(0);
  //     }
  //     data.forEach(row => {
  //       for (let i = 0; i < row.length; ++i) {
  //         let elmt = row[i];
  //         if (elmt.compare) {
  //           total[i] += elmt.value;
  //         }
  //       }
  //     });
  //   }
  //   res.push(total.map(e => <h5>{e ? formatNumber(e) : "-"}</h5>));
  //   return res;
  // };

  const handleCountryChange = event => {
    let country = event.target.value;
    setCountry(country);
  };

  // const handleGroupChange = event => {
  //   let group = event.target.value;
  //   setGroup(group);
  // };

  // const buildPdf = () => {
  //   makePdf(
  //     tr,
  //     year,
  //     month,
  //     country,
  //     modelSearch,
  //     fanSearch,
  //     currency,
  //     transactionType,
  //     transactions
  //   );
  // };

  const loadDispute = () => {
    const authService = new AuthService();

    authService.setUrl(context.apiUrl + context.securionpayPath + "dispute");
    authService.request("post", {}, response => {
      const { data } = response;
      setCountries(["All"].concat(data));
    });
  }

  const checkSecurionpayIntegrity = () => {
    setLoading(true);

    const authService = new AuthService();

    let date = new Date();

    authService.setUrl(context.apiUrl + context.securionpayPath + "charges/integrity");
    authService.request(
      "get",
      {
        month: date.getMonth() + 1,
        year: date.getFullYear(),
        limit: 1000
      },
      response => {
        const { data } = response;
        alert(JSON.stringify(data, null, 4));
        setLoading(false);
      }
    );
  }

  const checkEpochIntegrity = () => {
    setLoading(true);

    const authService = new AuthService();

    let date = new Date();

    authService.setUrl(context.apiUrl + context.epochPath + "integrity");
    authService.request(
      "get",
      {
        month: date.getMonth() + 1,
        year: date.getFullYear()
      },
      response => {
        const { data } = response;
        alert(JSON.stringify(data, null, 4));
        setLoading(false);
      }
    );
  }

  const checkWalletIntegrity = () => {
    setLoading(true);

    const authService = new AuthService();

    let date = new Date();

    authService.setUrl(context.apiUrl + context.transactionsPath + "integrity");
    authService.request(
      "get",
      {
        month: date.getMonth() + 1,
        year: date.getFullYear()
      },
      response => {
        const { data } = response;
        alert(JSON.stringify(data, null, 4));
        setLoading(false);
      }
    );
  }

  useEffect(() => {
    const authService = new AuthService();

    // Get translations
    authService.setUrl(context.apiUrl + context.translationsPath);
    authService.request("get", { country: "EN", type: "site" }, response => {
      const { data } = response;
      if (data.length > 0) {
        data[0].translations.userOption.all = "All";
        setTr(data[0].translations);
      } else {
        console.log("No translations found !");
      }
    });

    // Get All countries
    authService.setUrl(context.apiUrl + context.geolocationPath + "countries");
    authService.request("get", {}, response => {
      const { data } = response;
      setCountries(["All"].concat(data));
    });

    getMoreTransactions(
      false,
      start,
      end,
      currency,
      day,
      month,
      year,
      transactionType,
      country,
      modelSearch,
      fanSearch,
      godfatherSearch,
      promoterSearch,
      superGodFatherSearch,
      agencySearch,
      showIntegrity
    );
  }, []);

  // useEffect(() => {
  //   setSearchLoading(true);
  //   getMoreTransactions(start, end, modelSearch, fanSearch, godfatherSearch, promoterSearch, superGodFatherSearch);
  // }, [
  //   // end,
  //   // currency,
  //   // day,
  //   // month,
  //   // year,
  //   // transactionType,
  //   // country,
  //   // modelSearch,
  //   // fanSearch,
  //   // godfatherSearch,
  //   // promoterSearch,
  //   // superGodFatherSearch
  // ]);

  const getMoreTransactions = (
    reset,
    start,
    end,
    currency,
    day,
    month,
    year,
    transactionType,
    country,
    modelSearch,
    fanSearch,
    godfatherSearch,
    promoterSearch,
    superGodFatherSearch,
    agencySearch,
    showIntegrity
  ) => {
    if (reset) {
      setTransactions([]);
      setTransactionsCount(0);
    }

    setSearchLoading(true);

    let activeColumns = columns.filter(e => e.isActive).map(e => e.columnName);

    // Get all transactions
    authServiceSingleton.current.cancel();
    authServiceSingleton.current.request(
      "get",
      {
        start,
        end,
        currency,
        day: day ? day : "All",
        month: month ? month : "All",
        year,
        transactionType,
        country,
        modelUserName: modelSearch,
        fanUserName: fanSearch,
        godfatherUserName: godfatherSearch,
        promoterUserName: promoterSearch,
        superGodFatherUserName: superGodFatherSearch,
        agencyUserName: agencySearch,
        showLocation: activeColumns.indexOf("Country") !== -1 || activeColumns.indexOf("City") !== -1,
        showIntegrity
      },
      response => {
        const { data } = response;
        setSearchLoading(false);
        let newTransactions = reset ? data : transactions.concat(data);
        setTransactions(newTransactions);
        setTransactionsCount(newTransactions.length);

        setStart(newTransactions.length);
        setEnd(newTransactions.length + nbTransactionsToFetch);
      }
    );
  }

  useEffect(() => {
    let data = makeDataFromTransations();
    setData(data);
  }, [transactionsCount, columnsCount]);

  let total = [];

  const showMore = () => {
    setReset(false);
    // setStart(end);
    // setEnd(end + nbTransactionsToFetch);

    getMoreTransactions(
      false,
      start,
      end,
      currency,
      day,
      month,
      year,
      transactionType,
      country,
      modelSearch,
      fanSearch,
      godfatherSearch,
      promoterSearch,
      superGodFatherSearch,
      agencySearch,
      showIntegrity
    );
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "auto",
    bgcolor: 'white',
    border: '2px solid #2D2D2D',
    boxShadow: 24,
    width: "80px",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px"
  };

  return (
    <>
      <Modal
        open={loading}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CircularProgress />
          <label>Loading</label>
        </Box>
      </Modal>

      <FullscreenDialog
        isOpened={photoUrl !== null || videoUrl !== null}
        content={
          <>
            {photoUrl && (
              <CloudImage
                src={photoUrl}
                defaultHeight="100%"
                defaultWidth="auto"
                alt="profil"
              />
            )}
            {videoUrl && <video src={videoUrl} controls autoPlay />}
          </>
        }
        onClose={() => {
          setVideoUrl(null);
          setPhotoUrl(null);
        }}
      />

      <FormControl className={classes.formControl}>
        <Button
          fullWidth
          color="primary"
          onClick={() => checkSecurionpayIntegrity()}
        >
          Check Securionpay integrity
      </Button>
      </FormControl>

      <FormControl className={classes.formControl}>
        <Button
          fullWidth
          color="primary"
          onClick={() => checkEpochIntegrity()}
        >
          Check Epoch integrity
      </Button>
      </FormControl>

      <FormControl className={classes.formControl}>
        <Button
          fullWidth
          color="primary"
          onClick={() => checkWalletIntegrity()}
        >
          Check Wallets integrity
      </Button>
      </FormControl>

      <FormControl className={classes.formControl}>
        <Button
          fullWidth
          color="primary"
          onClick={() => loadDispute()}
        >
          Load dispute
      </Button>
      </FormControl>

      <br />

      <FormControl className={classes.formControl}>
        <InputLabel>Currency</InputLabel>
        <Select onChange={handleCurrenyChange} value={currency}>
          {currencies.map(currency => (
            <MenuItem value={currency}>{currency}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel>Year</InputLabel>
        <Select onChange={handleYearChange} value={year}>
          {years.map(y => (
            <MenuItem value={y}>{y}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel>Month</InputLabel>
        <Select onChange={handleMonthChange} value={month}>
          {monthNames.map((m, index) => (
            <MenuItem value={index}>{m}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel>Day</InputLabel>
        <Select onChange={handleDayChange} value={day}>
          {dayValues.map((v, index) => (
            <MenuItem value={index}>{v}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel>Transaction type</InputLabel>
        <Select onChange={handleTransactionTypeChange} value={transactionType}>
          {transactionTypes.map(transactionType => (
            <MenuItem value={transactionType}>
              {typeMap[transactionType]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* <FormControl className={classes.formControl}>
        <InputLabel>Column Filter</InputLabel>
        <Select onChange={handleColumnFilterChange} value={columnFilter}>
          {columns
            .filter(column => column.isFilterable && column.isActive)
            .map(column => {
              var index = columns
                .filter(column => column.isActive)
                .map(field => field.columnName)
                .indexOf(column.columnName);
              return <MenuItem value={index}>{column.columnName}</MenuItem>;
            })}
        </Select>
      </FormControl> */}

      {/* <FormControl className={classes.formControl}>
        <InputLabel>Sort</InputLabel>
        <Select onChange={handleOrderChange} value={order}>
          {orders.map(order => (
            <MenuItem value={order}>{order}</MenuItem>
          ))}
        </Select>
      </FormControl> */}

      <FormControl className={classes.formControl}>
        <InputLabel>Countries</InputLabel>
        <Select onChange={handleCountryChange} value={country}>
          {countries.map(countrie => (
            <MenuItem key={countrie} value={countrie}>
              {countrie}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControlLabel
        control={
          <Checkbox
            icon={<RadioButtonUncheckedIcon />}
            checkedIcon={<CheckCircleIcon />}
            checked={showIntegrity}
            onChange={handleIntegrityStateChange}
            color="secondary"
          />
        }
        label="Show integrity"
      />

      {/* <FormControl className={classes.formControl}>
        <InputLabel>Group</InputLabel>
        <Select onChange={handleGroupChange} value={group}>
          {groupTypes.map(group => (
            <MenuItem key={group} value={group}>
              {group}
            </MenuItem>
          ))}
        </Select>
      </FormControl> */}

      {/* <GetAppIcon
        onClick={buildPdf}
        className={classes.downloadIcon}
        fontSize="large"
      /> */}

      <br />

      <TextField
        className={classes.textfield}
        value={modelSearch}
        label="Model name"
        type="search"
        variant="outlined"
        onChange={handleModelSearchChange}
      />
      <TextField
        className={classes.textfield}
        value={fanSearch}
        label="Fan name"
        type="search"
        variant="outlined"
        onChange={handleFanSearchChange}
      />
      <TextField
        className={classes.textfield}
        value={godfatherSearch}
        label="Godfather name"
        type="search"
        variant="outlined"
        onChange={handleGodfatherSearchChange}
      />
      <TextField
        className={classes.textfield}
        value={promoterSearch}
        label="Promoter name"
        type="search"
        variant="outlined"
        onChange={handlePromoterSearchChange}
      />
      <TextField
        className={classes.textfield}
        value={superGodFatherSearch}
        label="Super godFather name"
        type="search"
        variant="outlined"
        onChange={handleSuperGodFatherSearchChange}
      />
      <TextField
        className={classes.textfield}
        value={agencySearch}
        label="Agency name"
        type="search"
        variant="outlined"
        onChange={handleAgencySearchChange}
      />

      <br />

      {columns.map((column, index) => (
        <FormControlLabel
          control={
            <Checkbox
              disabled={column.isMandatory}
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={<CheckCircleIcon />}
              checked={column.isActive}
              onChange={handleColumnStateChange(index)}
              color="secondary"
            />
          }
          label={column.columnName}
        />
      ))}

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Transactions</h4>
              <p className={classes.cardCategoryWhite}>
                Here are all the transactions
              </p>
            </CardHeader>

            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={columns
                  .filter(column => column.isActive)
                  .map(column => column.columnName)}
                tableData={total.concat(
                  data.map(e => e.map(e => e.value))
                )}
              />
            </CardBody>

            {searchLoading && (
              <div className={classes.spinnerContainer}>
                <CircularProgress color="secondary" />
              </div>
            )}
            {
              transactions.length > 0 &&
              <Movepoint onEnter={showMore} />
            }
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}
