import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import CloudImage from "components/CloudImage/CloudImage";

const styles = {
  thumb: {
    objectFit: 'cover',
    borderRadius: '5px'
  }
};

export default function Thumb(props) {

    const useStyles = makeStyles(styles);
    const classes = useStyles();

    const { height, width, src } = props;
    return (
      <CloudImage
        className={classes.thumb}
        src={src}
        defaultHeight={height}
        defaultWidth={width}
        alt="thumb"
      />
  );
}
