import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../AppContext";
import AuthService from "../../AuthService";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AccountCircle from "@material-ui/icons/AccountCircle";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function Login() {
  const classes = useStyles();

  const context = useContext(AppContext);

  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");

  let history = useHistory();

  useEffect(() => {}, []);

  const sigin = () => {
    let credentials = { login, password };

    var authService = new AuthService(
      context.apiUrl + context.authPath + "admin"
    );
    authService.setCacheAge(24 * 3600);
    authService.login(credentials, response => {
      history.push("/admin/dashboard");
    });
  };

  const handleLoginChange = evt => {
    setLogin(evt.target.value);
  };

  const handlePassChange = evt => {
    setPassword(evt.target.value);
  };

  return (
    <div className={classes.center}>
      <Card style={{ width: "400px" }}>
        <CardHeader color="info" stats icon>
          <CardIcon color="info">
            <AccountCircle />
          </CardIcon>
          <p className={classes.cardCategory}>Login</p>
        </CardHeader>
        <CardBody>
          <TextField
            value={login}
            onChange={handleLoginChange}
            style={{ width: "100%" }}
            label="Login"
            variant="outlined"
          />
          <TextField
            value={password}
            onChange={handlePassChange}
            style={{ marginTop: "20px", width: "100%" }}
            label="Pass"
            type="password"
            variant="outlined"
          />
          <div className={classes.center}>
            <Button
              style={{ marginTop: "20px" }}
              variant="outlined"
              color="primary"
              onClick={sigin}
            >
              Login
            </Button>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}
