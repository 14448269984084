import React from "react";
import CloudImage from "../CloudImage/CloudImage";
import AuthService from "../../AuthService";
import { AppContext } from "../../AppContext";
import CircularProgress from "@material-ui/core/CircularProgress";
import { isDeviceMobile } from "../../utils/DeviceUtils";
import Movepoint from "components/Movepoint/Movepoint.js";

import "./SmartVideo.css";

class SmartVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      video: null,
      thumb: props.thumb,
      isVideoReady: false,
      currentTime: 0,
      height: props.defaultHeight ? props.defaultHeight : "500px",
      width: props.defaultWidth ? props.defaultWidth : "500px",
      start: 0,
      end: 1,
      step: 1,
      downloadRange: 20 * 1000 * 1000,
      fetchDelay: 100,
      isAbleToStream: true
    };
    this.queue = [];
    this.videoRef = React.createRef();
  }

  componentWillReceiveProps = nextProps => {
    const { video, thumb } = this.props;
    if (
      (nextProps.video && video !== nextProps.video) ||
      (nextProps.thumb && thumb !== nextProps.thumb)
    ) {
      this.loadVideo(nextProps.video, nextProps.thumb, nextProps.play);
    }

    let isPlaying = this.isPlaying();
    if (nextProps.play && !isPlaying) {
      this.play();
    } else if (!nextProps.play && isPlaying) {
      this.pause();
    }
  };

  componentDidMount = () => {
    this.authService = new AuthService();
    const { video, thumb, play } = this.props;
    if (video) {
      this.loadVideo(video, thumb, play);
    }
  };

  loadVideo = (video, thumb, play) => {
    this.setState({
      thumb
    });
    if (video) {
      this.setState({
        video
      });
    }
  };

  handleMetaDataLoaded = () => {
    const { onLoadedMetadata, currentTime } = this.props;

    let videoRef = this.videoRef.current;

    if (currentTime) {
      videoRef.currentTime = currentTime;
    }

    if (onLoadedMetadata) {
      onLoadedMetadata(videoRef);
    }
  };

  handleVideoLoaded = () => {
    const { autoPlay, onCanPlay } = this.props;
    const { currentTime } = this.state;
    if (onCanPlay) {
      onCanPlay();
    }

    if (autoPlay || currentTime) {
      this.play();
    }
  };

  play = currentTime => {
    if (this.videoRef && this.videoRef.current && this.videoRef.current.play) {
      this.videoRef.current.volume = 0.1;
      this.videoRef.current.play();
      if (currentTime) {
        this.videoRef.current.currentTime = currentTime;
      }
    } else {
      console.log("Can not play !");
    }
  };

  pause = () => {
    if (this.videoRef && this.videoRef.current && this.videoRef.current.pause) {
      this.videoRef.current.pause();
    }
  };

  handlePause = () => {
    const { onPause } = this.props;
    if (onPause) {
      onPause();
    }
  };

  handlePlay = () => {
    const { onPlay } = this.props;
    if (onPlay) {
      onPlay();
    }
  };

  isPlaying = () => {
    return (
      this.videoRef && this.videoRef.current && !this.videoRef.current.paused
    );
  };

  handleError = error => {
    const { onError } = this.props;
    if (onError) {
      onError(error);
    }
  };

  render() {
    const {
      className,
      loop,
      autoPlay,
      controls,
      onThumbLoaded,
      conserveRatio,
      muted,
      activeWaypoint,
      onClick
    } = this.props;

    const { video, thumb, isVideoReady, height, width } = this.state;

    const isMobile = isDeviceMobile();

    return (
      <>
        <div
          className={className + " smart-video-container"}
          style={{ height, width }}
        >
          {!isVideoReady && thumb && (
            <>
              <CloudImage
                className="smart-video-thumb"
                src={typeof thumb === "string" ? thumb : thumb.normal}
                onImageLoaded={onThumbLoaded}
                defaultHeight={height}
                defaultWidth={width}
                conserveRatio={conserveRatio}
                onClick={onClick}
                alt="thumb"
              />

              <div className="content-center disable-pointer-events">
                <CircularProgress color="secondary" />
              </div>
            </>
          )}

          {/* Force to play or pause video when it's visible or not on the screen */}
          <div className="content-center disable-pointer-events z-index-neg">
            {activeWaypoint && (
              <>
                {isMobile ? (
                  <Movepoint
                    scrollableAncestor={window}
                    onEnter={() => (autoPlay ? this.play() : null)}
                    onLeave={() => (autoPlay ? this.pause() : null)}
                  />
                ) : (
                  <Movepoint
                    onEnter={() => (autoPlay ? this.play() : null)}
                    onLeave={() => (autoPlay ? this.pause() : null)}
                  />
                )}
              </>
            )}
          </div>

          {video && (
            <video
              ref={this.videoRef}
              crossOrigin="https://uncove.com"
              className="smart-video"
              onCanPlay={this.handleVideoLoaded}
              onLoadedMetadata={this.handleMetaDataLoaded}
              loop={loop}
              onError={this.handleError}
              autoPlay={autoPlay}
              controls={controls}
              onClick={onClick}
              muted={muted}
              onPlay={this.handlePlay}
              onPause={this.handlePause}
              onContextMenu={e => {
                e.preventDefault();
              }}
              playsInline
            >
              {/*
                            --- Disable IOS auto full screen ---
                            webkit-playsinline : iOS < 10
                            playsinline        : iOS >= 10
                        */}
              <source src={video} />
            </video>
          )}
        </div>
      </>
    );
  }
}
SmartVideo.contextType = AppContext;

export default SmartVideo;
