import React, { useEffect, useState, useContext } from "react";
import { parse } from "json2csv";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "@material-ui/core/Chip";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Movepoint from "components/Movepoint/Movepoint.js";
import Autosuggest from "react-autosuggest";

// @material-ui/icons
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";

import { AppContext } from "../../AppContext";
import AuthService from "../../AuthService";
import { func } from "prop-types";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  formControl: {
    margin: "5px",
    width: "120px",
  },
  selectEmpty: {
    marginTop: "2",
  },
  searchInput: {
    width: "300px",
  },
  actionText: {
    cursor: "pointer",
    fontWeight: "bold",
    color: "#4caf50",
    fontSize: "16px",
  },
  warningText: {
    cursor: "pointer",
    fontWeight: "bold",
    color: "orange",
    fontSize: "16px",
  },
  infoText: {
    cursor: "pointer",
    fontWeight: "bold",
    color: "purple",
    fontSize: "16px",
  },
  balanceText: {
    cursor: "pointer",
    fontWeight: "bold",
    color: "skyblue",
    fontSize: "16px",
  },
  emptyBalanceText: {
    cursor: "pointer",
    fontWeight: "bold",
    color: "#aaa",
    fontSize: "16px",
  },
  actionDisableText: {
    cursor: "pointer",
    fontWeight: "bold",
    color: "#aaa",
    fontSize: "16px",
  },
  inputText: {
    width: "100px",
    padding: 5,
    margin: 0,
    fontize: 14,
  },
  inputLongText: {
    width: "200px",
  },
  spinnerContainer: {
    marginTop: "10px",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  flag: {
    height: "30px",
  },
  walletList: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  walletItem: {
    display: "flex",
    flexDirection: "row",
    gap: "5px",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "20px",
    cursor: "pointer",
  },
  colorPink: {
    color: "#d62546",
  },
  colorGreen: {
    color: "#4caf50",
  },
  colorSkyblue: {
    color: "skyblue",
  },
  leftAutoSuggest: {
    margin: "10px 0",
    position: "relative",
    zIndex: "5",
  },
};

const useStyles = makeStyles(styles);

export default function Wallets() {
  const context = useContext(AppContext);
  const classes = useStyles();

  function getFirstDayOfCurrentMonth() {
    let now = new Date();
    let firstDayOfCurrentMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    return firstDayOfCurrentMonth.toISOString();
  }

  function sleep(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  let firstDayOfCurrentMonth = getFirstDayOfCurrentMonth();
  let now = new Date().toISOString();

  const initColumns = [
    {
      columnName: "First Name",
      getData: (user) => user.firstName,
      getCompareData: (user) => user.firstName,
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
    {
      columnName: "Last Name",
      getData: (user) => user.lastName,
      getCompareData: (user) => user.lastName,
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
    {
      columnName: "User Name",
      getData: (user) => user.userName,
      getCompareData: (user) => user.userName,
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
    {
      columnName: "Type",
      getData: (user) => (user.isModel ? "Model" : "Fan"),
      getCompareData: (user) => user.isModel,
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
    {
      columnName: "Payout ID",
      getData: (data) =>
        data.revolut
          ? data.revolut.recipientAccountId
          : data.paypalEmail
          ? data.paypalEmail.split("/").splice(-1)[0]
          : "",
      getCompareData: (data) => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
    {
      columnName: "IN",
      getData: (data) => (
        <p>
          {data.transactions && data.transactions.in
            ? data.transactions.in.length
            : 0}
        </p>
      ),
      getCompareData: () => {},
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
    {
      columnName: "OUT",
      getData: (data) => (
        <p>
          {data.transactions && data.transactions.out
            ? data.transactions.out.length
            : 0}
        </p>
      ),
      getCompareData: () => {},
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
    {
      columnName: "Last payout",
      getData: (data) => (
        <p>
          {data.lastPayoutDate
            ? new Date(data.lastPayoutDate).toLocaleDateString()
            : ""}
        </p>
      ),
      getCompareData: () => {},
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
    {
      columnName: "IBAN Update",
      getData: (data) => (
        <p>
          {data.wise?.accountDetails?.lastUpdate
            ? new Date(
                data.wise?.accountDetails?.lastUpdate
              ).toLocaleDateString()
            : ""}
        </p>
      ),
      getCompareData: () => {},
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
    {
      columnName: "Balance",
      getData: (data) => (
        <p
          className={
            data.balance ? classes.balanceText : classes.emptyBalanceText
          }
        >
          {data.balance} {data.currency.symbol}
        </p>
      ),
      getCompareData: (data) => data.balance,
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
    {
      columnName: "Integrity",
      getData: (data) => (
        <p
          onClick={() =>
            checkIntegrity(data.userId, data.currency ? data.currency._id : "")
          }
          className={classes.warningText}
        >
          Integrity
        </p>
      ),
      getCompareData: () => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
    {
      columnName: "Fix",
      getData: (data) => (
        <p
          onClick={() =>
            fixIntegrity(data.userId, data.currency ? data.currency._id : "")
          }
          className={classes.infoText}
        >
          Fix
        </p>
      ),
      getCompareData: () => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
    {
      columnName: "Status",
      getData: (data) => (
        <>
          {data.deleted ? (
            <Chip
              label="Inactive"
              style={{ background: "#d62546", color: "#FFF" }}
            />
          ) : (
            <Chip
              label="Active"
              style={{ background: "#5DBB63", color: "#FFF" }}
            />
          )}
        </>
      ),
      getCompareData: () => {},
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
    {
      columnName: "Payout",
      getData: (data, currentPayouts, wallets, index) =>
        data.balance > 0 &&
        !data.pausedTransfers &&
        (data.paypalEmail ||
          (data.revolut && data.revolut.recipientAccountId)) ? (
          <Box display="flex" flexDirection="row" alignItems="center" gap="10">
            <FormControl className={classes.formControl}>
              <Select
                onChange={(evt) =>
                  handlePayoutDateChange(evt.target.value, wallets, index)
                }
                value={data.payoutDate}
              >
                <MenuItem key="firstDay" value={firstDayOfCurrentMonth}>
                  {new Date(firstDayOfCurrentMonth).toLocaleDateString()}
                </MenuItem>
                <MenuItem key="now" value={"now"}>
                  Immediately
                </MenuItem>
              </Select>
            </FormControl>

            <Box
              style={{ marginLeft: "10px" }}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
            >
              <label
                onClick={() => checkPayoutAmount(data)}
                className={classes.balanceText}
              >
                Total
              </label>

              <label
                onClick={() =>
                  exportTransactions(
                    data.userId,
                    data.currency ? data.currency.code : "",
                    data.payoutDate
                  )
                }
                className={classes.warningText}
              >
                Summary
              </label>

              <label
                onClick={() =>
                  data.revolut && data.revolut.recipientAccountId
                    ? handlePayout(data, currentPayouts, wallets, index)
                    : paypalPayout(data, wallets, index)
                }
                className={classes.infoText}
              >
                Payout
              </label>
            </Box>
          </Box>
        ) : data.pausedTransfers ? (
          <Chip
            label="Paused"
            style={{ background: "#d62546", color: "#FFF" }}
          />
        ) : (
          <p className={classes.actionDisableText}>Payout</p>
        ),
      getCompareData: () => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
  ];

  const [columnFilter, setColumnFilter] = useState(
    initColumns
      .filter((e) => e.isActive)
      .map((e) => e.columnName)
      .indexOf("Balance")
  );

  const [columns, setColumns] = useState(initColumns);

  const [currencies, setCurrencies] = useState([]);
  const [currency, setCurrency] = useState("");

  const [wallets, setWallets] = useState([]);
  const [walletsInfo, setWalletsInfo] = useState(null);
  const [userWallets, setUserWallets] = useState([]);

  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(50);
  const [searchLoading, setSearchLoading] = useState(true);

  const [userNameInput, setUserNameInput] = useState("");
  const [userName, setUserName] = useState("");
  const [userNameSuggestions, setUserNameSuggestions] = useState([]);

  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPayouts, setCurrentPayouts] = useState([]);

  const authService = new AuthService();
  const authServiceSingleton = React.useRef(new AuthService());

  useEffect(() => {
    // Get all currencies
    authService.setUrl(
      context.apiUrl + context.categoriesPath + "currencies/all"
    );
    authService.request("get", {}, (response) => {
      const currencies = response.data;
      setCurrencies(currencies);

      // Get all balances
      authService.setUrl(context.apiUrl + context.revolutPath + "balances");
      authService.request("get", {}, (response) => {
        let wallets = response.data;
        wallets = wallets.map((w) => {
          let currency = currencies.filter(
            (c) => c.code.toLowerCase() === w.currency.toLowerCase()
          );
          return {
            ...w,
            ...{
              flag: currency.length > 0 ? currency[0].flag : "",
              currencyId: currency.length > 0 ? currency[0]._id : "",
            },
          };
        });
        setWallets(wallets);
        let map = {};
        wallets.map((wallet) => {
          map[wallet.currencyId] = 0;
        });
        setCurrentPayouts(map);
      });
    });
  }, []);

  useEffect(() => {
    authService.setUrl(context.apiUrl + context.walletPath + "currency/info");
    authService.request("get", { currencyId: currency }, (response) => {
      if (!response.data.error) {
        setWalletsInfo(response.data);
      }
    });
  }, [currency]);

  useEffect(() => {
    getWallets(true);
  }, [currency, columnFilter, userName]);

  const checkIntegrity = (userId, currencyId) => {
    setLoading(true);
    authServiceSingleton.current.cancel();
    authServiceSingleton.current.setUrl(
      context.apiUrl + context.walletPath + "integrity"
    );
    const data = {
      userId,
      currencyId,
    };
    authServiceSingleton.current.request("get", data, async (response) => {
      const { data } = response;
      let { jobId, queue: queueName } = data;
      response = await pullJob(jobId, queueName);
      alert(JSON.stringify(response.data, null, 4));
      setLoading(false);
    });
  };

  const fixIntegrity = (userId, currencyId) => {
    setLoading(true);
    authServiceSingleton.current.cancel();
    authServiceSingleton.current.setUrl(
      context.apiUrl + context.walletPath + "fix"
    );
    const data = {
      userId,
      currencyId,
    };
    authServiceSingleton.current.request("put", data, async (response) => {
      const { data } = response;
      let { jobId, queue: queueName } = data;
      response = await pullJob(jobId, queueName);
      alert(JSON.stringify(response.data, null, 4));
      setLoading(false);
    });
  };

  function pullJob(jobId, queueName) {
    try {
      return new Promise(async (resolve, reject) => {
        console.log(`Pull job !`);

        let isFinished = false;

        authServiceSingleton.current.setUrl(
          context.apiUrl + context.walletPath + "job"
        );

        while (!isFinished) {
          let jobResponse = await getJob(jobId, queueName);
          if (jobResponse?.data) {
            const { status } = jobResponse.data;

            if (status && status !== "in-progress" && status !== "waiting") {
              isFinished = true;
              resolve(jobResponse);
              return;
            }
          }

          await sleep(3000);
        }
      });
    } catch (error) {
      console.log("Error", error);
    }
  }

  function getJob(jobId, queueName) {
    return new Promise((resolve, reject) => {
      authServiceSingleton.current.request(
        "get",
        { jobId, queueName },
        async (response) => {
          resolve(response);
        }
      );
    });
  }

  const exportTransactions = (userId, currency, payoutDate) => {
    setLoading(true);
    authServiceSingleton.current.cancel();
    authServiceSingleton.current.setUrl(
      context.apiUrl + context.transactionsPath + "user/summary"
    );

    const now = payoutDate === "now" ? new Date() : new Date(payoutDate);

    now.setMonth(now.getMonth() - 1);

    let month = now.getMonth() + 1 + "";
    let year = now.getFullYear() + "";

    const data = {
      userId,
      currency,
      month,
      year,
    };
    authServiceSingleton.current.request("get", data, (response) => {
      const { data } = response;
      setLoading(false);
      const csv = parse(data);

      // Download summary as CSV file
      const element = document.createElement("a");
      const file = new Blob([csv], { type: "text/plain" });
      element.href = URL.createObjectURL(file);
      element.download = `${userId}_${month}_${year}.csv`;
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();

      alert(data.splice(-1)[0]?.income ?? "");
    });
  };

  const handlePayoutDateChange = (value, userWallets, index) => {
    if (value !== "now") {
      let date = new Date(value).toISOString();
      userWallets[index].payoutDate = date;
    } else {
      userWallets[index].payoutDate = value;
    }
    refreshWallets(userWallets);
  };

  const handlePayout = (data, currentPayouts, userWallets, index) => {
    setLoading(true);

    authServiceSingleton.current.cancel();
    authServiceSingleton.current.setUrl(
      context.apiUrl + context.revolutPath + "payout"
    );

    const revolutData = {
      currencyId: data.currency ? data.currency._id : "",
      date: data.payoutDate !== "now" ? data.payoutDate : undefined,
      userId: data.userId,
    };

    currentPayouts[data.currency._id] += Number(data.balance);
    setCurrentPayouts(currentPayouts);
    userWallets[index].balance = 0;

    authServiceSingleton.current.request("post", revolutData, (response) => {
      const { data } = response;
      if (data.error) {
        alert(data.error);
      } else {
        refreshWallets(userWallets);
        alert(`Payout done : ${data.targetValue} ${data.targetCurrency}`);
      }
      setLoading(false);
    });
  };

  const paypalPayout = (data, userWallets, index) => {
    setLoading(true);

    authServiceSingleton.current.cancel();
    authServiceSingleton.current.setUrl(
      context.apiUrl + context.paypalPath + "payout"
    );

    alert(data.payoutDate);

    const paypalData = {
      currencyId: data.currency ? data.currency._id : "",
      date: data.payoutDate !== "now" ? data.payoutDate : undefined,
      userId: data.userId,
    };

    currentPayouts[data.currency._id] += Number(data.balance);
    setCurrentPayouts(currentPayouts);
    userWallets[index].balance = 0;

    authServiceSingleton.current.request("post", paypalData, (response) => {
      const { data } = response;
      if (data.error) {
        alert(data.error);
      } else {
        refreshWallets(userWallets);
        alert(`Payout done`);
      }
      setLoading(false);
    });
  };

  const checkPayoutAmount = (data) => {
    authServiceSingleton.current.cancel();
    authServiceSingleton.current.setUrl(context.apiUrl + context.walletPath);

    const walletData = {
      date: data.payoutDate !== "now" ? data.payoutDate : "",
      userId: data.userId,
    };

    authServiceSingleton.current.request("get", walletData, (response) => {
      if (response.data.error) {
        alert(response.data.error);
      } else {
        alert(
          response.data.filter(
            (e) => e.currency === data.currency?.code ?? ""
          )[0]?.balance ?? ""
        );
      }
      setLoading(false);
    });
  };

  const refreshWallets = (data) => {
    setUserWallets(data);
    let tableData = makeData(data);
    setTableData(tableData);
  };

  const handleCurrencyChange = (evt) => {
    let { value } = evt.target;
    setCurrency(value);
  };

  const handleColumnStateChange = (index) => (event) => {
    let activeColumns = columns.filter((e) => e.isActive);
    let currentFilterColumnName = activeColumns[columnFilter]
      ? activeColumns[columnFilter].columnName
      : "";

    let newColumns = columns;
    newColumns[index].isActive = event.target.checked;
    setColumns(newColumns);

    let newFilterColumn = newColumns
      .filter((e) => e.isActive)
      .map((e) => e.columnName)
      .indexOf(currentFilterColumnName);
    if (newFilterColumn === -1) {
      newFilterColumn = initColumns.map((e) => e.isFilterable).indexOf(true);
    }
    setColumnFilter(newFilterColumn);
  };

  const makeData = (allRows) => {
    var data = [];
    allRows.forEach((user, userIndex) => {
      var row = [];
      columns.forEach((column) => {
        if (column.isActive) {
          row.push({
            value: column.getData(user, currentPayouts, allRows, userIndex),
            compare: column.getCompareData(user),
          });
        }
      });
      data.push(row);
    });
    return data;
  };

  const getMoreWallets = () => {
    // Avoid to be triggered immediatly
    if (tableData.length > 0) {
      getWallets(false);
    }
  };
  const getWallets = (reset = false) => {
    setSearchLoading(true);
    authServiceSingleton.current.cancel();
    authServiceSingleton.current.setUrl(
      context.apiUrl + context.walletPath + "all"
    );
    authServiceSingleton.current.request(
      "get",
      {
        currencyId: currency,
        start: reset ? 0 : start,
        end: reset ? 20 : end,
        userName: userName,
      },
      (response) => {
        const { data } = response;
        setSearchLoading(false);
        let newUserWallets = reset ? data : userWallets.concat(data);
        setUserWallets(newUserWallets);
        let tableData = makeData(
          newUserWallets.map((e) => {
            return {
              ...e,
              ...{ payoutDate: getFirstDayOfCurrentMonth() },
            };
          })
        );
        setTableData(tableData);
        let newStart = newUserWallets.length;
        let newEnd = newStart + 20;
        setStart(newStart);
        setEnd(newEnd);
      }
    );
  };

  const userNameInputProps = {
    placeholder: "User Name",
    onChange: (event) => handleUserNameChange(event),
    value: userNameInput,
    style: {
      padding: "17.75px 20px",
      width: "315px",
      boxSizing: "border-box",
    },
  };

  const handleUserNameChange = (event) => {
    let username = event.target.value;
    if (username != undefined) {
      setUserNameInput(username);
      if (userName) setUserName("");

      if (!username) {
        setUserNameSuggestions([]);
        return;
      }
      authService.setUrl(context.apiUrl + context.usersPath + "suggestion");
      authService.request(
        "get",
        {
          name: username,
          show: "All",
          age: "[18,60]",
          distance: 100,
          country: "All",
          sex: "All",
          userType: "All",
          radarVisibilityOnly: false,
        },
        (response) => {
          const { data } = response;
          setUserNameSuggestions(data);
        }
      );
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "white",
    border: "2px solid #2D2D2D",
    boxShadow: 24,
    width: "80px",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
  };

  let symbol = "";
  if (userWallets.length > 0) {
    symbol = currencies.filter((e) => e._id == currency)[0].symbol;
  }

  return (
    <>
      <Modal
        open={loading}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CircularProgress />
          <label>Loading</label>
        </Box>
      </Modal>

      <FormControl className={classes.formControl}>
        <div className={classes.walletList}>
          {wallets.map((w) => (
            <div
              className={classes.walletItem}
              onClick={() => setCurrency(w.currencyId)}
            >
              <img className={classes.flag} src={w.flag} />
              <p>{w.currency}</p>
              <b>
                {Number(w.amount.value) -
                  Number(
                    currentPayouts[w.currencyId]
                      ? currentPayouts[w.currencyId]
                      : 0
                  )}
              </b>
            </div>
          ))}
        </div>
      </FormControl>

      <br />

      <FormControl className={classes.formControl}>
        <InputLabel>Currencies</InputLabel>
        <Select onChange={handleCurrencyChange} value={currency}>
          {currencies.map((currency) => (
            <MenuItem key={currency.code} value={currency._id}>
              {currency.code.toUpperCase()}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <br />

      {columns.map((column, index) => (
        <FormControlLabel
          key={column.columnName}
          control={
            <Checkbox
              disabled={column.isMandatory}
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={<CheckCircleIcon />}
              checked={column.isActive}
              onChange={handleColumnStateChange(index)}
              color="secondary"
            />
          }
          label={column.columnName}
        />
      ))}

      <div className={classes.leftAutoSuggest}>
        <Autosuggest
          suggestions={userNameSuggestions.filter(
            (e) => e?.toLowerCase().indexOf(userNameInput?.toLowerCase()) !== -1
          )}
          onSuggestionsFetchRequested={() => {}}
          onSuggestionsClearRequested={() => {}}
          onSuggestionSelected={(event, data) => {
            setUserNameInput(data.suggestionValue);
            setUserName(data.suggestionValue);
          }}
          getSuggestionValue={(suggestion) => suggestion}
          shouldRenderSuggestions={() => true}
          renderSuggestion={(elmt) => <span>{elmt}</span>}
          inputProps={userNameInputProps}
        />
      </div>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Wallets</h4>
              <p className={classes.cardCategoryWhite}>
                Here are all the wallets
              </p>
            </CardHeader>
            <CardBody>
              {userWallets.length > 0 && (
                <div>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    {wallets
                      .filter((e) => e.currencyId === currency)
                      .map((w) => (
                        <div className={classes.walletItem}>
                          <img className={classes.flag} src={w.flag} />
                          <p>{w.currency}</p>
                        </div>
                      ))}

                    {walletsInfo && (
                      <>
                        <label style={{ marginTop: "5px" }}>
                          Balance{" "}
                          <b className={classes.colorGreen}>
                            {walletsInfo.balanceAmount?.toFixed(2) || 0}{" "}
                            {symbol}
                          </b>
                        </label>
                        <label style={{ marginTop: "5px" }}>
                          Payout{" "}
                          <b className={classes.colorPink}>
                            {walletsInfo.payoutAmount?.toFixed(2) || 0} {symbol}
                          </b>
                        </label>
                      </>
                    )}
                  </Box>
                </div>
              )}
              <Table
                tableHeaderColor="primary"
                tableHead={columns
                  .filter((column) => column.isActive)
                  .map((column) => column.columnName)}
                tableData={tableData.map((e) => e.map((e) => e.value))}
              />
              {searchLoading && (
                <div className={classes.spinnerContainer}>
                  <CircularProgress color="secondary" />
                </div>
              )}
              <Movepoint onEnter={getMoreWallets} onLeave={() => {}} />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}
