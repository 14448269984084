import axios from "axios";
import Cookies from "universal-cookie";
import { setupCache } from "axios-cache-adapter";

export default class AuthService {
  // Initializing important variables
  constructor(domain) {
    this.serviceUrl = domain;
    this.cacheAge = 0;
    this.contentType = "application/json";
    this.responseType = "json";
    this.encoding = "utf8";
    this.call = null;
    this.retryCount = 0;
  }

  fbLogin = (credentials, callback) => {
    axios({
      method: "get",
      url: this.serviceUrl + "fb",
      params: credentials
    })
      .then(response => {
        this.handleAuthResponse(response, callback);
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  login = (credentials, callback) => {
    axios({
      method: "get",
      url: this.serviceUrl,
      params: credentials
    })
      .then(response => {
        this.handleAuthResponse(response, callback);
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  subscribe = (newUser, callback) => {
    this.request(
      "post",
      newUser,
      response => {
        this.handleAuthResponse(response, callback);
      },
      error => {
        console.log(error);
      }
    );
  };

  handleAuthResponse = (response, callback) => {
    var userData = response.data;
    if (userData.error) {
      console.log(userData.error);
      callback(response);
    } else {
      if (userData.token) {
        localStorage.setItem("user_token", userData.token);
      }
      callback(response);
    }
  };

  clearSession() {
    localStorage.removeItem("user_token");
  }

  clearCookies() {
    const cookies = new Cookies();
    cookies.remove("user_token");
  }

  getToken() {
    return localStorage.getItem("user_token");
  }

  setToken(token) {
    return localStorage.setItem("user_token", token);
  }

  setUrl(url) {
    this.serviceUrl = url;
  }

  setCacheAge(age) {
    this.cacheAge = age;
  }

  setContentType(contentType) {
    this.contentType = contentType;
  }

  setResponseType(responseType) {
    this.responseType = responseType;
  }

  setEncoding(encoding) {
    this.encoding = encoding;
  }

  setRetryCount(count) {
    this.retryCount = count;
  }

  cancel() {
    if (this.call) {
      console.log("Canceled by user !");
      this.call.cancel("Canceled by user !");
    } else {
      console.log("cancel not possible !");
    }
  }

  request(method, data, successCallback, errorCallback) {
    var headers = {
      Accept: "application/json",
      "Content-Type": this.contentType
    };

    var token = this.getToken();

    if (token) {
      headers["Authorization"] = "Bearer " + token;

      if (this.cacheAge > 0) {
        headers["Cache-Control"] = "max-age=" + this.cacheAge;
      }

      const cache = setupCache({
        maxAge: this.cacheAge
      });

      const api = axios.create({
        adapter: cache.adapter
      });

      api.cancelToken = axios.CancelToken;
      this.call = api.cancelToken.source();

      var config = {
        method: method,
        url: this.serviceUrl,
        headers: headers,
        responseType: this.responseType,
        encoding: this.encoding,
        cancelToken: this.call.token
      };

      method === "get" ? (config["params"] = data) : (config["data"] = data);

      api(config).then(
        response => {
          this.cacheAge = 0; // Reset cache age
          successCallback(response);
        },
        error => {
          console.log("Axios error : " + error);
          if (!axios.isCancel(error) && errorCallback) {
            errorCallback(error);
          }
        }
      );
    } else {
      let msg = "No token found : request to " + this.serviceUrl + " aborted";
      console.log(msg);
      if (this.retryCount) {
        this.retryCount -= 1;
        console.log("Retry for " + this.serviceUrl);
        setTimeout(() => {
          this.request(method, data, successCallback, errorCallback);
        }, 300);
      } else if (errorCallback) {
        errorCallback(msg);
      }
    }
  }
}
