import React, { useEffect, useContext } from "react";
import { AppContext } from "../../AppContext";
import AuthService from "../../AuthService";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Box from "@material-ui/core/Box";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

// @material-ui/icons
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";

// Autocomplete
import Autosuggest from "react-autosuggest";

const styles = {
  spinnerContainer: {
    marginTop: "10px",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  formControl: {
    margin: "5px",
    width: "150px",
    zIndex: "4",
  },
  selectEmpty: {
    marginTop: "2",
  },
  searchInput: {
    width: "300px",
  },
  actionText: {
    cursor: "pointer",
    fontWeight: "bold",
    color: "#d62546",
    fontSize: "16px",
  },
  inputText: {
    width: "100px",
  },
  inputLongText: {
    width: "200px",
  },
  cardCurrency: {
    width: "200px",
    marginRight: "10px",
  },
};

const useStyles = makeStyles(styles);

const orders = ["Asc", "Desc"];

const monthNames = [
  "All",
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const years = ["All"];

let staringDate = 2020;
let date = new Date();
for (let year = staringDate; year <= date.getFullYear(); ++year) {
  years.push(year);
}

export default function Donations() {
  const context = useContext(AppContext);
  const classes = useStyles();

  const initColumns = [
    {
      columnName: "Date",
      getData: (donation) => {
        let date =
          new Date(donation.date).getDate() +
          "/" +
          (new Date(donation.date).getMonth() + 1) +
          "/" +
          new Date(donation.date).getFullYear();
        return date;
      },
      getCompareData: (donation) => donation.date,
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
    {
      columnName: "First Name",
      getData: (donation) => donation.user.firstName,
      getCompareData: (donation) => donation.user.firstName,
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
    {
      columnName: "User Name",
      getData: (donation) => donation.user.userName,
      getCompareData: (donation) => donation.user.userName,
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
    {
      columnName: "Mail",
      getData: (donation) => donation.user.mail,
      getCompareData: (donation) => donation.user.mail,
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
    {
      columnName: "Amount",
      getData: (donation) => donation.amount,
      getCompareData: (donation) => donation.amount,
      isFilterable: true,
      isActive: true,
      isMandatory: true,
    },
    {
      columnName: "Currency",
      getData: (donation) => donation.user.currency,
      getCompareData: (donation) => donation.user.currency,
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
    {
      columnName: "Percent",
      getData: (donation) => donation.percent,
      getCompareData: (donation) => donation.percent,
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
    {
      columnName: "Asso",
      getData: (donation) => donation.asso,
      getCompareData: (donation) => donation.asso,
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
  ];

  const [toggle, setToggle] = React.useState(false); // Just needed to force refresh
  const [allDonations, setAllDonations] = React.useState([]);
  const [order, setOrder] = React.useState("Asc");
  const [columnFilter, setColumnFilter] = React.useState(
    initColumns
      .filter((e) => e.isActive)
      .map((e) => e.columnName)
      .indexOf("Amount")
  );
  const [columns, setColumns] = React.useState(initColumns);
  const [search, setSearch] = React.useState("");
  const [associations, setAssociations] = React.useState([]);
  const [assoSelected, setAssoSelected] = React.useState("");
  const [month, setMonth] = React.useState(new Date().getMonth() + 1);
  const [year, setYear] = React.useState(new Date().getFullYear());
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [
    platformDonationCounters,
    setPlatformDonationCounters,
  ] = React.useState({});
  const [userDonationCounters, setUserDonationCounters] = React.useState({});

  const userDonationService = new AuthService();
  userDonationService.setUrl(
    context.apiUrl + context.associationsPath + "donations"
  );

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  const handleColumnFilterChange = (event) => {
    setColumnFilter(event.target.value);
  };

  const handleOrderChange = (event) => {
    setOrder(event.target.value);
  };

  const handleColumnStateChange = (index) => (event) => {
    let activeColumns = columns.filter((e) => e.isActive);
    let currentFilterColumnName = activeColumns[columnFilter]
      ? activeColumns[columnFilter].columnName
      : "";

    let newColumns = columns;
    newColumns[index].isActive = event.target.checked;
    setColumns(newColumns);

    let newFilterColumn = newColumns
      .filter((e) => e.isActive)
      .map((e) => e.columnName)
      .indexOf(currentFilterColumnName);
    if (newFilterColumn === -1) {
      newFilterColumn = initColumns.map((e) => e.isFilterable).indexOf(true);
    }
    setColumnFilter(newFilterColumn);
    setToggle(!toggle);
  };

  const buildDataFromDonations = () => {
    let data = [];
    allDonations.forEach((donation, index) => {
      let row = [];
      columns.forEach((column) => {
        if (column.isActive) {
          row.push({
            value: column.getData(donation, allDonations, index),
            compare: column.getCompareData(donation),
          });
        }
      });
      data.push(row);
    });

    setData(data);
  };

  const onSuggestionSelected = (event, data) => {
    if (data.suggestionValue) {
      setAssoSelected(data.suggestionValue);
      setSearch(data.suggestionValue);
    }
  };

  const shouldRenderSuggestions = (value) => {
    if (value && value.length >= 1) {
      return true;
    }
  };

  const sortData = (a, b) => {
    if (!a[columnFilter] || !b[columnFilter]) {
      return 0;
    }
    return (
      (a[columnFilter].compare - b[columnFilter].compare) *
      (order === "Asc" ? 1 : -1)
    );
  };

  useEffect(() => {
    const authService = new AuthService();

    // Get all associations
    authService.setUrl(context.apiUrl + context.associationsPath);
    authService.request("get", {}, (response) => {
      setAssociations(response.data);
    });

    buildPlatformDonationCounters();
  }, []);

  useEffect(() => {
    setLoading(true);
    setData([]);
    setUserDonationCounters({});
    getUsersDonations();
  }, [assoSelected, month, year]);

  useEffect(() => {
    buildPlatformDonationCounters();
    buildUsersDonationCounters();
    buildDataFromDonations();
  }, [allDonations]);

  const buildPlatformDonationCounters = () => {
    const authService = new AuthService();

    // Get platform donation
    authService.setUrl(
      context.apiUrl + context.associationsPath + "donations/platform"
    );
    authService.request(
      "get",
      {
        month: month + "",
        year: year + "",
      },
      (response) => {
        const { error } = response.data;
        if (!error) {
          setPlatformDonationCounters(response.data);
        } else {
          console.log(error);
        }
      }
    );
  };

  const buildUsersDonationCounters = () => {
    let userDonationCounters = {};
    allDonations.forEach((donation) => {
      const { currency } = donation.user;
      if (!userDonationCounters[currency]) {
        userDonationCounters[currency] = 0;
      }
      userDonationCounters[currency] += donation.amount;
    });

    // Apply percent to give
    Object.keys(userDonationCounters).forEach((currency) => {
      userDonationCounters[currency] =
        Math.round(userDonationCounters[currency] * 100) / 100;
    });

    setUserDonationCounters(userDonationCounters);
  };

  const getUsersDonations = () => {
    let index = associations.map((e) => e.name).indexOf(assoSelected);
    if (!assoSelected || index !== -1) {
      let assoId = index > -1 ? associations[index]._id : "";

      userDonationService.cancel();

      // Get all donations
      userDonationService.request(
        "get",
        {
          assoId,
          month: month + "",
          year: year + "",
        },
        (response) => {
          setLoading(false);
          setAllDonations(response.data);
        }
      );
    }
  };

  const inputProps = {
    placeholder: "Association",
    onChange: (evt) => {
      setSearch(evt.target.value);
      setAssoSelected("");
    },
    value: search,
  };

  // const handleDeleteDonations = () => {
  //   let index = associations.map(e => e.name).indexOf(assoSelected);
  //   if (index !== -1) {
  //     console.log("Delete " + JSON.stringify(associations[index], null, 4));

  //     const authService = new AuthService(
  //       context.apiUrl + context.associationsPath + "donations"
  //     );

  //     let deleteData = {
  //       assocationId: associations[index]._id,
  //       month: month + "",
  //       year: year + ""
  //     };

  //     authService.request("delete", deleteData, response => {
  //       if (response.data.result === "success") {
  //         getUsersDonations();
  //       }
  //     });
  //   }
  // };

  return (
    <>
      <FormControl className={classes.formControl}>
        <InputLabel>Month</InputLabel>
        <Select onChange={handleMonthChange} value={month}>
          {monthNames.map((m, i) => (
            <MenuItem key={m} value={i || m}>
              {m}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel>Year</InputLabel>
        <Select onChange={handleYearChange} value={year}>
          {years.map((y) => (
            <MenuItem value={y}>{y}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel>Column</InputLabel>
        <Select onChange={handleColumnFilterChange} value={columnFilter}>
          {columns
            .filter((column) => column.isFilterable && column.isActive)
            .map((column) => {
              var index = columns
                .filter((column) => column.isActive)
                .map((field) => field.columnName)
                .indexOf(column.columnName);
              return <MenuItem value={index}>{column.columnName}</MenuItem>;
            })}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel>Order</InputLabel>
        <Select onChange={handleOrderChange} value={order}>
          {orders.map((order) => (
            <MenuItem value={order}>{order}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <Autosuggest
          suggestions={associations
            .map((e) => e.name)
            .filter((e) => e?.toLowerCase().indexOf(search) !== -1)}
          onSuggestionsFetchRequested={() => {}}
          onSuggestionsClearRequested={() => {}}
          onSuggestionSelected={onSuggestionSelected}
          getSuggestionValue={(suggestion) => {
            console.log("suggestion : " + suggestion);
            return suggestion;
          }}
          shouldRenderSuggestions={shouldRenderSuggestions}
          renderSuggestion={(elmt) => <span>{elmt}</span>}
          inputProps={inputProps}
        />
      </FormControl>

      <br />

      {columns.map((column, index) => (
        <FormControlLabel
          control={
            <Checkbox
              disabled={column.isMandatory}
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={<CheckCircleIcon />}
              checked={column.isActive}
              onChange={handleColumnStateChange(index)}
              color="secondary"
            />
          }
          label={column.columnName}
        />
      ))}

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Uncove</h4>
              <p className={classes.cardCategoryWhite}>
                Here are the uncove donations
              </p>
            </CardHeader>
            <CardBody>
              <Box display="flex" flexDirection="row">
                {Object.keys(platformDonationCounters).map((currency) => (
                  <Card className={classes.cardCurrency}>
                    <CardContent>
                      <Typography variant="h5" component="h2" color="secondary">
                        {currency.toUpperCase()}
                      </Typography>
                      <Typography className={classes.pos} color="textSecondary">
                        {platformDonationCounters[currency]}
                      </Typography>
                    </CardContent>
                  </Card>
                ))}
              </Box>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Users</h4>
              <p className={classes.cardCategoryWhite}>
                Here are all user donations
              </p>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={columns
                  .filter((column) => column.isActive)
                  .map((column) => column.columnName)}
                tableData={data
                  .sort(sortData)
                  .map((e) => e.map((e) => e.value))}
              />

              {loading && (
                <div className={classes.spinnerContainer}>
                  <CircularProgress color="secondary" />
                </div>
              )}

              <Box display="flex" flexDirection="row">
                {Object.keys(userDonationCounters).map((currency) => (
                  <Card className={classes.cardCurrency}>
                    <CardContent>
                      <Typography variant="h5" component="h2" color="secondary">
                        {currency.toUpperCase()}
                      </Typography>
                      <Typography className={classes.pos} color="textSecondary">
                        {userDonationCounters[currency]}
                      </Typography>
                    </CardContent>
                  </Card>
                ))}
              </Box>
            </CardBody>
          </Card>
          {/* {assoSelected !== "" && (
            <p className={classes.actionText} onClick={handleDeleteDonations}>
              Delete donations for "{assoSelected}"
            </p>
          )} */}
        </GridItem>
      </GridContainer>
    </>
  );
}
