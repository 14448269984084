import React, { useEffect, useContext, useState } from "react";
import Autosuggest from "react-autosuggest";
import FormControl from "@material-ui/core/FormControl";
import Button from "components/CustomButtons/Button.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { pink } from "@material-ui/core/colors";

import { AppContext } from "../../AppContext";
import AuthService from "../../AuthService";

const styles = {
  formControlLabelFeature: {
    margin: "5px",
    width: "auto",
    height: "56px",
  },
  formControlLabelCategories: {
    margin: "5px",
    width: "120px",
    height: "56px",
  },
  infoButton: {
    background: "skyblue",
    color: "#fff",
    marginTop: "10px",
    marginRight: "10px",
    height: "30px",
  },
  dangerButton: {
    color: "#fff",
    marginTop: "10px",
    marginRight: "10px",
    background: pink[500],
    height: "30px",
  },
};

export default function Access() {
  const context = useContext(AppContext);
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const [modelSuggestions, setModelSuggestions] = useState([]);
  const [contentCategories, setContentCategories] = useState([]);
  const [
    contentCategoriesAuthorized,
    setContentCategoriesAuthorized,
  ] = useState(null);
  const [username, setUserName] = useState("");
  const [userSelected, setUserSelected] = useState("");
  const [toggle, setToggle] = useState(false);

  const authService = new AuthService();

  useEffect(() => {
    authService.setUrl(context.apiUrl + context.categoriesPath + "content");
    authService.request("get", {}, (response) => {
      if (response.data) {
        setContentCategories(response.data);
      }
    });
  }, []);

  useEffect(() => {
    getContentCategoriesAuthorized();
  }, [userSelected]);

  const onSuggestionSelected = (event, data) => {
    setUserName(data.suggestionValue);

    authService.setUrl(context.apiUrl + context.usersPath);
    authService.request(
      "get",
      {
        key: "userName",
        value: data.suggestionValue,
        categories: contentCategories.map((e) => e._id),
      },
      (response) => {
        setUserSelected(response.data);
      }
    );
  };

  const getContentCategoriesAuthorized = () => {
    authService.setUrl(
      context.apiUrl + context.settingsPath + "contentCategories"
    );
    authService.request(
      "get",
      {
        key: "userName",
        modelId: userSelected._id,
      },
      (response) => {
        setContentCategoriesAuthorized(response.data);
      }
    );
  };

  const handleUserNameChange = (event) => {
    var username = event.target.value;
    if (username != undefined) {
      setUserName(username);

      authService.setUrl(context.apiUrl + context.usersPath + "suggestion");
      authService.request(
        "get",
        {
          name: username,
          show: "All",
          age: "[18,60]",
          distance: 100,
          country: "All",
          sex: "All",
          userType: "All",
          radarVisibilityOnly: false,
        },
        (response) => {
          const { data } = response;
          setModelSuggestions(data);
        }
      );
    }
  };

  const handleFeaturesChange = (key) => {
    userSelected.features[key] = !userSelected.features[key];
    setUserSelected(userSelected);
    setToggle(!toggle);

    authService.setUrl(context.apiUrl + context.settingsPath + "features");
    authService.request(
      "put",
      {
        userId: userSelected._id,
        features: key,
        value: userSelected.features[key],
      },
      () => {}
    );
  };

  const handleContentCategoriesAuthorizationChange = (key) => {
    contentCategoriesAuthorized[key] = !contentCategoriesAuthorized[key];
    setContentCategoriesAuthorized(contentCategoriesAuthorized);
    setToggle(!toggle);

    authService.setUrl(
      context.apiUrl + context.settingsPath + "contentCategories"
    );
    authService.request(
      "put",
      {
        userId: userSelected._id,
        contentCategoriesKeyname: [key],
        value: contentCategoriesAuthorized[key],
      },
      () => {}
    );
  };

  const updateAllContentCategories = (value) => {
    Object.keys(contentCategoriesAuthorized).forEach((key) => {
      contentCategoriesAuthorized[key] = value;
    });

    setContentCategoriesAuthorized(contentCategoriesAuthorized);
    setToggle(!toggle);

    authService.setUrl(
      context.apiUrl + context.settingsPath + "contentCategories"
    );
    authService.request(
      "put",
      {
        userId: userSelected._id,
        contentCategoriesKeyname: Object.keys(contentCategoriesAuthorized),
        value,
      },
      () => {}
    );
  };

  const inputProps = {
    placeholder: "User name",
    onChange: handleUserNameChange,
    value: username,
  };

  return (
    <>
      <FormControl className={classes.formControl}>
        <Autosuggest
          suggestions={modelSuggestions.filter(
            (e) => e?.toLowerCase().indexOf(username?.toLowerCase()) !== -1
          )}
          onSuggestionsFetchRequested={() => {}}
          onSuggestionsClearRequested={() => {}}
          onSuggestionSelected={onSuggestionSelected}
          getSuggestionValue={(suggestion) => suggestion}
          shouldRenderSuggestions={() => true}
          renderSuggestion={(elmt) => <span>{elmt}</span>}
          inputProps={inputProps}
        />
      </FormControl>

      <h3>Features</h3>
      <Box display="flex" flexDirection="row" flexWrap="wrap">
        {userSelected &&
          Object.keys(userSelected.features).map((key) => (
            <>
              <FormControlLabel
                key={key}
                className={classes.formControlLabelFeature}
                control={
                  <Checkbox
                    onClick={() => {
                      handleFeaturesChange(key);
                    }}
                    checked={userSelected.features[key]}
                  />
                }
                label={key}
              />
            </>
          ))}
      </Box>

      <h3>Content categories</h3>
      {contentCategoriesAuthorized &&
        Object.keys(contentCategoriesAuthorized).length > 0 && (
          <>
            <Box display="flex" flexDirection="row" flexWrap="wrap">
              {Object.keys(contentCategoriesAuthorized).map((key) => (
                <FormControlLabel
                  key={key}
                  className={classes.formControlLabelCategories}
                  control={
                    <Checkbox
                      onClick={() => {
                        handleContentCategoriesAuthorizationChange(key);
                      }}
                      checked={contentCategoriesAuthorized[key]}
                    />
                  }
                  label={key}
                />
              ))}
            </Box>
            <Box display="flex" flexDirection="row">
              {Object.values(contentCategoriesAuthorized).some((e) => !e) && (
                <Button
                  className={classes.infoButton}
                  onClick={() => {
                    updateAllContentCategories(true);
                  }}
                  variant="contained"
                >
                  Select all
                </Button>
              )}

              {Object.values(contentCategoriesAuthorized).some((e) => e) && (
                <Button
                  className={classes.dangerButton}
                  onClick={() => {
                    updateAllContentCategories(false);
                  }}
                  variant="contained"
                >
                  Clear all
                </Button>
              )}
            </Box>
          </>
        )}
    </>
  );
}
