import React, { useEffect, useContext, useState } from "react";

import { AppContext } from "../../AppContext";
import AuthService from "../../AuthService";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import SwipeableViews from "react-swipeable-views";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Movepoint from "components/Movepoint/Movepoint.js";

import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import Booking from "./Booking";

const styles = {
  root: {
    backgroundColor: "#aaa",
    width: 500,
  },
  spinnerContainer: {
    marginTop: "10px",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box paddingTop={3}>{children}</Box>}
    </div>
  );
}

export default function Bookings() {
  const classes = useStyles();
  const context = useContext(AppContext);

  const authService = new AuthService();
  const nbBookingsToFetch = 10;

  const [startIndex, setStartIndex] = useState(0);
  const [bookings, setBookings] = useState({
    bookingFinished: [],
    bookingPending: [],
    bookingRefused: [],
    upcomingBooking: [],
  });

  const [searchLoading, setSearchLoading] = useState(true);
  const [value, setValue] = useState(0);

  useEffect(() => {
    loadBookings(startIndex, nbBookingsToFetch);
  }, []);

  const loadBookings = (start, end) => {
    setSearchLoading(true);
    authService.setUrl(context.apiUrl + context.bookingPath);
    authService.request("get", { start, end }, (response) => {
      let {
        bookingFinished,
        bookingPending,
        bookingRefused,
        upcomingBooking,
      } = response.data;

      setBookings({
        bookingFinished: [...bookings.bookingFinished, ...bookingFinished],
        bookingPending: [...bookings.bookingPending, ...bookingPending],
        bookingRefused: [...bookings.bookingRefused, ...bookingRefused],
        upcomingBooking: [...bookings.upcomingBooking, ...upcomingBooking],
      });

      setSearchLoading(false);
      setStartIndex(startIndex + nbBookingsToFetch);
    });
  };

  const showMore = () => {
    let end = startIndex + nbBookingsToFetch;
    loadBookings(startIndex, end);
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleTabChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Appointments</h4>
            <p className={classes.cardCategoryWhite}>
              Here are all the appointments
            </p>
          </CardHeader>
          <CardBody>
            <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label="Wainting" />
                <Tab label="Forthcoming" />
                <Tab label="Past" />
                <Tab label="Refused" />
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={"x"}
              index={value}
              onChangeIndex={handleTabChangeIndex}
            >
              <TabPanel value={value} index={0} dir={""}>
                <Booking
                  showMore={showMore}
                  bookingsArray={
                    bookings.bookingPending ? bookings.bookingPending : []
                  }
                />
              </TabPanel>
              <TabPanel value={value} index={1} dir={""}>
                <Booking
                  showMore={showMore}
                  bookingsArray={
                    bookings.upcomingBooking ? bookings.upcomingBooking : []
                  }
                />
              </TabPanel>
              <TabPanel value={value} index={2} dir={""}>
                <Booking
                  showMore={showMore}
                  bookingsArray={
                    bookings.bookingFinished ? bookings.bookingFinished : []
                  }
                />
              </TabPanel>
              <TabPanel value={value} index={3} dir={""}>
                <Booking
                  showMore={showMore}
                  bookingsArray={
                    bookings.bookingRefused ? bookings.bookingRefused : []
                  }
                />
              </TabPanel>
            </SwipeableViews>
            <Movepoint onEnter={showMore} />
            {searchLoading && (
              <div className={classes.spinnerContainer}>
                <CircularProgress color="secondary" />
              </div>
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
