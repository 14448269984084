import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

const styles = {
  bookingContainer: {
    width: "100%",
    overflow: "scroll",
    "& ul": {
      width: "max-content",
      display: "flex",
      flex: "1",
      listStyleType: "none",
      listStyle: "none",
      margin: "0 auto",
      padding: "0",
      padding: "20px 0",
      borderBottom: "1px solid lightgrey",
      " & li": {
        width: "140px",
        overflow: "scroll",
        margin: "0 8px",
        display: "flex",
        flexDirection: "column",
        whiteSpace: "nowrap",
        "& strong": {
          color: "#ab47bc",
          fontWeight: "normal",
        },
      },
    },
  },
};

const useStyles = makeStyles(styles);

export default function Booking(props) {
  const classes = useStyles();

  const { bookingsArray } = props;

  return (
    <>
      {bookingsArray.length > 0 && (
        <div className={classes.bookingContainer}>
          {bookingsArray.map((bookings, index) => (
            <div key={index}>
              {bookings.map(({ booking }, index) => (
                <ul key={index}>
                  <li>
                    <strong>Start Date</strong>
                    {booking.from
                      ? new Date(booking.from).toLocaleString()
                      : "null"}
                  </li>
                  <li>
                    <strong>End Date</strong>
                    {booking.to
                      ? new Date(booking.to).toLocaleString()
                      : "null"}
                  </li>
                  <li>
                    <strong>Username Fan</strong>
                    {booking.fanUserName ? booking.fanUserName : "null"}
                  </li>
                  <li>
                    <strong>Username Model</strong>
                    {booking.modelUserName ? booking.modelUserName : "null"}
                  </li>
                  <li>
                    <strong>Fan request</strong>
                    {booking.description ? booking.description : "null"}
                  </li>
                  <li>
                    <strong>Model response</strong>
                    {booking.response ? booking.response : "null"}
                  </li>
                  <li>
                    <strong>Amount</strong>
                    {booking.amount ? booking.amount : "null"}
                  </li>
                  <li>
                    <strong>Transaction status</strong>
                    {booking.transactionCaptured ? "captured" : "not captured"}
                  </li>
                  <li>
                    <strong>time Model In Live</strong>
                    {booking.timeModelInLiveStream
                      ? `${booking.timeModelInLiveStream}s`
                      : "null"}
                  </li>
                </ul>
              ))}
            </div>
          ))}
        </div>
      )}
    </>
  );
}
