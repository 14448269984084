import React, { useEffect, useContext } from "react";
import Movepoint from "components/Movepoint/Movepoint.js";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Thumb from "components/Thumb/Thumb.js";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";

// @material-ui/icons
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";

import { AppContext } from "../../AppContext";
import AuthService from "../../AuthService";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  formControl: {
    margin: "5px",
    width: "120px"
  },
  formControlLabel: {
    margin: "5px",
    width: "140px",
    height: "56px"
  },
  selectEmpty: {
    marginTop: "2"
  },
  searchInput: {
    width: "300px"
  },
  preview: {
    objectFit: "cover",
    width: "50px",
    height: "50px"
  },
  spinnerContainer: {
    marginTop: "10px",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  actionText: {
    cursor: "pointer",
    fontWeight: "bold",
    color: "#d62546",
    fontSize: "16px"
  },
  countersContainerDanger: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: "5px",
    borderRadius: "5px",
    color: "#FFF",
    background: "#d62546",
    fontSize: "16px",
    marginBottom: "20px",
    padding: "10px"
  },
  countersContainerSuccess: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: "5px",
    borderRadius: "5px",
    color: "#FFF",
    background: "#32CD32",
    fontSize: "16px",
    marginBottom: "20px",
    padding: "10px"
  },
  fixButton: {
    background: "#FFF",
    color: "#2D2D2D",
    marginRight: "10px",
    height: "35px"
  }
};

const useStyles = makeStyles(styles);

export default function Photos() {
  const context = useContext(AppContext);
  const classes = useStyles();
  const nbPhotosToFetch = 10;

  const authServiceSingleton = React.useRef(new AuthService());
  const authServiceSingleton2 = React.useRef(new AuthService());

  let initColumns = [
    {
      columnName: "First Name",
      getData: data => (data.user ? data.user.firstName : ""),
      getCompareData: () => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: true
    },
    {
      columnName: "Last Name",
      getData: data => (data.user ? data.user.lastName : ""),
      getCompareData: () => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: true
    },
    {
      columnName: "User Name",
      getData: data => (data.user ? data.user.userName : ""),
      getCompareData: () => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: false
    },
    {
      columnName: "Tags",
      getData: data => (data.tags ? "#" + data.tags.join(" #") : ""),
      getCompareData: () => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: true
    },
    {
      columnName: "Type",
      getData: data => data.type,
      getCompareData: () => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: false
    },
    {
      columnName: "Private",
      getData: data => (data.blur ? "True" : "False"),
      getCompareData: () => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: false
    },
    {
      columnName: "Date",
      getData: data => new Date(data.date).toLocaleString(),
      getCompareData: data => new Date(data.date).getTime(),
      isFilterable: true,
      isActive: true,
      isMandatory: false
    },
    {
      columnName: "Thumb",
      getData: data => (
        <Thumb
          height="50px"
          width="50px"
          key={data.small ? data.small : data.normal}
          src={data.small ? data.small : data.normal}
        />
      ),
      getCompareData: data => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: false
    },
    {
      columnName: "Key",
      getData: data => (
        <p>{data._key}</p>
      ),
      getCompareData: data => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: false
    },
    {
      columnName: "Preview",
      getData: data => (
        <p
          className={classes.actionText}
          onClick={() => window.open(data.normal)}
        >
          Show
        </p>
      ),
      getCompareData: data => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: false
    },
    {
      columnName: "Delete",
      getData: data => (
        <p className={classes.actionText} onClick={() => deleteMedia(data._id)}>
          Delete
        </p>
      ),
      getCompareData: data => 0,
      isFilterable: false,
      isActive: false,
      isMandatory: false
    }
  ];

  const [toggle, setToggle] = React.useState(false); // Just needed to force refresh
  const [photos, setPhotos] = React.useState([]);
  const [order, setOrder] = React.useState("Desc");
  const [columnFilter, setColumnFilter] = React.useState(
    initColumns.map(e => e.isFilterable).indexOf(true)
  );
  const [columns, setColumns] = React.useState(initColumns);
  const [search, setSearch] = React.useState("");
  const [tag, setTag] = React.useState("");
  const [photoType, setPhotoType] = React.useState("All");
  const [showPost, setShowPost] = React.useState(true);
  const [showThumb, setShowThumb] = React.useState(true);
  const [showProfil, setShowProfil] = React.useState(true);
  const [showIdentity, setShowIdentity] = React.useState(true);
  const [showChat, setShowChat] = React.useState(true);
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(nbPhotosToFetch);
  const [searchLoading, setSearchLoading] = React.useState(true);
  const [formatMissingLoaded, setFormatMissingLoaded] = React.useState(false);
  const [formatNormalMissing, setFormatNormalMissing] = React.useState(0);
  const [formatMediumMissing, setFormatMediumMissing] = React.useState(0);
  const [formatSmalllMissing, setFormatSmalllMissing] = React.useState(0);

  const photoTypes = ["All", "Public", "Private"];
  const orders = ["Asc", "Desc"];

  useEffect(() => {
    getFailedPhotos();
    searchPhotos();
  }, [photoType, search, tag, showPost, showThumb, showProfil, showIdentity, showChat]);

  const searchPhotos = () => {

    setSearchLoading(true);

    // Get all photos
    authServiceSingleton.current.setUrl(context.apiUrl + context.photosPath + "all");
    authServiceSingleton.current.request(
      "get",
      {
        start,
        end,
        photoType: photoType.toLowerCase(),
        username: search,
        tag,
        showPost,
        showThumb,
        showProfil,
        showIdentity,
        showChat
      },
      response => {
        const { data } = response;
        let newStart = photos.length + data.length;
        setSearchLoading(false);
        setPhotos(start === 0 ? data : photos.concat(data));
        setStart(newStart);
        setEnd(newStart + nbPhotosToFetch);
      }
    );
  };

  const getFailedPhotos = () => {
    authServiceSingleton2.current.cancel();
    authServiceSingleton2.current.setUrl(context.apiUrl + context.photosPath + "fail");
    authServiceSingleton2.current.request("get", {}, response => {
      if (response.data) {
        const { formatMissing } = response.data;
        console.log("response", response.data);
        setFormatNormalMissing(formatMissing.normal.length);
        setFormatMediumMissing(formatMissing.medium.length);
        setFormatSmalllMissing(formatMissing.small.length);
        setFormatMissingLoaded(true);
      }
    });
  }

  const fix = () => {
    authServiceSingleton.current.setUrl(context.apiUrl + context.photosPath + "fail");
    authServiceSingleton.current.request("put", {}, response => {
      if (response.data.status === "success") {
        getFailedPhotos();
      }
    });
  }

  const deleteMedia = photoId => {
    const authService = new AuthService();

    // Delete a photo
    authServiceSingleton.current.setUrl(context.apiUrl + context.photosPath);
    authServiceSingleton.current.request("delete", { photoId }, response => {
      const { data } = response;
      if (data.status === "success") {
        alert("Deleted !");
      }
    });
  };

  const handleColumnFilterChange = event => {
    setColumnFilter(event.target.value);
  };

  const handleOrderChange = event => {
    setOrder(event.target.value);
  };

  const handleSearchChange = event => {
    authServiceSingleton.current.cancel();
    setPhotos([]);
    setStart(0);
    setEnd(nbPhotosToFetch);
    setSearch(event.target.value);
  };

  const handleTagChange = event => {
    setStart(0);
    setEnd(nbPhotosToFetch);
    setTag(event.target.value);
  };

  const handlePhotoTypeChange = event => {
    setStart(0);
    setEnd(nbPhotosToFetch);
    setPhotoType(event.target.value);
  };

  const handleColumnStateChange = index => event => {
    let activeColumns = columns.filter(e => e.isActive);
    let currentFilterColumnName = activeColumns[columnFilter]
      ? activeColumns[columnFilter].columnName
      : "";

    let newColumns = columns;
    newColumns[index].isActive = event.target.checked;
    setColumns(newColumns);

    let newFilterColumn = newColumns
      .filter(e => e.isActive)
      .map(e => e.columnName)
      .indexOf(currentFilterColumnName);
    if (newFilterColumn === -1) {
      newFilterColumn = initColumns.map(e => e.isFilterable).indexOf(true);
    }
    setColumnFilter(newFilterColumn);
    setToggle(!toggle);
  };

  const makeDataFromPhotos = () => {
    let data = [];

    photos.forEach(photo => {
      var row = [];
      columns.forEach((column, index) => {
        if (column.isActive) {
          row.push({
            value: column.getData(photo),
            compare: column.getCompareData(photo)
          });
        }
      });
      data.push(row);
    });
    return data;
  };

  const sortData = (a, b) => {
    if (!a[columnFilter] || !b[columnFilter]) {
      return 0;
    }
    return (
      (a[columnFilter].compare - b[columnFilter].compare) *
      (order === "Asc" ? 1 : -1)
    );
  };

  let data = makeDataFromPhotos();

  const showMore = () => {
    searchPhotos();
  };

  return (
    <>
      {
        formatMissingLoaded &&
        <div className={(
          formatNormalMissing > 0 ||
          formatMediumMissing > 0 || 
          formatSmalllMissing > 0
          ) ? classes.countersContainerDanger : classes.countersContainerSuccess
        }>
          <b>Normal : {formatNormalMissing}</b>
          <b>Medium : {formatMediumMissing}</b>
          <b>Small : {formatSmalllMissing}</b>
  
          <Button
            className={classes.fixButton}
            onClick={() => fix()}
            variant="contained"
          >
            Fix
          </Button>
        </div>
      }

      <FormControl className={classes.formControl}>
        <InputLabel>Photo type</InputLabel>
        <Select onChange={handlePhotoTypeChange} value={photoType}>
          {photoTypes.map(photoType => (
            <MenuItem key={photoType} value={photoType}>
              {photoType}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel>Column</InputLabel>
        <Select onChange={handleColumnFilterChange} value={columnFilter}>
          {columns
            .filter(column => column.isFilterable && column.isActive)
            .map(column => {
              var index = columns
                .filter(column => column.isActive)
                .map(field => field.columnName)
                .indexOf(column.columnName);
              return (
                <MenuItem key={index} value={index}>
                  {column.columnName}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel>Order</InputLabel>
        <Select onChange={handleOrderChange} value={order}>
          {orders.map(order => (
            <MenuItem key={order} value={order}>
              {order}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <TextField
          label="User name"
          type="search"
          variant="outlined"
          onChange={handleSearchChange}
        />
      </FormControl>

      <FormControl className={classes.formControl}>
        <TextField
          label="Tag"
          type="tag"
          variant="outlined"
          onChange={handleTagChange}
        />
      </FormControl>

      <FormControlLabel
        className={classes.formControlLabel}
        control={
          <Checkbox
            icon={<RadioButtonUncheckedIcon />}
            checkedIcon={<CheckCircleIcon />}
            checked={showPost}
            onChange={() => {
              authServiceSingleton.current.cancel();
              setPhotos([]);
              setStart(0);
              setEnd(nbPhotosToFetch);
              setShowPost(!showPost);
            }}
            color="secondary"
          />
        }
        label="Show Posts"
      />

      <FormControlLabel
        className={classes.formControlLabel}
        control={
          <Checkbox
            icon={<RadioButtonUncheckedIcon />}
            checkedIcon={<CheckCircleIcon />}
            checked={showProfil}
            onChange={() => {
              authServiceSingleton.current.cancel();
              setPhotos([]);
              setStart(0);
              setEnd(nbPhotosToFetch);
              setShowProfil(!showProfil);
            }}
            color="secondary"
          />
        }
        label="Show Profil"
      />

      <FormControlLabel
        className={classes.formControlLabel}
        control={
          <Checkbox
            icon={<RadioButtonUncheckedIcon />}
            checkedIcon={<CheckCircleIcon />}
            checked={showThumb}
            onChange={() => {
              authServiceSingleton.current.cancel();
              setPhotos([]);
              setStart(0);
              setEnd(nbPhotosToFetch);
              setShowThumb(!showThumb);
            }}
            color="secondary"
          />
        }
        label="Show Thumb"
      />

      <FormControlLabel
        className={classes.formControlLabel}
        control={
          <Checkbox
            icon={<RadioButtonUncheckedIcon />}
            checkedIcon={<CheckCircleIcon />}
            checked={showIdentity}
            onChange={() => {
              authServiceSingleton.current.cancel();
              setPhotos([]);
              setStart(0);
              setEnd(nbPhotosToFetch);
              setShowIdentity(!showIdentity);
            }}
            color="secondary"
          />
        }
        label="Show Identity"
      />

      <FormControlLabel
        className={classes.formControlLabel}
        control={
          <Checkbox
            icon={<RadioButtonUncheckedIcon />}
            checkedIcon={<CheckCircleIcon />}
            checked={showChat}
            onChange={() => {
              authServiceSingleton.current.cancel();
              setPhotos([]);
              setStart(0);
              setEnd(nbPhotosToFetch);
              setShowChat(!showChat);
            }}
            color="secondary"
          />
        }
        label="Show Chat"
      />

      <br />

      {columns.map((column, index) => (
        <FormControlLabel
          key={column.columnName}
          control={
            <Checkbox
              disabled={column.isMandatory}
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={<CheckCircleIcon />}
              checked={column.isActive}
              onChange={handleColumnStateChange(index)}
              color="secondary"
            />
          }
          label={column.columnName}
        />
      ))}

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Photos</h4>
              <p className={classes.cardCategoryWhite}>
                Here are all the photos
              </p>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={columns
                  .filter(column => column.isActive)
                  .map(column => column.columnName)}
                tableData={data.sort(sortData).map(e => e.map(e => e.value))}
              />
            </CardBody>
            {searchLoading && (
              <div className={classes.spinnerContainer}>
                <CircularProgress color="secondary" />
              </div>
            )}
            <Movepoint onEnter={showMore} />
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}
