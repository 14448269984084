import React, { useEffect, useContext } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import ListSubheader from "@material-ui/core/ListSubheader";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CloudImage from "components/CloudImage/CloudImage";
import SmartVideo from "components/SmartVideo/SmartVideo";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";

// @material-ui/icons
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";

import { AppContext } from "../../AppContext";
import AuthService from "../../AuthService";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  formControl: {
    margin: "5px",
    width: "120px"
  },
  selectEmpty: {
    marginTop: "2"
  },
  searchInput: {
    width: "300px"
  },
  textButton: {
    color: "#9c35b3",
    cursor: "pointer",
    fontSize: "20px"
  }
};

export default function ContentCategories() {
  const context = useContext(AppContext);
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  let initColumns = [
    {
      columnName: "Keyname",
      getData: data => data.keyname,
      getCompareData: data => data.keyname,
      isFilterable: false,
      isActive: true,
      isMandatory: true
    },{
      columnName: "Posts",
      getData: data => data.posts,
      getCompareData: data => data.posts,
      isFilterable: false,
      isActive: true,
      isMandatory: true
    },{
      columnName: "Videos",
      getData: data => data.videosCount,
      getCompareData: data => data.videoCounts,
      isFilterable: false,
      isActive: true,
      isMandatory: false
    },{
      columnName: "Photos",
      getData: data => data.photosCount,
      getCompareData: data => data.photosCount,
      isFilterable: false,
      isActive: true,
      isMandatory: false
    },{
      columnName: "Tags Count",
      getData: data => data.tagsCount,
      getCompareData: data => data.tagsCount,
      isFilterable: false,
      isActive: true,
      isMandatory: false
    },{
      columnName: "Tags most used",
      getData: data => data.tagList.join(" - "),
      getCompareData: () => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: false
    }
  ];

  const [toggle, setToggle] = React.useState(false); // Just needed to force refresh
  const [categories, setCategories] = React.useState([]);
  const [columnFilter, setColumnFilter] = React.useState(
    initColumns.map(e => e.isFilterable).indexOf(true)
  );
  const [columns, setColumns] = React.useState(initColumns);

  const handleColumnStateChange = index => event => {
    let activeColumns = columns.filter(e => e.isActive);
    let currentFilterColumnName = activeColumns[columnFilter]
      ? activeColumns[columnFilter].columnName
      : "";

    let newColumns = columns;
    newColumns[index].isActive = event.target.checked;
    setColumns(newColumns);

    let newFilterColumn = newColumns
      .filter(e => e.isActive)
      .map(e => e.columnName)
      .indexOf(currentFilterColumnName);
    if (newFilterColumn === -1) {
      newFilterColumn = initColumns.map(e => e.isFilterable).indexOf(true);
    }
    setColumnFilter(newFilterColumn);
    setToggle(!toggle);
  };

  const makeData = () => {
    var data = [];

    categories.forEach(category => {
      var row = [];

      let tags = {
        
      }
      
      category.tags.forEach(key => {
        if (!tags[key]) {
          tags[key] = 0;
        }

        tags[key] += 1;
      });

      let tagList = [];
      Object.keys(tags).forEach(key => {
        tagList.push({
          tag: `${key} (${tags[key]})`,
          count: tags[key]
        });
      });

      tagList.sort((a, b) => (a.count > b.count) ? 1 : -1);
      tagList = tagList.reverse().slice(0, 5);

      category.tagList = tagList.map(e => e.tag);

      columns.forEach(column => {
        if (column.isActive) {
          row.push({
            value: column.getData(category),
            compare: column.getCompareData(category)
          });
        }
      });
      data.push(row);
    });
    return data;
  };

  // const sortData = (a, b) => {
  //   if (!a[columnFilter] || !b[columnFilter]) {
  //     return 0;
  //   }
  //   return (
  //     (a[columnFilter].compare - b[columnFilter].compare) *
  //     (order === "Asc" ? 1 : -1)
  //   );
  // };

  useEffect(() => {
    const authService = new AuthService();

    // Get all tags
    authService.setUrl(context.apiUrl + context.categoriesPath + "content/counters");
    authService.request("get", {}, response => {
      console.log("categories : " + JSON.stringify(response.data, null, 4));
      setCategories(response.data);
    });
  }, []);

  let data = makeData();

  return (
    <>
      {columns.map((column, index) => (
        <FormControlLabel
          control={
            <Checkbox
              disabled={column.isMandatory}
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={<CheckCircleIcon />}
              checked={column.isActive}
              onChange={handleColumnStateChange(index)}
              color="secondary"
            />
          }
          label={column.columnName}
        />
      ))}

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Content Categories</h4>
              <p className={classes.cardCategoryWhite}>
                Here are all the content categories
              </p>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={columns
                  .filter(column => column.isActive)
                  .map(column => column.columnName)}
                tableData={data
                  // .sort(sortData)
                  .map(e => e.map(e => e.value))}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}
