import React from "react";
import { Waypoint } from "react-waypoint";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const styles = {
  movepointContainer: {
    width: "100%",
    height: "auto",
    display: "flex",
    justifyContent: "center"
  },
  acceptButton: {
    marginTop: "20px",
    background: "#87ceeb",
    color: "white",
    width: "100px"
  }
};

const useStyles = makeStyles(styles);

export default function Movepoint(props) {
  const classes = useStyles();
  const { onEnter, onLeave, scrollableAncestor, hideBtn, btnText } = props;

  return (
    <div className={classes.movepointContainer}>
      {!hideBtn && (
        <Button
          className={classes.acceptButton}
          onClick={onEnter}
          variant="contained"
        >
          {btnText || "See more"}
        </Button>
      )}

      <Waypoint
        onEnter={onEnter ? onEnter : () => {}}
        onLeave={onLeave ? onLeave : () => {}}
        scrollableAncestor={scrollableAncestor}
      />
    </div>
  );
}
