import React, { useContext, useEffect, useState } from "react";
import { Map, TileLayer, Popup, Marker } from "react-leaflet";
import MarkerClusterGroup from "../../components/MarkerClusterGroup/MarkerClusterGroup";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import CloudImage from "components/CloudImage/CloudImage";

import AuthService from "../../AuthService";
import { AppContext } from "../../AppContext";

import "./UserMap.css";

const styles = {
  formControl: {
    margin: "5px",
    width: "120px"
  },
  searchInput: {
    width: "300px"
  }
};

const useStyles = makeStyles(styles);

export default function UserMap() {
  const classes = useStyles();

  const [geolocations, setGeolocations] = useState([]);
  const [userType, setUserType] = React.useState("All");
  const [filterType, setFilterType] = React.useState("All");
  const [threshold, setThreshold] = React.useState(1);

  const MAP_CENTER_COORDINATES = [51.0, 19.0];
  const MAP_ZOOM = 4;
  const MAP_MAX_ZOOM = 18;
  const usersType = ["All", "Fans", "Models"];
  const filtersType = [
    "All",
    "Follower",
    "Followed",
    "Subscriber",
    "Subscribed"
  ];
  const thresholds = [1, 10, 50, 100, 1000, 10000];

  const context = useContext(AppContext);

  useEffect(() => {
    const authService = new AuthService();

    authService.setUrl(context.apiUrl + context.geolocationPath + "all");
    authService.request("get", {}, response => {
      console.log("response : " + JSON.stringify(response.data, null, 4));
      let geolocations = response.data;
      setGeolocations(geolocations);
    });
  }, []);

  const makeMarkers = () => {
    let markers = geolocations && geolocations.length ? geolocations
      .filter(
        geo =>
          geo.user &&
          (userType === "All" ||
            (userType === "Fans" && !geo.user.isModel) ||
            (userType === "Models" && geo.user.isModel))
      )
      .filter(
        geo =>
          filterType === "All" || geo[filterType.toLowerCase()] >= threshold
      )
      .filter(
        geo => geo.ll[1] && geo.ll[0]
      )
      .map(geo => (
        <Marker position={[geo.ll[0], geo.ll[1]]}>
          <Popup>
            <div className="map-popup">
              <label className="map-popup-title">{geo.user.userName}</label>
              <br />
              <label>
                {geo.user.firstName} {geo.user.lastName}
              </label>
              <br />
              <CloudImage
                className="map-avatar"
                src={geo.user.pictureUrl ? geo.user.pictureUrl.normal : null}
                defaultHeight="auto"
                defaultWidth="100%"
                alt="avatar"
              />
            </div>
          </Popup>
        </Marker>
      )) : [];

    return markers;
  };

  const handleUsersTypeChange = event => {
    let userType = event.target.value;
    setUserType(userType);
  };

  const handleFilterTypeChange = event => {
    let filterType = event.target.value;
    setFilterType(filterType);
  };

  const handleThresholdChange = event => {
    let threshold = event.target.value;
    setThreshold(threshold);
  };

  let markers = makeMarkers();

  return (
    <>
      <FormControl className={classes.formControl}>
        <InputLabel>Users</InputLabel>
        <Select onChange={handleUsersTypeChange} value={userType}>
          {usersType.map(type => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel>Filter</InputLabel>
        <Select onChange={handleFilterTypeChange} value={filterType}>
          {filtersType.map(type => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {filterType !== "All" && (
        <FormControl className={classes.formControl}>
          <InputLabel>Threshold</InputLabel>
          <Select onChange={handleThresholdChange} value={threshold}>
            {thresholds.map(type => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <Map
        className="markercluster-map"
        center={MAP_CENTER_COORDINATES}
        zoom={MAP_ZOOM}
        maxZoom={MAP_MAX_ZOOM}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />

        <MarkerClusterGroup>{markers}</MarkerClusterGroup>
      </Map>
    </>
  );
}
