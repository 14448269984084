import React, { useEffect, useContext } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import ListSubheader from "@material-ui/core/ListSubheader";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CloudImage from "components/CloudImage/CloudImage";
import SmartVideo from "components/SmartVideo/SmartVideo";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";

// @material-ui/icons
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";

import { AppContext } from "../../AppContext";
import AuthService from "../../AuthService";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  formControl: {
    margin: "5px",
    width: "120px"
  },
  selectEmpty: {
    marginTop: "2"
  },
  searchInput: {
    width: "300px"
  },
  textButton: {
    color: "#9c35b3",
    cursor: "pointer",
    fontSize: "20px"
  }
};

export default function Tags() {
  const context = useContext(AppContext);
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const getDiffDay = (date1, date2) => {
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  let initColumns = [
    {
      columnName: "Tag Name",
      getData: data => data.tag,
      getCompareData: () => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: true
    },
    {
      columnName: "Count",
      getData: data => (data.occurences ? data.occurences.length : 0),
      getCompareData: data => (data.occurences ? data.occurences.length : 0),
      isFilterable: true,
      isActive: true,
      isMandatory: true
    },
    {
      columnName: "This week",
      getData: data =>
        data.occurences
          ? data.occurences
              .map(e => e.date)
              .filter(e => getDiffDay(new Date(e), new Date()) <= 7).length
          : 0,
      getCompareData: data =>
        data.occurences
          ? data.occurences
              .map(e => e.date)
              .filter(e => getDiffDay(new Date(e), new Date()) <= 7).length
          : 0,
      isFilterable: true,
      isActive: true,
      isMandatory: false
    },
    {
      columnName: "This month",
      getData: data =>
        data.occurences
          ? data.occurences
              .map(e => e.date)
              .filter(e => getDiffDay(new Date(e), new Date()) <= 30).length
          : 0,
      getCompareData: data =>
        data.occurences
          ? data.occurences
              .map(e => e.date)
              .filter(e => getDiffDay(new Date(e), new Date()) <= 30).length
          : 0,
      isFilterable: true,
      isActive: true,
      isMandatory: false
    },
    {
      columnName: "Photos",
      getData: data => (
        <Button
          onClick={() => {
            selectMediaType(data, "photo");
          }}
          color="secondary"
        >
          {data.occurences
            ? data.occurences.filter(e => e.mediaType === "photo").length
            : 0}
        </Button>
      ),
      getCompareData: data =>
        data.occurences
          ? data.occurences.filter(e => e.mediaType === "photo").length
          : 0,
      isFilterable: true,
      isActive: true,
      isMandatory: false
    },
    {
      columnName: "Videos",
      getData: data => (
        <Button
          onClick={() => {
            selectMediaType(data, "video");
          }}
          color="secondary"
        >
          {data.occurences
            ? data.occurences.filter(e => e.mediaType === "video").length
            : 0}
        </Button>
      ),
      getCompareData: data =>
        data.occurences
          ? data.occurences.filter(e => e.mediaType === "video").length
          : 0,
      isFilterable: true,
      isActive: true,
      isMandatory: false
    }
  ];

  const [toggle, setToggle] = React.useState(false); // Just needed to force refresh
  const [mode, setMode] = React.useState("data");
  const [tags, setTags] = React.useState([]);
  const [order, setOrder] = React.useState("Desc");
  const [columnFilter, setColumnFilter] = React.useState(
    initColumns.map(e => e.isFilterable).indexOf(true)
  );
  const [columns, setColumns] = React.useState(initColumns);
  const [tag, setTag] = React.useState("");
  const [tagType, setTagType] = React.useState("All");
  const [countries, setCountries] = React.useState([]);
  const [country, setCountry] = React.useState("All");
  const [city, setCity] = React.useState("");
  const [posts, setPosts] = React.useState([]);
  const [tagSelected, setTagSelected] = React.useState("");

  const tagTypes = ["All", "Photo", "Video"];
  const orders = ["Asc", "Desc"];

  const selectMediaType = (data, mediaType) => {
    setPosts(data.occurences.filter(e => e.mediaType === mediaType));
    setMode("posts");
    setTagSelected(data.tag);
  };

  const handleColumnFilterChange = event => {
    setColumnFilter(event.target.value);
  };

  const handleOrderChange = event => {
    setOrder(event.target.value);
  };

  const handleTagChange = event => {
    setTag(event.target.value);
  };

  const handleTagTypeChange = event => {
    setTagType(event.target.value);
  };

  const handleColumnStateChange = index => event => {
    let activeColumns = columns.filter(e => e.isActive);
    let currentFilterColumnName = activeColumns[columnFilter]
      ? activeColumns[columnFilter].columnName
      : "";

    let newColumns = columns;
    newColumns[index].isActive = event.target.checked;
    setColumns(newColumns);

    let newFilterColumn = newColumns
      .filter(e => e.isActive)
      .map(e => e.columnName)
      .indexOf(currentFilterColumnName);
    if (newFilterColumn === -1) {
      newFilterColumn = initColumns.map(e => e.isFilterable).indexOf(true);
    }
    setColumnFilter(newFilterColumn);
    setToggle(!toggle);
  };

  const makeDataFromTags = () => {
    var data = [];

    var tagFiltered = tags.filter(
      e =>
        tagType === "All" ||
        e.occurences.map(e => e.mediaType).indexOf(tagType.toLowerCase()) !== -1
    );

    tagFiltered = tagFiltered.filter(
      e =>
        country === "All" ||
        e.occurences.map(e => e.country).indexOf(country) !== -1
    );

    tagFiltered = tagFiltered.filter(
      e =>
        city === "" ||
        e.occurences
          .map(e => (e.city ? e.city.toLowerCase() : ""))
          .join(" ")
          .indexOf(city.toLowerCase()) !== -1
    );

    tagFiltered.forEach(tag => {
      var row = [];
      columns.forEach(column => {
        if (column.isActive) {
          row.push({
            value: column.getData(tag),
            compare: column.getCompareData(tag)
          });
        }
      });
      data.push(row);
    });
    return data;
  };

  const handleCityChange = event => {
    let city = event.target.value;
    setCity(city);
  };

  const handleCountryChange = event => {
    let country = event.target.value;
    setCountry(country);
  };

  const sortData = (a, b) => {
    if (!a[columnFilter] || !b[columnFilter]) {
      return 0;
    }
    return (
      (a[columnFilter].compare - b[columnFilter].compare) *
      (order === "Asc" ? 1 : -1)
    );
  };

  useEffect(() => {
    const authService = new AuthService();

    // Get all tags
    authService.setUrl(context.apiUrl + context.tagsPath + "all");
    authService.request("get", {}, response => {
      console.log("tags : " + JSON.stringify(response.data, null, 4));
      setTags(response.data);
      setCountries(
        ["All"].concat(
          response.data
            .map(tag => tag.occurences.map(e => e.country))
            .join()
            .split(",")
            .filter(country => country !== "")
            .filter((value, index, self) => self.indexOf(value) === index)
        )
      );
    });
  }, []);

  let data = makeDataFromTags();

  return (
    <>
      {mode === "data" && (
        <>
          <FormControl className={classes.formControl}>
            <InputLabel>Tag type</InputLabel>
            <Select onChange={handleTagTypeChange} value={tagType}>
              {tagTypes.map(tagType => (
                <MenuItem value={tagType}>{tagType}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl className={classes.formControl}>
            <InputLabel>Column</InputLabel>
            <Select onChange={handleColumnFilterChange} value={columnFilter}>
              {columns
                .filter(column => column.isFilterable && column.isActive)
                .map(column => {
                  var index = columns
                    .filter(column => column.isActive)
                    .map(field => field.columnName)
                    .indexOf(column.columnName);
                  return <MenuItem value={index}>{column.columnName}</MenuItem>;
                })}
            </Select>
          </FormControl>

          <FormControl className={classes.formControl}>
            <InputLabel>Order</InputLabel>
            <Select onChange={handleOrderChange} value={order}>
              {orders.map(order => (
                <MenuItem value={order}>{order}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl className={classes.formControl}>
            <TextField
              label="Tag"
              type="tag"
              variant="outlined"
              onChange={handleTagChange}
            />
          </FormControl>

          <FormControl className={classes.formControl}>
            <InputLabel>Countries</InputLabel>
            <Select onChange={handleCountryChange} value={country}>
              {countries.map(countrie => (
                <MenuItem key={countrie} value={countrie}>
                  {countrie}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl className={classes.formControl}>
            <TextField
              label="City"
              type="city"
              variant="outlined"
              onChange={handleCityChange}
            />
          </FormControl>

          <br />

          {columns.map((column, index) => (
            <FormControlLabel
              control={
                <Checkbox
                  disabled={column.isMandatory}
                  icon={<RadioButtonUncheckedIcon />}
                  checkedIcon={<CheckCircleIcon />}
                  checked={column.isActive}
                  onChange={handleColumnStateChange(index)}
                  color="secondary"
                />
              }
              label={column.columnName}
            />
          ))}

          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>Tags</h4>
                  <p className={classes.cardCategoryWhite}>
                    Here are all the tags
                  </p>
                </CardHeader>
                <CardBody>
                  <Table
                    tableHeaderColor="primary"
                    tableHead={columns
                      .filter(column => column.isActive)
                      .map(column => column.columnName)}
                    tableData={data
                      .sort(sortData)
                      .filter(
                        row => tag === "" || row[0].value.indexOf(tag) !== -1
                      )
                      .map(e => e.map(e => e.value))}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </>
      )}

      {mode === "posts" && (
        <>
          <p onClick={() => setMode("data")} className={classes.textButton}>
            Back to data
          </p>
          {
            <GridList cellHeight={450} cols={3} className={classes.gridList}>
              <GridListTile key="Subheader" cols={3} style={{ height: "auto" }}>
                <ListSubheader component="div">#{tagSelected}</ListSubheader>
              </GridListTile>
              {posts.map(post => (
                <GridListTile cols={1} key={post.url}>
                  {post.mediaType === "video" && (
                    <SmartVideo
                      className={classes.postImg}
                      video={post.src.url}
                      autoPlay
                      loop
                      controls
                      defaultHeight="auto"
                      defaultWidth="100%"
                      conserveRatio={true}
                    />
                  )}

                  {post.mediaType === "photo" && (
                    <CloudImage
                      className={classes.postImg}
                      src={post.src ? post.src.normal : null}
                      defaultHeight="auto"
                      defaultWidth="100%"
                      alt="post"
                    />
                  )}
                  <GridListTileBar
                    title={post.userName}
                    subtitle={
                      <span>{new Date(post.date).toLocaleString()}</span>
                    }
                  />
                </GridListTile>
              ))}
            </GridList>
          }
        </>
      )}
    </>
  );
}
