import AuthService from "../AuthService";
import { stackBlurCanvasRGB } from "../3rdParty/StackBlur";
import watermarkImage from "../img/watermark.png";
import CryptoJS from "crypto-js";

const LOGO_MARGIN_PERCENTAGE = 1;
const MIN_WATERMARK_WIDTH = 50;
const WATERMARK_SIZE_RATIO = 7;

export function transform(
  context,
  fileOrBlob,
  maxSize,
  blurRadius,
  watermark,
  userName,
  imageEncoding,
  callback
) {
  const fileLoader = new FileReader();
  const imageObj = new Image();
  const watermarkObj = new Image();

  var isFile = fileOrBlob ? typeof fileOrBlob.name == "string" : null;

  // check for an image then
  //trigger the file loader to get the data from the image
  if (isFile) {
    if (fileOrBlob.type.match("image.*")) {
      fileLoader.readAsDataURL(fileOrBlob);
    } else {
      alert("File is not an image");
    }
  }

  // setup the file loader onload function
  // once the file loader has the data it passes it to the
  // image object which, once the image has loaded,
  // triggers the images onload function
  if (isFile) {
    fileLoader.onload = function () {
      if (watermark) {
        watermarkObj.src = watermarkImage;
      } else {
        imageObj.src = this.result;
      }
    };

    fileLoader.onabort = function () {
      alert("The upload was aborted.");
    };

    fileLoader.onerror = function () {
      alert("An error occured while reading the file.");
    };
  } else {
    watermarkObj.src = watermarkImage;
  }

  watermarkObj.onload = function () {
    if (isFile) {
      imageObj.src = fileLoader.result;
    } else {
      imageObj.src = URL.createObjectURL(fileOrBlob);
    }
  };

  // set up the images onload function which clears the hidden canvas context,
  // draws the new image then gets the blob data from it
  imageObj.onload = function () {
    // Check for empty images
    if (this.width === 0 || this.height === 0) {
      alert("Image is empty");
    } else {
      let ratio =
        Math.min(this.width, this.height) / Math.max(this.width, this.height);

      let newWidth = 0;
      let newHeight = 0;
      if (this.width > this.height) {
        newWidth = maxSize ? Math.min(maxSize, this.width) : this.width;
        newHeight = newWidth * ratio;
      } else {
        newHeight = maxSize ? Math.min(maxSize, this.height) : this.height;
        newWidth = newHeight * ratio;
      }

      let canvas = document.createElement("canvas");
      let context2D = null;

      //create a hidden canvas object we can use to create the new resized image data
      canvas.id = "hiddenCanvas";
      canvas.width = newWidth;
      canvas.height = newHeight;
      canvas.style.visibility = "hidden";

      context2D = canvas.getContext("2d");

      context2D.clearRect(0, 0, newWidth, newHeight);
      context2D.drawImage(
        imageObj,
        0,
        0,
        this.width,
        this.height,
        0,
        0,
        newWidth,
        newHeight
      );

      if (blurRadius) {
        let blurValue = Math.round(
          (Math.min(newHeight, newWidth) * blurRadius) / 100
        );
        stackBlurCanvasRGB(
          canvas,
          0,
          0,
          newWidth,
          newHeight,
          Math.min(blurValue, context.maxBlurValue)
        );
      }

      if (watermark) {
        let watermarkSize = Math.max(
          Math.floor((newHeight * WATERMARK_SIZE_RATIO) / 100),
          MIN_WATERMARK_WIDTH
        );

        let watermarkSizeRatio =
          (newHeight > newWidth ? watermarkObj.height : watermarkObj.width) /
          watermarkSize;

        let waterMarkHeight = Math.round(
          watermarkObj.height / watermarkSizeRatio
        );
        let waterMarkWidth = Math.round(
          watermarkObj.width / watermarkSizeRatio
        );

        const xMargin = Math.floor((newWidth * LOGO_MARGIN_PERCENTAGE) / 100);
        const yMargin = Math.floor((newWidth * LOGO_MARGIN_PERCENTAGE) / 100);

        let baseUrl = `${window.location.protocol}//${window.location.host}`;

        let f = new FontFace(
          "comfortaa",
          `url(${baseUrl}/fonts/Comfortaa-Regular.ttf)`
        );

        f.load()
          .then(function () {
            // Prêt à utiliser la police dans un contexte de canevas

            const X = Math.round(xMargin);
            const Y = Math.round(newHeight - waterMarkHeight - yMargin);

            let gradient = context2D.createLinearGradient(0, newHeight, 0, Y);
            gradient.addColorStop(0, "rgba(0, 0, 0, 0.5)");
            gradient.addColorStop(1, "rgba(0, 0, 0, 0)");

            context2D.fillStyle = gradient;
            context2D.fillRect(0, Y, newWidth, newHeight);

            context2D.drawImage(
              watermarkObj,
              0,
              0,
              watermarkObj.width,
              watermarkObj.height,
              X,
              Y,
              waterMarkWidth,
              waterMarkHeight
            );

            context2D.fillStyle = "white";
            context2D.font = `${Math.round(
              waterMarkHeight / 3
            )}px textFontFamily`;
            context2D.fillText(
              `uncove.com/${userName}`,
              X + waterMarkWidth + 10,
              Y + waterMarkHeight - 10
            );
            canvas.toBlob(callback, imageEncoding);
          })
          .catch(function (error) {
            console.log("font loading error : " + error);
            canvas.toBlob(callback, imageEncoding);
          });
      } else {
        canvas.toBlob(callback, imageEncoding);
      }
    }
  };

  imageObj.onabort = function () {
    alert("Image load was aborted.");
  };

  imageObj.onerror = function () {
    alert("An error occured while loading image.");
  };
}

export function uploadPhoto(
  context,
  sha1,
  largeBlob,
  mediumBlob,
  smallBlob,
  blurredBlob,
  isPrivate,
  tags,
  type,
  postId,
  mediaPrivateId
) {
  return new Promise((resolve, reject) => {
    let authService = new AuthService();
    authService.setRetryCount("infinite");

    const formData = new FormData();
    formData.append("largeFile", largeBlob);
    formData.append("mediumFile", mediumBlob);
    formData.append("smallFile", smallBlob);
    formData.append("blurredFile", blurredBlob);
    formData.set("isPrivate", isPrivate);
    formData.set("tags", JSON.stringify(tags));
    formData.set("type", type);
    formData.set("postId", postId);
    formData.set("mediaPrivateId", mediaPrivateId);
    formData.set("sha1", sha1);

    authService.setContentType("multipart/form-data");
    authService.setUrl(context.apiUrl + context.photosPath);
    // Upload photo file
    authService.request("post", formData, response => {
      let result = response.data;
      resolve(result);
    });
  });
}

export function getPhotoUploadAuthorization(context, file, type, isPrivate) {
  return new Promise((resolve, reject) => {
    getPhotoSha1(file).then(sha1 => {
      let authService = new AuthService();
      authService.setUrl(context.apiUrl + context.photosPath + "authorization");
      authService.request("post", { sha1, type, isPrivate }, response => {
        let { data } = response;
        if (data.error) {
          resolve(data);
        } else {
          resolve({ sha1 });
        }
      });
    });
  });
}

export function getPhotoSha1(file) {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.onload = function (f) {
      let result = this.result;
      let wordArr = CryptoJS.lib.WordArray.create(result);
      let sha1 = CryptoJS.SHA1(wordArr);
      resolve(sha1.toString());
    };
    reader.readAsArrayBuffer(file);
  });
}

export function getPhotosFormated(
  context,
  userName,
  file,
  mime,
  config,
  callback
) {
  let transformPromises = [];

  transformPromises.push(
    new Promise((resolve, reject) => {
      transform(context, file, 1500, 0, true, userName, mime, largeBlob => {
        resolve(largeBlob);
      });
    })
  );

  transformPromises.push(
    new Promise((resolve, reject) => {
      if (config.medium) {
        transform(context, file, 500, 0, false, "", mime, mediumBlob => {
          resolve(mediumBlob);
        });
      } else {
        resolve(null);
      }
    })
  );

  transformPromises.push(
    new Promise((resolve, reject) => {
      if (config.small) {
        transform(context, file, 150, 0, false, "", mime, smallBlob => {
          resolve(smallBlob);
        });
      } else {
        resolve(null);
      }
    })
  );

  transformPromises.push(
    new Promise((resolve, reject) => {
      if (config.blur) {
        transform(
          context,
          file,
          null,
          context.blurRadius,
          false,
          "",
          mime,
          blurredBlob => {
            resolve(blurredBlob);
          }
        );
      } else {
        resolve(null);
      }
    })
  );

  Promise.all(transformPromises).then(values => {
    callback({
      largeBlob: values[0],
      mediumBlob: values[1],
      smallBlob: values[2],
      blurredBlob: values[3]
    });
  });
}

export function getSize(file) {
  return new Promise((resolve, reject) => {
    let img = new Image();
    var objectUrl = URL.createObjectURL(file);
    img.onload = function () {
      resolve({
        width: this.width,
        height: this.height
      });
      URL.revokeObjectURL(objectUrl);
    };
    img.src = objectUrl;
  });
}

export async function processFiles(files, props, context) {
  return new Promise(async resolve => {
    const { setGlobalState, globalState } = props;
    const { tr, user } = globalState;

    setGlobalState({ isLoading: true });

    const postsToEdit = Array.from(files).map(file => {
      const mime = file.type;
      const type = mime.split("/")[0];
      const res = { type, file };
      const url = URL.createObjectURL(file);

      res.blobUrl = url;
      res.mime = mime;

      return res;
    });

    const promises = [];

    let i = 0;
    while (i < postsToEdit.length) {
      if (postsToEdit.length > 1) {
        const number = i + 1;
        const percent = Math.round((number * 100) / postsToEdit.length);
        setGlobalState({
          loadingMessage: tr.pleaseWaitProgress.replace("[value]", percent)
        });
      }

      const { file, type } = postsToEdit[i];
      if (type === "image") {
        const resolution = await getSize(file);
        if (resolution.width > resolution.height) {
          if (resolution.width < context.fileMinSizeInPixel) {
            postsToEdit.splice(i, 1);
            setGlobalState({
              message: tr.infoMinPhotoWidth.replace(
                "[size]",
                context.fileMinSizeInPixel
              )
            });
          }
        } else {
          if (resolution.height < context.fileMinSizeInPixel) {
            postsToEdit.splice(i, 1);
            setGlobalState({
              message: tr.infoMinPhotoHeight.replace(
                "[size]",
                context.fileMinSizeInPixel
              )
            });
          }
        }
      }

      if (file.size > context.fileMaxSizeInGiga * 1000 * 1000 * 1000) {
        postsToEdit.splice(i, 1);
        setGlobalState({
          message: tr.infoLimitFileSize.replace(
            "[limit]",
            context.fileMaxSizeInGiga
          )
        });
      } else {
        if (type === "image") {
          promises.push(
            new Promise(resolve => {
              const destMime = "image/jpeg";
              const config = { medium: true, small: true, blur: true };
              getPhotosFormated(
                context,
                user.userName,
                file,
                destMime,
                config,
                formats => {
                  const {
                    largeBlob,
                    mediumBlob,
                    smallBlob,
                    blurredBlob
                  } = formats;

                  resolve({ largeBlob, mediumBlob, smallBlob, blurredBlob });
                }
              );
            })
          );
        }
        ++i;
      }
    }

    Promise.all(promises).then(values => {
      setGlobalState({
        isLoading: false,
        loadingMessage: tr.pleaseWait
      });

      if (postsToEdit.length) {
        for (let i = 0; i < values.length; ++i) {
          const { smallBlob, mediumBlob, largeBlob, blurredBlob } = values[i];
          postsToEdit[i].smallBlob = smallBlob;
          postsToEdit[i].mediumBlob = mediumBlob;
          postsToEdit[i].largeBlob = largeBlob;
          postsToEdit[i].blurredBlob = blurredBlob;

          // Uncomment this line to override post file with blurred file
          // postsToEdit[i].blobUrl = URL.createObjectURL(blurredBlob);

          // Uncomment this line to open large photo format with watermak
          // window.open(URL.createObjectURL(largeBlob));
        }

        resolve(postsToEdit);
      }
    });
  });
}
