import React, { useEffect, useContext, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import CircularProgress from "@material-ui/core/CircularProgress";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Movepoint from "components/Movepoint/Movepoint.js";
import Autosuggest from "react-autosuggest";

// @material-ui/icons
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import DeleteIcon from "@material-ui/icons/Delete";

import { AppContext } from "../../AppContext";
import AuthService from "../../AuthService";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  spinnerContainer: {
    marginTop: "10px",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    gap: "20px",
    margin: "15px auto",
  },
  leftAutoSuggest: {
    marginTop: "10px",
    position: "relative",
    zIndex: "3",
  },
  centerAutoSuggest: {
    display: "flex",
    justifyContent: "center",
    flex: 1,
    margin: "15px auto",
    width: "650px",
    gap: "20px",
    position: "relative",
    zIndex: "2",
    transform: "translateX(-5px)",
  },
  formControlTextField: {
    width: "100%",
  },
  errorMessage: {
    color: "#d62546",
    margin: "15px auto",
    width: "100%",
    textAlign: "center",
  },
  infoButton: {
    background: "skyblue",
    color: "#fff",
    height: "30px",
  },
  dangerButton: {
    background: "#d62546",
    color: "#fff",
    height: "30px",
  },
};

export default function Alias() {
  const context = useContext(AppContext);
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const authService = new AuthService();

  let initColumns = [
    {
      columnName: "Alias",
      getData: (data) => data.alias,
      getCompareData: () => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
    {
      columnName: "User Name",
      getData: (data) => data.userName,
      getCompareData: () => 0,
      isFilterable: true,
      isActive: true,
      isMandatory: true,
    },
    {
      columnName: "Delete",
      getData: (data) => (
        <IconButton
          aria-label="delete"
          className={classes.deleteButton}
          onClick={() => handleOpenDialogDeleting(data)}
        >
          <DeleteIcon color="secondary" />
        </IconButton>
      ),
      getCompareData: (data) => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: true,
    },
  ];

  const [alias, setAlias] = React.useState([]);
  const [columns, setColumns] = React.useState(initColumns);
  const [tableData, setTableData] = useState([]);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(50);
  const [searchLoading, setSearchLoading] = useState(true);

  //Filter Alias
  const [userNameInput, setUserNameInput] = useState("");
  const [userName, setUserName] = useState("");
  const [userNameSuggestions, setUserNameSuggestions] = useState([]);

  // Delete Alias
  const [openDialogDeleting, setOpenDialogDeleting] = useState(false);
  const [aliasToDelete, setAliasToDelete] = useState(null);
  const [addAliasSection, setAddAliasSection] = useState(false);

  // New Alias
  const [newAlias, setNewAlias] = useState("");
  const [newUserName, setNewUserName] = useState("");
  const [newUserNameSuggestions, setNewUserNameSuggestions] = useState([]);
  const [newAliasError, setNewAliasError] = useState("");

  useEffect(() => {
    searchAlias(true);
  }, [userName]);

  const searchMore = (reset) => {
    // Avoid to be triggered immediatly
    if (tableData.length > 0) {
      searchAlias(reset);
    }
  };

  const searchAlias = (reset) => {
    setSearchLoading(true);

    authService.setUrl(context.apiUrl + context.usersPath + "alias/all");
    authService.request(
      "get",
      {
        start: reset ? 0 : start,
        end: reset ? 50 : end,
        userName,
      },
      (response) => {
        const { data } = response;
        setSearchLoading(false);
        let nextAlias = reset ? data : alias.concat(data);
        setAlias(nextAlias);
        let newTabledata = makeDataFromAlias(nextAlias, columns);
        setTableData(newTabledata);
        let newStart = nextAlias.length;
        let newEnd = newStart + 50;
        setStart(newStart);
        setEnd(newEnd);
      }
    );
  };

  const makeDataFromAlias = (userAlias, colums) => {
    var data = [];

    userAlias.forEach((alias) => {
      let row = [];
      colums.forEach((column) => {
        if (column.isActive) {
          row.push({
            value: column.getData(alias),
            compare: column.getCompareData(alias),
          });
        }
      });
      data.push(row);
    });

    return data;
  };

  const handleOpenDialogDeleting = (data) => {
    setOpenDialogDeleting(true);
    setAliasToDelete(data);
  };

  const handleCloseDialogDeleting = () => {
    setOpenDialogDeleting(false);
    setAliasToDelete(null);
  };

  const deleteAlias = () => {
    authService.setUrl(context.apiUrl + context.usersPath + "alias");
    authService.request(
      "delete",
      {
        userAliasId: aliasToDelete._id,
      },
      () => {
        handleCloseDialogDeleting();
        searchAlias(true /* reset */);
      }
    );
  };

  const handleOpenAliasSection = () => {
    setAddAliasSection(true);
    getLastVipAlias(alias);
  };

  const handleCloseAliasSection = () => {
    setAddAliasSection(false);
    setNewAlias("");
    setNewUserName("");
    setNewAliasError("");
  };

  const addUserAlias = () => {
    if (newAlias && newUserName) {
      authService.setUrl(context.apiUrl + context.usersPath + "alias");
      authService.request(
        "post",
        {
          userName: newUserName,
          alias: newAlias,
        },
        (response) => {
          const { data } = response;
          if (data.error) {
            setNewAliasError(data.error);
            return;
          }
          handleCloseAliasSection();
          searchAlias(true);
        }
      );
    }
  };

  const getLastVipAlias = (alias) => {
    const vips = alias
      .map((a) => a.alias)
      .filter((a) => a && a.slice(0, 3) === "vip");

    if (vips.length == 0) {
      setNewAlias("vip1");
      return;
    }

    const nextVipNumber =
      vips.reduce((max, vip) => {
        const num = parseInt(vip.slice(3), 10);
        return num > max ? num : max;
      }, 0) + 1;

    setNewAlias("vip" + nextVipNumber);
  };

  const onSuggestionSelected = (name, type) => {
    if (type == "userName") {
      setUserNameInput(name);
      setUserName(name);
    } else if (type == "newUserName") {
      setNewUserName(name);
    }
  };

  const userNameInputProps = {
    placeholder: "User Name",
    onChange: (event) => handleUserNameChange(event, "userName"),
    value: userNameInput,
    style: {
      padding: "17.75px 20px",
      width: "315px",
      boxSizing: "border-box",
    },
  };

  const newUserNameInputProps = {
    placeholder: "User Name",
    onChange: (event) => handleUserNameChange(event, "newUserName"),
    value: newUserName,
    style: {
      padding: "17.75px 20px",
      width: "315px",
      boxSizing: "border-box",
    },
  };

  const handleUserNameChange = (event, type) => {
    let username = event.target.value;
    if (username != undefined) {
      if (type == "userName") {
        setUserNameInput(username);
        if (userName) setUserName("");
      } else if (type == "newUserName") {
        setNewUserName(username);
      }

      authService.setUrl(context.apiUrl + context.usersPath + "suggestion");
      authService.request(
        "get",
        {
          name: username,
          show: "All",
          age: "[18,60]",
          distance: 100,
          country: "All",
          sex: "All",
          userType: "All",
          radarVisibilityOnly: false,
        },
        (response) => {
          const { data } = response;
          if (type == "userName") {
            setUserNameSuggestions(data);
          } else if (type == "newUserName") {
            setNewUserNameSuggestions(data);
          }
        }
      );
    }
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Alias</h4>
              <p className={classes.cardCategoryWhite}>
                Here are all about alias
              </p>
            </CardHeader>
            <CardBody>
              {columns.map((column, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      disabled={column.isMandatory}
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<CheckCircleIcon />}
                      checked={column.isActive}
                      onChange={() => {}}
                      color="secondary"
                    />
                  }
                  label={column.columnName}
                />
              ))}

              <div className={classes.leftAutoSuggest}>
                <Autosuggest
                  suggestions={userNameSuggestions.filter(
                    (e) =>
                      e?.toLowerCase().indexOf(userNameInput?.toLowerCase()) !==
                      -1
                  )}
                  onSuggestionsFetchRequested={() => {}}
                  onSuggestionsClearRequested={() => {}}
                  onSuggestionSelected={(event, data) =>
                    onSuggestionSelected(data.suggestionValue, "userName")
                  }
                  getSuggestionValue={(suggestion) => suggestion}
                  shouldRenderSuggestions={() => true}
                  renderSuggestion={(elmt) => <span>{elmt}</span>}
                  inputProps={userNameInputProps}
                />
              </div>

              {!addAliasSection && (
                <div className={classes.center}>
                  <Button
                    className={classes.infoButton}
                    onClick={handleOpenAliasSection}
                    variant="contained"
                  >
                    Add alias
                  </Button>
                </div>
              )}

              {addAliasSection && (
                <>
                  <p className={classes.center}>
                    <strong>Add alias</strong>
                  </p>
                  <div className={classes.centerAutoSuggest}>
                    <FormControl className={classes.formControlTextField}>
                      <TextField
                        label="Alias"
                        type="search"
                        variant="outlined"
                        autoFocus={true}
                        value={newAlias}
                        onChange={(e) => setNewAlias(e.target.value)}
                        maxRows={1}
                      />
                    </FormControl>
                    <Autosuggest
                      suggestions={newUserNameSuggestions.filter(
                        (e) =>
                          e
                            ?.toLowerCase()
                            .indexOf(newUserName?.toLowerCase()) !== -1
                      )}
                      onSuggestionsFetchRequested={() => {}}
                      onSuggestionsClearRequested={() => {}}
                      onSuggestionSelected={(event, data) =>
                        onSuggestionSelected(
                          data.suggestionValue,
                          "newUserName"
                        )
                      }
                      getSuggestionValue={(suggestion) => suggestion}
                      shouldRenderSuggestions={() => true}
                      renderSuggestion={(elmt) => <span>{elmt}</span>}
                      inputProps={newUserNameInputProps}
                    />
                  </div>

                  {newAliasError && (
                    <p className={classes.errorMessage}>{newAliasError}</p>
                  )}

                  <div className={classes.center}>
                    <Button
                      className={classes.infoButton}
                      onClick={addUserAlias}
                      variant="contained"
                    >
                      Add
                    </Button>
                    <Button
                      className={classes.dangerButton}
                      onClick={handleCloseAliasSection}
                      variant="contained"
                    >
                      Close
                    </Button>
                  </div>
                </>
              )}

              <Table
                tableHeaderColor="primary"
                tableHead={columns
                  .filter((column) => column.isActive)
                  .map((column) => column.columnName)}
                tableData={tableData.map((e) => e.map((e) => e.value))}
              />

              {searchLoading && (
                <div className={classes.spinnerContainer}>
                  <CircularProgress color="secondary" />
                </div>
              )}
              <Movepoint
                onEnter={() => {
                  searchMore(false);
                }}
                onLeave={() => {}}
              />

              <Dialog
                open={openDialogDeleting && aliasToDelete}
                onClose={handleCloseDialogDeleting}
                maxWidth={false}
              >
                {aliasToDelete && (
                  <>
                    <DialogTitle id="alert-dialog-title">
                      Delete user alias
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        Alias : <strong>{aliasToDelete?.alias}</strong> / User
                        Name : <strong>{aliasToDelete?.userName}</strong>
                      </DialogContentText>
                      <DialogActions>
                        <Button
                          onClick={handleCloseDialogDeleting}
                          color="primary"
                        >
                          Cancel
                        </Button>
                        <Button onClick={deleteAlias} color="secondary">
                          Delete
                        </Button>
                      </DialogActions>
                    </DialogContent>
                  </>
                )}
              </Dialog>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}
