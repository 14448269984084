import React, { useEffect, useContext } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Box from "@material-ui/core/Box";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Thumb from "components/Thumb/Thumb.js";

// @material-ui/icons
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";

import { AppContext } from "../../AppContext";
import AuthService from "../../AuthService";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  formControl: {
    margin: "5px",
    width: "120px"
  },
  selectEmpty: {
    marginTop: "2"
  },
  searchInput: {
    width: "300px"
  },
  actionText: {
    cursor: "pointer",
    fontWeight: "bold",
    color: "#d62546",
    fontSize: "16px"
  },
  infoText: {
    cursor: "pointer",
    fontWeight: "bold",
    color: "skyblue",
    fontSize: "16px"
  },
  inputText: {
    width: "100px"
  },
  inputLongText: {
    width: "200px"
  }
};

const useStyles = makeStyles(styles);

const orders = ["Asc", "Desc"];

const replyOptions = {
  news: ["contentLegal", "contentDeleted"],
  profile: ["profilVerified", "profilDeleted", "contentLegal", "contentDeleted"]
}

export default function Reportings() {
  const context = useContext(AppContext);
  const classes = useStyles();

  const initColumns = [
    {
      columnName: "Reporter",
      getData: data => data.reporter.userName,
      getCompareData: data => data.reporter.userName,
      isFilterable: false,
      isActive: true,
      isMandatory: true
    },
    {
      columnName: "Reported",
      getData: data => (
        <p
          onClick={() => selectReported(data.reported.userName)}
          className={classes.actionText}
        >
          {data.reported.userName}
        </p>
      ),
      getCompareData: data => data.reported.userName,
      isFilterable: false,
      isActive: true,
      isMandatory: true
    },
    {
      columnName: "Thumb",
      getData: data => (
        <Thumb
          key={data.photo ? data.photo.normal : null}
          height="50px"
          width="50px"
          src={data.photo ? data.photo.normal : null}
        />
      ),
      getCompareData: data => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: false
    },
    {
      columnName: "Details",
      getData: data => data.details,
      getCompareData: data => data.details,
      isFilterable: false,
      isActive: true,
      isMandatory: true
    },
    {
      columnName: "Country",
      getData: data => data.country,
      getCompareData: data => data.country,
      isFilterable: false,
      isActive: true,
      isMandatory: true
    },
    {
      columnName: "City",
      getData: data => data.city,
      getCompareData: data => data.city,
      isFilterable: false,
      isActive: true,
      isMandatory: true
    },
    {
      columnName: "Type",
      getData: data => data.type,
      getCompareData: data => data.type,
      isFilterable: false,
      isActive: true,
      isMandatory: true
    },
    {
      columnName: "Option",
      getData: data => data.option,
      getCompareData: data => data.option,
      isFilterable: false,
      isActive: true,
      isMandatory: true
    },
    {
      columnName: "Count",
      getData: data => data.count,
      getCompareData: data => data.count,
      isFilterable: true,
      isActive: true,
      isMandatory: true
    },
    // {
    //   columnName: "Move",
    //   getData: (data, tr, datasFiltered, index) => (
    //     <p
    //       className={classes.actionText}
    //       onClick={() => moveToPrivate(data.content, datasFiltered, index)}
    //     >
    //       Move to private
    //     </p>
    //   ),
    //   getCompareData: () => 0,
    //   isFilterable: false,
    //   isActive: false,
    //   isMandatory: false
    // },
    {
      columnName: "Show",
      getData: data => (
        <p className={classes.actionText} onClick={() => show(data)}>
          Show
        </p>
      ),
      getCompareData: data => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: false
    },
    {
      columnName: "Reply",
      getData: (data, tr, allReports, type) => (
        <>
          <Box display="flex" flexDirection="row">
            <Select onChange={evt => handleReplyChanged(
                data,
                evt.target.value,
                allReports,
                type,
                tr
              )}
              value={data.reply}
            >
              {replyOptions[data.type].map(
                  option => <MenuItem value={option}>{tr[option]}</MenuItem>
                )
              }
            </Select>
            <p className={classes.infoText} onClick={() => reply(data, allReports, type, tr)}>
              Reply
            </p>
          </Box>
        </>
      ),
      getCompareData: data => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: false
    },
    // {
    //   columnName: "Deleted",
    //   getData: data => (data.reported.deleted ? <p>Deleted</p> : <p> - </p>),
    //   getCompareData: data => data.reported.deleted,
    //   isFilterable: true,
    //   isActive: false,
    //   isMandatory: false
    // },
    // {
    //   columnName: "Deletion date",
    //   getData: data =>
    //     data.reported.deletionDate ? (
    //       new Date(data.reported.deletionDate).toDateString()
    //     ) : (
    //       <p> - </p>
    //     ),
    //   getCompareData: data => data.reported.deletionDate,
    //   isFilterable: true,
    //   isActive: false,
    //   isMandatory: false
    // },
    // {
    //   columnName: "Delete content",
    //   getData: (data, tr, datasFiltered) => (
    //     <p
    //       onClick={() => deleteContent(data.content, datasFiltered)}
    //       className={classes.actionText}
    //     >
    //       Delete {data.type === "news" ? "post" : "user"}
    //     </p>
    //   ),
    //   getCompareData: () => {},
    //   isFilterable: false,
    //   isActive: false,
    //   isMandatory: false
    // },
    // {
    //   columnName: "Delete report",
    //   getData: (data, tr, datasFiltered, type, index) => (
    //     <p
    //       onClick={() => deleteReport(data.content, datasFiltered, index)}
    //       className={classes.actionText}
    //     >
    //       Delete Report
    //     </p>
    //   ),
    //   getCompareData: () => {},
    //   isFilterable: false,
    //   isActive: false,
    //   isMandatory: false
    // }
  ];

  const types = ["news", "profile"];

  const [tr, setTr] = React.useState({});
  const [toggle, setToggle] = React.useState(false); // Just needed to force refresh
  const [allReports, setAllReports] = React.useState([]);
  const [order, setOrder] = React.useState("Desc");
  const [columnFilter, setColumnFilter] = React.useState(
    initColumns
      .filter(e => e.isActive)
      .map(e => e.columnName)
      .indexOf("Count")
  );
  const [columns, setColumns] = React.useState(initColumns);
  const [search, setSearch] = React.useState("");
  const [type, setType] = React.useState(types[0]);
  const [countries, setCountries] = React.useState([]);
  const [country, setCountry] = React.useState("All");
  const [city, setCity] = React.useState("");
  const [data, setData] = React.useState([]);

  const handleReplyChanged = (report, reply, allReports, type, tr) => {
    let index = allReports.map(e => e._id).indexOf(report._id);
    allReports[index].reply = reply;
    makeData(allReports, type, tr);
  }

  const reply = (report, allReports, type, tr) => {
    let index = allReports.map(e => e._id).indexOf(report._id);
    allReports.splice(index, 1);
    makeData(allReports, type, tr);

    let authService = new AuthService();
    authService.setUrl(context.apiUrl + context.reportPath + "reply");
    authService.request("post", {
      reportId: report._id,
      reply: report.reply
    }, () => {});
  }

  const deleteContent = (content, datas) => {
    let authService = new AuthService();
    if (content.indexOf("posts") !== -1) {
      authService.setUrl(context.apiUrl + context.postsPath);
      authService.request("delete", { postId: content }, () => {});
    } else {
      authService.setUrl(context.apiUrl + context.usersPath);
      authService.request("delete", { userId: content }, result => {});
    }
  };

  const deleteReport = (content, datas, index) => {
    let authService = new AuthService();
    authService.setUrl(context.apiUrl + context.reportPath);
    authService.request("delete", { content }, result => {
      const { data } = result;
      if (data.result === "success") {
        var newReports = datas;
        newReports.splice(index, 1);
        setAllReports(newReports);
      }
    });
  };

  const show = data => {
    if (data.type == "news") {
      window.open(data.photo.normal);
    } else {
      window.open("https://uncove.com/" + data.reported.userName);
    }
  };

  // const moveToPrivate = (content, datas, index) => {
  //   var authService = new AuthService(context.apiUrl + context.postsPath);
  //   authService.request(
  //     "put",
  //     {
  //       postId: content,
  //       isPrivate: true
  //     },
  //     () => {
  //       deleteReport(content, datas, index);
  //     }
  //   );
  // };

  const handleColumnFilterChange = event => {
    setColumnFilter(event.target.value);
  };

  const handleOrderChange = event => {
    setOrder(event.target.value);
  };

  const handleSearchChange = event => {
    setSearch(event.target.value);
  };

  const handleCityChange = event => {
    let city = event.target.value;
    setCity(city);
  };

  const selectReported = userName => {
    setSearch(userName);
  };

  const handleColumnStateChange = index => event => {
    let activeColumns = columns.filter(e => e.isActive);
    let currentFilterColumnName = activeColumns[columnFilter]
      ? activeColumns[columnFilter].columnName
      : "";

    let newColumns = columns;
    newColumns[index].isActive = event.target.checked;
    setColumns(newColumns);

    let newFilterColumn = newColumns
      .filter(e => e.isActive)
      .map(e => e.columnName)
      .indexOf(currentFilterColumnName);
    if (newFilterColumn === -1) {
      newFilterColumn = initColumns.map(e => e.isFilterable).indexOf(true);
    }
    setColumnFilter(newFilterColumn);
    setToggle(!toggle);
  };

  const handleCountryChange = event => {
    let country = event.target.value;
    setCountry(country);
  };

  const handleTypeChange = (event, allReports, tr) => {
    let type = event.target.value;
    setType(type);
  };

  const makeData = (allReports, type, tr) => {
    let datasFiltered = allReports.filter(
      data => country === "All" || data.country === country
    );

    datasFiltered = allReports.filter(data => data.type && data.type === type);

    datasFiltered = datasFiltered.filter(
      data =>
        city === "" ||
        data.city.toLowerCase().indexOf(city.toLowerCase()) !== -1
    );

    // Add reply field if missing
    datasFiltered = datasFiltered.map(
      e => { return e.reply ? e : {...e, ...{ reply: replyOptions[e.type][0] }}}
    )

    var res = [];
    datasFiltered
      .forEach((data, index) => {
      data.count = datasFiltered
        .map(e => e.reported.userName)
        .filter(e => e === data.reported.userName).length;
      var row = [];
      columns.forEach(column => {
        if (column.isActive) {
          row.push({
            value: column.getData(data, tr, allReports, type, index),
            compare: column.getCompareData(data)
          });
        }
      });
      res.push(row);
    });

    setData(res);
  };

  const sortData = (a, b) => {
    if (!a[columnFilter] || !b[columnFilter]) {
      return 0;
    }
    return (
      (a[columnFilter].compare - b[columnFilter].compare) *
      (order === "Asc" ? 1 : -1)
    );
  };

  useEffect(() => {
    const authService = new AuthService();

    // Get translations
    authService.setUrl(context.apiUrl + context.translationsPath);
    authService.request("get", { country: "EN", type: "site" }, response => {
      const { data } = response;
      if (data.length > 0) {
        setTr(data[0].translations);
      } else {
        console.log("No translations found !");
      }

      authService.setUrl(context.apiUrl + context.reportPath);
      authService.request("get", {}, response => {
        setAllReports(response.data);
        makeData(response.data, type, data[0].translations);
        setCountries(
          ["All"].concat(
            response.data
              .map(data => data.country)
              .filter(country => country !== "")
              .filter((value, index, self) => self.indexOf(value) === index)
          )
        );
      });
    });
  }, []);

  useEffect(() => {
    makeData(allReports, type, tr);
  }, [type]);

  return (
    <>
      <FormControl className={classes.formControl}>
        <InputLabel>Column</InputLabel>
        <Select onChange={handleColumnFilterChange} value={columnFilter}>
          {columns
            .filter(column => column.isFilterable && column.isActive)
            .map(column => {
              var index = columns
                .filter(column => column.isActive)
                .map(field => field.columnName)
                .indexOf(column.columnName);
              return <MenuItem value={index}>{column.columnName}</MenuItem>;
            })}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel>Order</InputLabel>
        <Select onChange={handleOrderChange} value={order}>
          {orders.map(order => (
            <MenuItem value={order}>{order}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <TextField
          label="User name"
          type="search"
          variant="outlined"
          value={search}
          onChange={handleSearchChange}
        />
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel>Countries</InputLabel>
        <Select onChange={handleCountryChange} value={country}>
          {countries.map(countrie => (
            <MenuItem key={countrie} value={countrie}>
              {countrie}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel>Type</InputLabel>
        <Select onChange={handleTypeChange} value={type}>
          {types.map(type => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <TextField
          label="City"
          type="city"
          variant="outlined"
          onChange={handleCityChange}
        />
      </FormControl>

      <br />

      {columns.map((column, index) => (
        <FormControlLabel
          control={
            <Checkbox
              disabled={column.isMandatory}
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={<CheckCircleIcon />}
              checked={column.isActive}
              onChange={handleColumnStateChange(index)}
              color="secondary"
            />
          }
          label={column.columnName}
        />
      ))}

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Reportings</h4>
              <p className={classes.cardCategoryWhite}>
                Here are all the reportings
              </p>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={columns
                  .filter(column => column.isActive)
                  .map(column => column.columnName)}
                tableData={data
                  .sort(sortData)
                  .filter(
                    row =>
                      search === "" ||
                      (row[0].compare &&
                        row[0].compare
                          .toLowerCase()
                          .indexOf(search.toLowerCase()) !== -1) ||
                      (row[1].compare &&
                        row[1].compare
                          .toLowerCase()
                          .indexOf(search.toLowerCase()) !== -1) ||
                      (row[2].compare &&
                        row[2].compare
                          .toLowerCase()
                          .indexOf(search.toLowerCase()) !== -1)
                  )
                  .map(e => e.map(e => e.value))}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}
