import React, { useEffect, useContext } from "react";
import Movepoint from "components/Movepoint/Movepoint.js";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Thumb from "components/Thumb/Thumb.js";
import FullscreenDialog from "components/Dialog/FullscreenDialog.js";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

// @material-ui/icons
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";

import { AppContext } from "../../AppContext";
import AuthService from "../../AuthService";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  formControl: {
    margin: "5px",
    width: "120px"
  },
  selectEmpty: {
    marginTop: "2"
  },
  searchInput: {
    width: "300px"
  },
  infoText: {
    cursor: "pointer",
    fontWeight: "bold",
    color: "skyblue",
    fontSize: "16px"
  },
  actionText: {
    cursor: "pointer",
    fontWeight: "bold",
    color: "#d62546",
    fontSize: "16px"
  },
  warningText: {
    cursor: "pointer",
    fontWeight: "bold",
    color: "orange",
    fontSize: "16px"
  },
  dangerText: {
    cursor: "pointer",
    fontWeight: "bold",
    color: "#d62546",
    fontSize: "16px"
  },
  spinnerContainer: {
    marginTop: "10px",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  }
};

const useStyles = makeStyles(styles);

export default function Videos() {
  const context = useContext(AppContext);
  const classes = useStyles();

  const authServiceSingleton = React.useRef(new AuthService());

  const nbVideosToFetch = 10;

  let initColumns = [
    {
      columnName: "First Name",
      getData: data => (data.user ? data.user.firstName : ""),
      getCompareData: () => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: true
    },
    {
      columnName: "Last Name",
      getData: data => (data.user ? data.user.lastName : ""),
      getCompareData: () => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: true
    },
    {
      columnName: "User Name",
      getData: data => (data.user ? data.user.userName : ""),
      getCompareData: () => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: false
    },
    {
      columnName: "Duration",
      getData: data => data.duration,
      getCompareData: () => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: false
    },
    {
      columnName: "Format",
      getData: data => data.url.split(".").slice(-1)[0],
      getCompareData: () => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: false
    },
    {
      columnName: "Tags",
      getData: data =>
        data.tags && data.tags.join ? "#" + data.tags.join(" #") : "",
      getCompareData: () => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: true
    },
    {
      columnName: "Private",
      getData: data => (data.thumb && data.thumb.blur ? "True" : "False"),
      getCompareData: () => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: false
    },
    {
      columnName: "Date",
      getData: data => new Date(data.date).toLocaleString(),
      getCompareData: data => new Date(data.date).getTime(),
      isFilterable: true,
      isActive: true,
      isMandatory: false
    },
    {
      columnName: "Thumb",
      getData: data => (
        <>
          {data.thumb && (
            <Thumb
              key={data.thumb.small ? data.thumb.small : data.thumb.normal}
              src={data.thumb.small ? data.thumb.small : data.thumb.normal}
              height="50px"
              width="50px"
            />
          )}
        </>
      ),
      getCompareData: data => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: false
    },
    {
      columnName: "Key",
      getData: data => (
        <p>{data._key}</p>
      ),
      getCompareData: data => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: false
    },
    {
      columnName: "Preview",
      getData: data => (
        <p className={classes.actionText} onClick={() => setUrl(data.url)}>
          Show
        </p>
      ),
      getCompareData: data => 0,
      isFilterable: false,
      isActive: true,
      isMandatory: false
    },
    {
      columnName: "Fix",
      getData: data => (
        <p onClick={() => updateVideoMetadata(data._id)} className={classes.warningText}>
          Fix
        </p>
      ),
      getCompareData: data => 0,
      isFilterable: false,
      isActive: false,
      isMandatory: false
    },
    {
      columnName: "Transcode",
      getData: data => (
        <p onClick={() => transcodeVideo(data._id)} className={classes.infoText}>
          Transcode
        </p>
      ),
      getCompareData: data => 0,
      isFilterable: false,
      isActive: false,
      isMandatory: false
    },
    {
      columnName: "Delete",
      getData: data => (
        <p onClick={() => deleteMedia(data._id)} className={classes.dangerText}>
          Delete
        </p>
      ),
      getCompareData: data => 0,
      isFilterable: false,
      isActive: false,
      isMandatory: false
    }
  ];

  const [toggle, setToggle] = React.useState(false); // Just needed to force refresh
  const [videos, setVideos] = React.useState([]);
  const [order, setOrder] = React.useState("Desc");
  const [columnFilter, setColumnFilter] = React.useState(
    initColumns.map(e => e.isFilterable).indexOf(true)
  );
  const [columns, setColumns] = React.useState(initColumns);
  const [search, setSearch] = React.useState("");
  const [tag, setTag] = React.useState("");
  const [url, setUrl] = React.useState(null);
  const [videoType, setVideoType] = React.useState("All");
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(nbVideosToFetch);
  const [searchLoading, setSearchLoading] = React.useState(true);

  const videoTypes = ["All", "Public", "Private"];
  const orders = ["Asc", "Desc"];

  useEffect(() => {
    searchVideo();
  }, [videoType, tag, search]);

  const searchVideo = () => {

    authServiceSingleton.current.cancel();

    setSearchLoading(true);

    // Get all videos
    authServiceSingleton.current.setUrl(context.apiUrl + context.videosPath + "all");
    authServiceSingleton.current.request(
      "get",
      { start, end, videoType: videoType.toLowerCase(), tag, username: search },
      response => {
        const { data } = response;
        let newStart = videos.length + data.length;
        setSearchLoading(false);
        setVideos(start === 0 ? data : videos.concat(data));
        setStart(newStart);
        setEnd(newStart + nbVideosToFetch);
      }
    );
  };

  const deleteMedia = videoId => {
    // Delete a video
    authServiceSingleton.current.setUrl(context.apiUrl + context.videosPath);
    authServiceSingleton.current.request("delete", { videoId }, response => {
      const { data } = response;
      if (data.status === "success") {
        alert("Deleted !");
      }
    });
  };

  const updateVideoMetadata = videoId => {

    authServiceSingleton.current.setUrl(context.apiUrl + context.videosPath + "metadata");
    authServiceSingleton.current.request("put", { videoId }, response => {});

    alert("Get metadata ! Please wait...");
  }

  const transcodeVideo = videoId => {

    const data = {
      videoId
    }

    console.log("data", data);

    authServiceSingleton.current.setUrl(context.apiUrl + context.videosPath + "transcode");
    authServiceSingleton.current.request("post", data, () => {});

    alert("Transcode ! Please wait...");
  }

  const handleColumnFilterChange = event => {
    setColumnFilter(event.target.value);
  };

  const handleOrderChange = event => {
    setOrder(event.target.value);
  };

  const handleSearchChange = event => {
    setStart(0);
    setEnd(nbVideosToFetch);
    setSearch(event.target.value);
  };

  const handleTagChange = event => {
    setStart(0);
    setEnd(nbVideosToFetch);
    setTag(event.target.value);
  };

  const handleVideoTypeChange = event => {
    setStart(0);
    setEnd(nbVideosToFetch);
    setVideoType(event.target.value);
  };

  const handleColumnStateChange = index => event => {
    let activeColumns = columns.filter(e => e.isActive);
    let currentFilterColumnName = activeColumns[columnFilter]
      ? activeColumns[columnFilter].columnName
      : "";

    let newColumns = columns;
    newColumns[index].isActive = event.target.checked;
    setColumns(newColumns);

    let newFilterColumn = newColumns
      .filter(e => e.isActive)
      .map(e => e.columnName)
      .indexOf(currentFilterColumnName);
    if (newFilterColumn === -1) {
      newFilterColumn = initColumns.map(e => e.isFilterable).indexOf(true);
    }
    setColumnFilter(newFilterColumn);
    setToggle(!toggle);
  };

  const makeDataFromVideos = () => {
    var data = [];

    videos.forEach(video => {
      var row = [];
      columns.forEach(column => {
        if (column.isActive) {
          row.push({
            value: column.getData(video),
            compare: column.getCompareData(video)
          });
        }
      });
      data.push(row);
    });
    return data;
  };

  const sortData = (a, b) => {
    if (!a[columnFilter] || !b[columnFilter]) {
      return 0;
    }
    return (
      (a[columnFilter].compare - b[columnFilter].compare) *
      (order === "Asc" ? 1 : -1)
    );
  };

  let data = makeDataFromVideos();

  const showMore = () => {
    searchVideo();
  };

  return (
    <>
      <FullscreenDialog
        isOpened={url !== null}
        content={<video src={url} controls autoPlay />}
        onClose={() => setUrl(null)}
      />

      <FormControl className={classes.formControl}>
        <InputLabel>Video type</InputLabel>
        <Select onChange={handleVideoTypeChange} value={videoType}>
          {videoTypes.map(videoType => (
            <MenuItem value={videoType}>{videoType}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel>Column</InputLabel>
        <Select onChange={handleColumnFilterChange} value={columnFilter}>
          {columns
            .filter(column => column.isFilterable && column.isActive)
            .map(column => {
              var index = columns
                .filter(column => column.isActive)
                .map(field => field.columnName)
                .indexOf(column.columnName);
              return <MenuItem value={index}>{column.columnName}</MenuItem>;
            })}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel>Order</InputLabel>
        <Select onChange={handleOrderChange} value={order}>
          {orders.map(order => (
            <MenuItem value={order}>{order}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <TextField
          label="User name"
          type="search"
          variant="outlined"
          onChange={handleSearchChange}
        />
      </FormControl>

      <FormControl className={classes.formControl}>
        <TextField
          label="Tag"
          type="tag"
          variant="outlined"
          onChange={handleTagChange}
        />
      </FormControl>

      <br />

      {columns.map((column, index) => (
        <FormControlLabel
          control={
            <Checkbox
              disabled={column.isMandatory}
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={<CheckCircleIcon />}
              checked={column.isActive}
              onChange={handleColumnStateChange(index)}
              color="secondary"
            />
          }
          label={column.columnName}
        />
      ))}

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Videos</h4>
              <p className={classes.cardCategoryWhite}>
                Here are all the videos
              </p>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={columns
                  .filter(column => column.isActive)
                  .map(column => column.columnName)}
                tableData={data.sort(sortData).map(e => e.map(e => e.value))}
              />
            </CardBody>
            {searchLoading && (
              <div className={classes.spinnerContainer}>
                <CircularProgress color="secondary" />
              </div>
            )}
            <Movepoint onEnter={showMore} />
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}
