import React, { useState, useContext } from "react";
import Autosuggest from "react-autosuggest";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import { AppContext } from "../../AppContext";
import AuthService from "../../AuthService";

import List from "./List";

const styles = {
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  formControl: {
    zIndex: 5,
    margin: "5px",
    width: "120px",
  },
};

const mediaTypes = ["video", "photo", "all"];

const useStyles = makeStyles(styles);

export default function Upload() {
  const classes = useStyles();
  const context = useContext(AppContext);

  const [mediaType, setMediaType] = React.useState("all");
  const [modelSuggestions, setModelSuggestions] = useState([]);
  const [username, setUserName] = useState("");

  const handleMediaType = (evt) => {
    setMediaType(evt.target.value);
  };

  const handleUserNameChange = (event) => {
    var username = event.target.value;
    if (username != undefined) {
      const authService = new AuthService();

      setUserName(username);

      authService.setUrl(context.apiUrl + context.usersPath + "suggestion");
      authService.request(
        "get",
        {
          name: username,
          show: "All",
          age: "[18,60]",
          distance: 100,
          country: "All",
          sex: "All",
          userType: "All",
          radarVisibilityOnly: false,
        },
        (response) => {
          const { data } = response;
          setModelSuggestions(data);
        }
      );
    }
  };

  const onSuggestionSelected = (event, data) => {
    setUserName(data.suggestionValue);
  };

  const inputProps = {
    placeholder: "User name",
    onChange: handleUserNameChange,
    value: username,
  };

  return (
    <>
      <FormControl className={classes.formControl}>
        <InputLabel>Media types</InputLabel>
        <Select onChange={handleMediaType} value={mediaType}>
          {mediaTypes.map((mediaType) => (
            <MenuItem value={mediaType}>{mediaType}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <Autosuggest
          suggestions={modelSuggestions.filter(
            (e) => e?.toLowerCase().indexOf(username?.toLowerCase()) !== -1
          )}
          onSuggestionsFetchRequested={() => {}}
          onSuggestionsClearRequested={() => {}}
          onSuggestionSelected={onSuggestionSelected}
          getSuggestionValue={(suggestion) => suggestion}
          shouldRenderSuggestions={() => true}
          renderSuggestion={(elmt) => <span>{elmt}</span>}
          inputProps={inputProps}
        />
      </FormControl>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Media Privates</h4>
            </CardHeader>
            <CardBody>
              <List
                key={username + mediaType}
                approved={false}
                mediaType={mediaType}
                username={username}
                source={"chat"}
                allowToDelete={false}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Post Not Approved</h4>
            </CardHeader>
            <CardBody>
              <List
                key={username + mediaType}
                approved={false}
                mediaType={mediaType}
                username={username}
                source={"post"}
                allowToDelete={true}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Post Approved</h4>
            </CardHeader>
            <CardBody>
              <List
                key={username + mediaType}
                approved={true}
                mediaType={mediaType}
                username={username}
                source={"post"}
                allowToDelete={true}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}
